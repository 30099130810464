/* @flow */

import React, {
  type StatelessFunctionalComponent,
  type ElementProps
} from "react";

type AnchorProps = { target?: string } & ElementProps<"a">;

const Anchor: StatelessFunctionalComponent<AnchorProps> = ({
  target,
  ...remainingProps
}) => {
  let rel;
  if (target === "_blank") {
    rel = "noopener noreferrer";
  }

  return <a {...remainingProps} rel={rel} target={target} />;
};

export default Anchor;
