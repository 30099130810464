/* @flow */

import { useState, useEffect } from "react";

export const useMedia = (query: string): boolean => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const onMediaChange = (): void => {
      setMatches(mediaQueryList.matches);
    };
    const mediaQueryList = window.matchMedia(query);
    mediaQueryList.addListener(onMediaChange);
    onMediaChange();
    return () => {
      mediaQueryList.removeListener(onMediaChange);
    };
  }, [query]);

  return matches;
};
