/* @flow */

import React, { Fragment, type StatelessFunctionalComponent } from "react";
import { Route, Switch } from "react-router-dom";
import "./fonts.scss";
import "./base.scss";
import Link from "./components/Link";
import NavigationBar from "./components/NavigationBar";
import EuCountry from "./routes/EuCountry";
import Thanks from "./routes/Thanks/Thanks";
import NotFound from "./routes/NotFound";
import { StoreProvider } from "./components/StoreProvider";
import FormPopulator from "./components/dev/FormPopulator";
import Marquee from "./components/Marquee";
import LegalFooter from "./components/LegalFooter";
import Kiq from "./routes/Kiq/Kiq";
import Form from "./routes/Form/Form";
import {
  SUPPORTED_COUNTRY_CODES,
  SUPPORTED_LOCAL_ACQUIRING_COUNTRY_CODES
} from "./countryHelper";

type Config = {
  recaptchaSiteKey: string
};

type AppProps = {
  countryCode: string,
  countries: Array<string>,
  venmoOnly: boolean,
  config: Config
};

const App: StatelessFunctionalComponent<AppProps> = ({
  countryCode,
  countries,
  venmoOnly,
  config
}) => (
  <StoreProvider
    countryCode={countryCode}
    countries={countries}
    venmoOnly={venmoOnly}>
    <Fragment>
      <NavigationBar />
      <Route
        path="/country"
        component={props => (
          <Switch>
            <Route
              path={`${props.match.path}/eu`}
              component={() => (
                <main>
                  <EuCountry />
                </main>
              )}
            />
            <Route
              exact={true}
              path={`${
                props.match.path
              }/:country(${SUPPORTED_COUNTRY_CODES.join("|").toLowerCase()})`}
              component={props => {
                return (
                  <Fragment>
                    <Marquee
                      title={
                        <Fragment>Apply for a Production&nbsp;Account</Fragment>
                      }
                      subTitle={
                        venmoOnly ? (
                          <Fragment>
                            This is for a venmo only merchant account. For a
                            full stack US merchant account,{" "}
                            <Link
                              href="https://apply.braintreegateway.com/country/usa"
                              intent="simple"
                              target="_blank">
                              click here.
                            </Link>
                          </Fragment>
                        ) : null
                      }
                      darkStyle={true}
                      description={
                        <div>
                          <p>
                            Please answer the following questions about your
                            business. You’ll be asked to provide information to
                            verify the business and identity of the primary
                            business contact. This form should take you about
                            5-10 minutes to complete.
                          </p>
                          <p>
                            Are you looking to test an integration?{" "}
                            <Link
                              href="https://braintreepayments.com/sandbox"
                              intent="simple"
                              target="_blank">
                              Try Sandbox
                            </Link>
                            .
                          </p>
                        </div>
                      }
                    />
                    <main>
                      <Form {...props} config={config} />
                    </main>
                  </Fragment>
                );
              }}
            />
            <Route
              path={`${props.match.path}/usa/kiq`}
              render={props => (
                <Fragment>
                  <Marquee
                    title="Identity Verification"
                    description={
                      <Fragment>
                        <p>
                          The security of our merchants is very important to us.
                          As the authorized representative allowed to make
                          decisions on behalf of the business, we need to verify
                          your identity to protect you and your business from
                          fraud.
                        </p>
                        <p>This is the last step you will need to complete.</p>
                      </Fragment>
                    }
                    darkMode={false}
                  />

                  <main>
                    <Kiq {...props} />
                  </main>
                </Fragment>
              )}
            />
            <Route
              component={() => (
                <main>
                  <NotFound />
                </main>
              )}
            />
          </Switch>
        )}
      />

      <Route
        path="/thanks"
        exact={true}
        component={props => (
          <main>
            <Thanks
              {...props}
              countryCode={countryCode}
              message={
                <Fragment>
                  Your application will begin the review process within one
                  business day. If additional information is needed, we will
                  contact you by email at{" "}
                  <strong>{props.location.state.email}</strong>.
                </Fragment>
              }
            />
          </main>
        )}
      />

      <Route
        path="/internal/onboarding"
        component={props => (
          <Switch>
            <Route
              exact={true}
              path={`${
                props.match.path
              }/local_acquiring/:country_code(${SUPPORTED_LOCAL_ACQUIRING_COUNTRY_CODES.join(
                "|"
              ).toLowerCase()})`}
              component={props => {
                return (
                  <Fragment>
                    <Marquee
                      title={
                        <Fragment>Apply for a Production&nbsp;Account</Fragment>
                      }
                      darkStyle={true}
                      description={
                        <div>
                          <p>
                            Please answer the following questions about your
                            business. You’ll be asked to provide information to
                            verify the business and identity of the primary
                            business contact. This form should take you about
                            5-10 minutes to complete.
                          </p>
                          <p>
                            Are you looking to test an integration?{" "}
                            <Link
                              href="https://braintreepayments.com/sandbox"
                              intent="simple"
                              target="_blank">
                              Try Sandbox
                            </Link>
                            .
                          </p>
                        </div>
                      }
                    />
                    <main>
                      <Form {...props} countryCode={countryCode} />
                    </main>
                  </Fragment>
                );
              }}
            />
            <Route
              component={() => (
                <main>
                  <NotFound />
                </main>
              )}
            />
          </Switch>
        )}
      />

      <LegalFooter countryCode={countryCode} />

      {["development", "qa", "qa2"].includes(process.env.RAILS_ENV) && (
        <FormPopulator />
      )}
    </Fragment>
  </StoreProvider>
);

export default App;
