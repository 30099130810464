/* @flow */

import React from "react";
import type { CheckboxProps } from "./Checkbox.types";
import styles from "./Checkbox.scss";

export const Checkbox = (props: CheckboxProps) => {
  const { checked, description, name, onBlur, onChange } = props;

  return (
    <div className={styles.grid}>
      <div className={styles.column}>
        <input
          id={name}
          checked={checked}
          className={styles.input}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="checkbox"
        />
      </div>
      <div className={styles.column}>
        <div className={styles.description}>
          <label htmlFor={name}>{description}</label>
        </div>
      </div>
    </div>
  );
};
