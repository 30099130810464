/* @flow */

import React, { useContext } from "react";
import { Radio } from "./Radio";
import type { ChoiceProps } from "./Choice.types";
import { ChoiceGroupContext } from "./ChoiceGroupContext";
import styles from "./Choice.scss";

export const Choice = (props: ChoiceProps) => {
  const { labelText, value } = props;
  const { name, onBlur, onChange, onFocus, value: valueSelected } = useContext(
    ChoiceGroupContext
  );
  const isChecked = value === valueSelected;

  return (
    <div className={styles.root}>
      <Radio
        checked={isChecked}
        labelText={labelText}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
    </div>
  );
};
