/* @flow */

import React from "react";
import classNames from "classnames";
import styles from "./ChoiceGroup.scss";
import { ChoiceGroupContext } from "./ChoiceGroupContext";
import type { ChoiceGroupProps } from "./ChoiceGroup.types";

export const ChoiceGroup = (props: ChoiceGroupProps) => {
  const {
    children,
    errorText,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    description,
    value = ""
  } = props;
  const hasErrorMessage = Boolean(errorText);

  return (
    <ChoiceGroupContext.Provider
      value={{ name, onBlur, onChange, onFocus, value }}>
      <fieldset className="fieldset">
        {label !== undefined && (
          <legend
            className={classNames(styles.label, {
              [styles.invalid]: hasErrorMessage
            })}>
            {label}
          </legend>
        )}
        {children}
        {hasErrorMessage && <div className={styles.error}>{errorText}</div>}
        {description !== undefined && (
          <div className={styles.description}>{description}</div>
        )}
      </fieldset>
    </ChoiceGroupContext.Provider>
  );
};
