/* @flow */

import React from "react";
import styles from "./Spinner.scss";

type SpinnerProps = {
  size: number
};

const Spinner = ({ size }: SpinnerProps) => (
  <svg
    className={styles.spinner}
    viewBox="0 0 32 32"
    width={size}
    height={size}>
    <path
      opacity="0.08"
      d="M16,31.5A15.5,15.5,0,1,1,31.5,16,15.51,15.51,0,0,1,16,31.5Zm0-28A12.5,12.5,0,1,0,28.5,16,12.52,12.52,0,0,0,16,3.5Z"
    />
    <path d="M16,31.5A15.49,15.49,0,0,1,8.25,2.59a1.5,1.5,0,1,1,1.5,2.6,12.5,12.5,0,1,0,12.5,0,1.5,1.5,0,1,1,1.5-2.6A15.49,15.49,0,0,1,16,31.5Z" />
  </svg>
);

Spinner.defaultProps = {
  size: 32
};

export default Spinner;
