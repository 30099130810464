/** @flow */

import { number, object, string, type NumberSchema } from "yup";
import { booleanString, stringExists } from "./helpers";
export default object().shape(
  {
    annual_volume_amount: number()
      .transform((currentValue: number, originalValue: string): number | void =>
        originalValue === "" ? undefined : currentValue
      )
      .required("Please fill out this field")
      .moreThan(0, "Must be greater than 0")
      .max(
        1000000000,
        "Please contact us directly if you process more than $1 billion annually"
      ),
    average_transaction_amount: number()
      .transform((currentValue: number, originalValue: string): number | void =>
        originalValue === "" ? undefined : currentValue
      )
      .required("Please fill out this field")
      .moreThan(0, "Must be greater than 0")
      .when(
        "maximum_transaction_amount",
        (
          maximumTransactionAmount?: number,
          schema: NumberSchema
        ): NumberSchema => {
          if (
            maximumTransactionAmount !== undefined &&
            maximumTransactionAmount > 0
          ) {
            return schema.max(
              maximumTransactionAmount,
              "Must be less than or equal to largest transaction"
            );
          }
          return schema;
        }
      ),
    maximum_transaction_amount: number()
      .transform((currentValue: number, originalValue: string): number | void =>
        originalValue === "" ? undefined : currentValue
      )
      .required("Please fill out this field")
      .moreThan(0, "Must be greater than 0")
      .when(
        "average_transaction_amount",
        (
          averageTransactionAmount?: number,
          schema: NumberSchema
        ): NumberSchema => {
          if (
            averageTransactionAmount !== undefined &&
            averageTransactionAmount > 0
          ) {
            return schema.min(
              averageTransactionAmount,
              "Must be greater than or equal to average transaction"
            );
          }
          return schema;
        }
      ),
    customer_charged_on: string().required(
      "Please select an option from the list"
    ),
    refund_policy: string().when("$countryCode", {
      is: "USA",
      then: string().required("Please select an option from the list"),
      otherwise: string().notRequired()
    }),
    ship_physical_goods: booleanString,
    ships_own_products: booleanString,
    currently_processing_credit_cards: booleanString,
    existing_merchant: booleanString,
    in_person: booleanString,
    preferred_merchant_account_id: string().notRequired(),
    merchant_public_id: string().when(["$countryCode", "existing_merchant"], {
      is: ($countryCode, existing_merchant) => {
        return $countryCode === "BRA" && existing_merchant === "1";
      },
      then: stringExists,
      otherwise: string().notRequired()
    }),
    fulfillment_completed_in: string().required(
      "Please select an option from the list"
    ),
    offers_subscriptions: booleanString,
    subscription_percentages: object().when("offers_subscriptions", {
      is: "1",
      then: object().test(
        "subscriptionPercentagesSumTest",
        "Subscription revenue must add up to 100%",
        (subscriptions: { [string]: number }): boolean => {
          const percentages = Object.values(
            subscriptions?.value || subscriptions || {}
          );
          const sum = percentages.reduce(
            (accumulator: number, percentage: mixed): number =>
              accumulator + Number(percentage),
            0
          );
          return sum === 100;
        }
      ),
      otherwise: object().notRequired()
    }),
    customer_statement_descriptor: string().when(
      ["$countryCode"],
      (countryCode, schema) => {
        if (countryCode === "CAN") {
          return schema.required("Please fill out this field");
        }
        return schema.notRequired();
      }
    ),
    customer_statement_phone_number: string().when(
      ["$countryCode"],
      (countryCode, schema) => {
        if (countryCode === "CAN") {
          return schema.required("Please fill out this field");
        }
        return schema.notRequired();
      }
    ),
    processing_currencies: string().when(
      ["$countryCode"],
      (countryCode, schema) => {
        if (countryCode === "CAN") {
          return schema
            .required("Please select an option from the list")
            .oneOf(["CAD", "USD", "BOTH"]);
        }
        return schema.notRequired();
      }
    ),
    accept_amex: string().when(["$countryCode"], (countryCode, schema) => {
      if (countryCode === "CAN") {
        return schema
          .required("Please select an option from the list")
          .oneOf(["0", "1"]);
      }
      return schema.notRequired();
    })
  },
  [["average_transaction_amount", "maximum_transaction_amount"]]
);
