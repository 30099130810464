/* @flow */

import React, { Fragment, type Node } from "react";
import Fieldset from "../components/Fieldset";
import { Whole } from "../components/GridItem";
import TextField from "../components/TextField";
import Link from "../components/Link";
import type {
  FileDescriptor,
  FileUploadStatus,
  Action,
  BusinessValues,
  FormBlurHandlerEventArg,
  FormChangeHandlerEventArg,
  RootState,
  ValidationErrors
} from "../types";
import { connect } from "react-redux";
import GroupedList from "../components/GroupedList";
import { ChoiceGroup } from "./ChoiceGroup";
import { Choice } from "./Choice";
import FilePicker from "../components/FilePicker";
import Grid from "./Grid";

type PayWithVenmoProps = {
  dispatch: Action => Action,
  values: BusinessValues,
  errors: ValidationErrors,
  venmoOnly: boolean
};

export const PayWithVenmo = (props: PayWithVenmoProps): Node => {
  const { dispatch, values, errors, venmoOnly } = props;

  const handleBlur = (event: FormBlurHandlerEventArg) => {
    const { name } = event.currentTarget;

    dispatch({
      type: "BUSINESS_FIELD_BLUR",
      payload: { name }
    });
  };

  const handleChange = (event: FormChangeHandlerEventArg) => {
    const { name, value } = event.currentTarget;

    if (name === "pay_with_venmo") {
      dispatch({
        type: "DISCLAIMERS_FIELD_CHANGE",
        payload: { name: "venmo_terms_accepted", value: value === "0" }
      });

      if (value === "0") {
        dispatch({
          type: "DISCLAIMERS_FIELD_BLUR",
          payload: { name: "venmo_terms_accepted" }
        });
      }
    }

    dispatch({
      type: "BUSINESS_FIELD_CHANGE",
      payload: { name, value }
    });
  };

  const handleFileCollectionAdd = (newFile: FileDescriptor) => {
    dispatch({
      type: "BUSINESS_LOGO_ADD",
      payload: newFile
    });
  };

  const handleFileCollectionRemove = (fileToRemove: FileDescriptor) => {
    dispatch({
      type: "BUSINESS_LOGO_REMOVE",
      payload: fileToRemove
    });
  };

  const handleFileUploadStatusChange = (status: FileUploadStatus) => {
    if (status === "uploading") {
      dispatch({ type: "UI_ASYNC_ACTION_START" });
    } else if (status === "complete") {
      dispatch({ type: "UI_ASYNC_ACTION_COMPLETE" });
    }
  };

  return (
    <Fragment>
      {!venmoOnly && (
        <Fieldset
          title="Pay with Venmo"
          description={
            <Fragment>
              Grow your business by tapping into the Venmo consumer base when
              you offer Venmo as a payment option at checkout. Your customers
              will be able to use their Venmo accounts to make purchases,
              offering more convenience and flexibility.{" "}
              <Link
                href="https://www.paypal.com/us/enterprise/payment-processing/accept-venmo?utm_campaign=Braintree_Migration&utm_medium=Website&utm_source=Braintree"
                intent="simple"
                target="_blank">
                Learn More
              </Link>
            </Fragment>
          }>
          <GroupedList displayAsCard={true}>
            <ChoiceGroup
              errorText={errors.pay_with_venmo}
              label="Do you want to enable customers to pay with their Venmo account?"
              name="pay_with_venmo"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.pay_with_venmo}>
              <Choice
                labelText="Yes, I want to enable Pay with Venmo"
                value="1"
              />
              <Choice labelText="No" value="0" />
            </ChoiceGroup>
          </GroupedList>
          {values.pay_with_venmo === "1" && (
            <Fragment>
              <GroupedList title="Venmo Business profile" displayAsCard={true}>
                <Grid>
                  <Whole>
                    <p>
                      Please note that we will create a default Venmo Profile
                      using the following information provided in this
                      application: Business Name, Business Phone Number,
                      Website, Contact Email and Logo. You can change this
                      configuration or add new profiles at any time.
                    </p>
                  </Whole>
                  <Whole>
                    <TextField
                      errorText={errors.email}
                      labelText="Business Email"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      description="This information will be visible to customers in their purchase receipts"
                    />
                  </Whole>
                </Grid>
              </GroupedList>
              <FilePicker
                title="Business Logo"
                buttonTitle="Upload your business logo here..."
                files={values.logo_image || []}
                displayAsCard={true}
                onFileUpload={handleFileCollectionAdd}
                onFileRemove={handleFileCollectionRemove}
                onFileUploadStatus={handleFileUploadStatusChange}
                acceptType=".png"
                description="Upload a 1024x1024 PNG logo to display in the Venmo app. Logo file size cannot exceed 700 KB."
                requiredDimensions="1024X1024"
                maxSize="740000"
              />
            </Fragment>
          )}
        </Fieldset>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.business,
  venmoOnly: state.venmoOnly
});

// $FlowFixMe
export default connect(mapStateToProps)(PayWithVenmo);
