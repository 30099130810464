/* @flow */

import "@babel/polyfill";
import "whatwg-fetch";
import "url-search-params-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "../packages/signups/src/App.js";
import { MemoryRouter } from "react-router-dom";
import { init } from "@sentry/browser";
import { Kount } from "../packages/signups/src/Kount";

const configNode = document.querySelector("#formConfig");
const container = document.querySelector(".signups");

if (configNode !== null && container !== null) {
  const { sentry, countries, config, kount_id: kountId } = JSON.parse(
    configNode.textContent
  );
  const isInternalApply = location.pathname.includes("internal");
  const countryCode = isInternalApply
    ? location.pathname
        .split("/")
        .filter(item => item)[3]
        .toUpperCase()
    : location.pathname
        .split("/")
        .filter(item => item)[1]
        .toUpperCase();
  const venmoOnly = (location.search || "")
    .substring(1)
    .includes("venmo_only=true");
  Kount.initialize(kountId);

  init({
    ...sentry,
    attachStacktrace: true,
    dsn: "https://18f46a4c79944aa9a75172b3c631ee36@sentry.io/1725694",
    enabled: sentry.environment !== "test",
    whitelistUrls: [`${window.location.hostname}/packs`]
  });

  ReactDOM.render(
    <MemoryRouter initialEntries={[`${location.pathname}${location.search}`]}>
      <App
        countryCode={countryCode}
        countries={countries}
        venmoOnly={venmoOnly}
        config={config}
      />
    </MemoryRouter>,
    container
  );
}
