/* @flow */

export const isAcn = (value: string): boolean => {
  // https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check
  if (/[^0-9]/.test(value) || value.length !== 9) return false;
  let digits = [...value].map(Number);
  const checkDigit = digits.pop();
  // 1. Multiply each of the digits by a "weighting factor".
  const weightingFactors = [8, 7, 6, 5, 4, 3, 2, 1];
  digits = digits.map((digit, i) => digit * weightingFactors[i]);
  // 2. Sum the resulting product.
  const sum = digits.reduce((a, b) => a + b, 0);
  // 3. Divide the sum total by 10, noting the remainder.
  const remainder = sum % 10;
  // 4. Complement the remainder to 10.
  let complement = 10 - remainder;
  // 4.a If the complement equals 10, set it to 0.
  if (complement === 10) complement = 0;
  // 5. If the complement equals the check digit, the value is a valid ACN.
  return complement === checkDigit;
};

export const isAbn = (value: string): boolean => {
  // https://abr.business.gov.au/Help/AbnFormat
  if (/[^0-9]/.test(value) || value.length !== 11) return false;
  let digits = [...value].map(Number);
  // 1. Subtract 1 from the first (left-most) digit.
  digits[0] = digits[0] - 1;
  // 2. Multiply each of the digits by a "weighting factor".
  const weightingFactors = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  digits = digits.map((digit, i) => digit * weightingFactors[i]);
  // 3. Sum the resulting product.
  const sum = digits.reduce((a, b) => a + b, 0);
  // 4. Divide the sum total by 89, noting the remainder.
  const remainder = sum % 89;
  // 5. If the remainder is zero the number is a valid ABN.
  return remainder === 0;
};

export const isRoutingNumber = (value: string): boolean => {
  if (/[^0-9]/.test(value) || value.charAt(0) === "5" || value.length !== 9) {
    return false;
  }
  const digits = [...value].map(Number);
  const sum =
    3 * (digits[0] + digits[3] + digits[6]) +
    7 * (digits[1] + digits[4] + digits[7]) +
    1 * (digits[2] + digits[5] + digits[8]);
  const remainder = sum % 10;
  return remainder === 0;
};

export const isFormattedDate = (value: string): boolean =>
  /^\d{1,2}[.\-/]\d{1,2}[.\-/]\d{4}|\d{4}[.\-/]\d{1,2}[.\-/]\d{1,2}/.test(
    value
  ) && !Number.isNaN(Date.parse(value));

export const isInAgeRange = (min: number, max: number) => (value: string) => {
  const formatted = normalizeDateToUTC(value);
  const date = new Date(formatted);
  const age = Math.floor(
    (Date.now() - date.getTime()) / 1000 / 60 / 60 / 24 / 365
  );

  return age >= min && age <= max;
};

export const isInBusinessAgeRange = (value: string): boolean => {
  const nextYear = new Date().getFullYear() + 1;
  const formattedDate = normalizeDateToUTC(value);
  const businessYear = new Date(formattedDate).getFullYear();

  return Boolean(
    isFormattedDate(value) && businessYear < nextYear && businessYear >= 1700
  );
};

const normalizeDateToUTC = (value: string) => {
  const date = new Date(value);
  return `${date.getUTCMonth() +
    1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
};

export const isDateInThePast = (value: string): boolean => {
  return isFormattedDate(value) && new Date(value) <= new Date();
};
