/* @flow */

import React from "react";
import type {
  FormBlurHandlerEventArg,
  FormChangeHandlerEventArg,
  Address,
  ValidationErrors
} from "../../../types";
import TextField from "../../TextField";
import SelectField from "../../SelectField";
import RegionField from "../../RegionField";
import Grid from "../../Grid";
import { TwoThirds, Third, Whole } from "../../GridItem";
import { getCountriesForSelect } from "../../../fields/countries";

type ContactPersonalInformationAddressProps = {
  onBlur: (event: FormBlurHandlerEventArg) => void,
  onChange: (event: FormChangeHandlerEventArg) => void,
  values: Address,
  errors: $Rest<ValidationErrors, {| address?: ValidationErrors |}>,
  countryCode: string,
  countries: Array<string>
};

const ContactPersonalInformationAddress = ({
  countryCode,
  countries,
  errors,
  onBlur,
  onChange,
  values
}: ContactPersonalInformationAddressProps) => (
  <Grid>
    {countryCode !== "USA" && countryCode !== "CAN" && (
      <Whole>
        <SelectField
          errorText={errors.country}
          labelText="Country"
          name="country"
          onBlur={onBlur}
          onChange={onChange}
          options={getCountriesForSelect(countries)}
          value={values.country}
        />
      </Whole>
    )}
    <TwoThirds>
      <TextField
        errorText={errors.street_address}
        labelText="Street Address"
        name="street_address"
        onBlur={onBlur}
        onChange={onChange}
        placeholder="Street Address"
        value={values.street_address}
      />
    </TwoThirds>
    <Third>
      <TextField
        errorText={errors.extended_address}
        labelText="Address 2"
        name="extended_address"
        onBlur={onBlur}
        onChange={onChange}
        placeholder="Address 2"
        value={values.extended_address}
      />
    </Third>
    <Third>
      <TextField
        errorText={errors.locality}
        labelText="City"
        name="locality"
        onBlur={onBlur}
        onChange={onChange}
        placeholder="City"
        value={values.locality}
      />
    </Third>
    <Third>
      <RegionField
        labelText="State"
        name="region"
        countryCode={values.country}
        errors={errors.region}
        values={values.region}
        onChange={onChange}
      />
    </Third>
    <Third>
      <TextField
        errorText={errors.postal_code}
        labelText="Postal Code"
        name="postal_code"
        onBlur={onBlur}
        onChange={onChange}
        placeholder="Postal Code"
        value={values.postal_code}
      />
    </Third>
  </Grid>
);

export default ContactPersonalInformationAddress;
