/* @flow */

import React, { type Node } from "react";
import type { RouterHistory, Match, Location } from "react-router-dom";
import Button from "../../components/Button";
import GroupedList from "../../components/GroupedList";
import { ChoiceGroup } from "../../components/ChoiceGroup";
import { Choice } from "../../components/Choice";
import { getMetaNodeValue } from "../../dom";
import ErrorBlock from "../../components/ErrorBlock";
import Spinner from "../../components/Spinner";
import ListCell from "../../components/ListCell";
import Grid from "../../components/Grid";
import { Third } from "../../components/GridItem";

type KiqQuestionsProps = {
  history: RouterHistory,
  match: Match,
  location: Location
};

type KiqQuestionsState = {
  questions: { text: string, choices: string[] }[],
  formData: { [string]: string },
  hasErrors: boolean,
  isLoading: boolean
};

class KiqQuestions extends React.Component<
  KiqQuestionsProps,
  KiqQuestionsState
> {
  state = {
    questions: [],
    formData: {},
    hasErrors: false,
    isLoading: true
  };

  validateQuestionnaire = () => {
    const canSubmit =
      Object.values(this.state.formData).length === this.state.questions.length;
    if (canSubmit) {
      this.submitAnswers();
    } else {
      this.setState({ hasErrors: true });
    }
  };

  submitAnswers = async () => {
    const { history, location } = this.props;
    const { authorizedSigner, authorizedSignerId, locationId } =
      location.state || {};

    try {
      const response = await fetch(
        `/locations/${locationId}/contacts/${authorizedSignerId}/kiq_sessions`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": String(getMetaNodeValue("csrf-token")),
            "Content-type": "application/json"
          },
          body: JSON.stringify({ answers: Object.values(this.state.formData) })
        }
      );

      if (response.ok) {
        history.push("/thanks", { email: authorizedSigner.email });
      } else {
        this.gotoDocuments();
      }
    } catch (error) {
      this.gotoDocuments();
    }
  };

  gotoDocuments() {
    const { history, location, match } = this.props;
    const { authorizedSigner, authorizedSignerId } = location.state || {};
    history.push(`${match.url}/documents`, {
      authorizedSigner,
      authorizedSignerId
    });
  }

  async componentDidMount(): mixed {
    const { location } = this.props;
    const { authorizedSignerId, locationId } = location.state || {};

    try {
      const response = await fetch(
        `/locations/${locationId}/contacts/${authorizedSignerId}/kiq_sessions`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": String(getMetaNodeValue("csrf-token")),
            "Content-type": "application/json"
          }
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const { questions } = await response.json();

      if (questions.length > 0) {
        this.setState({ questions, isLoading: false });
      } else {
        this.gotoDocuments();
      }
    } catch (error) {
      this.gotoDocuments();
    }
  }

  render(): Node {
    const { location } = this.props;
    const { authorizedSigner } = location.state || {};
    const { questions, formData, hasErrors, isLoading } = this.state;

    return (
      <div>
        <GroupedList displayAsCard={true}>
          <ListCell
            title={`${authorizedSigner.first_name} ${authorizedSigner.last_name}`}
            description={authorizedSigner.job_title}
            badge={`${authorizedSigner.first_name.charAt(
              0
            )}${authorizedSigner.last_name.charAt(0)}`}
            accessory={isLoading && <Spinner size={24} />}
          />

          {hasErrors && <ErrorBlock message="Please answer all questions" />}

          {questions.map((question, index) => {
            const { text, choices } = question;
            const name = `question${index}`;

            return (
              <ChoiceGroup
                key={`ChoiceGroup${index}`}
                label={text}
                name={name}
                onChange={event => {
                  const inputValue = event.currentTarget.value;
                  this.setState(prevState => ({
                    ...prevState,
                    formData: {
                      ...prevState.formData,
                      [name]: inputValue
                    }
                  }));
                }}
                value={formData[name]}>
                <Grid>
                  {choices.map((choice, index) => (
                    <Third key={`GridItem-${index}`}>
                      <Choice labelText={choice} value={`${index + 1}`} />
                    </Third>
                  ))}
                </Grid>
              </ChoiceGroup>
            );
          })}
        </GroupedList>

        <Button
          intent="primary"
          disabled={isLoading}
          fullWidth={true}
          onClick={this.validateQuestionnaire}>
          Submit Answers
        </Button>
      </div>
    );
  }
}

export default KiqQuestions;
