/* @flow */

import React, { type Node } from "react";
import TextField from "./TextField";
import SelectField from "./SelectField";
import Fieldset from "./Fieldset";
import Grid from "./Grid";
import { Half, Whole, TwoThirds, Third } from "./GridItem";
import { connect } from "react-redux";
import type {
  FileDescriptor,
  FileUploadStatus,
  FundingValues,
  FundingAddressState,
  ValidationErrors,
  Action,
  RootState
} from "../types";
import FilePicker from "./FilePicker";
import countryConfig from "../countryConfig";
import GroupedList from "./GroupedList";
import { getCountriesForSelect } from "../fields/countries";

type FundingProps = {
  dispatch: Action => Action,
  values: FundingValues,
  address: FundingAddressState,
  errors: ValidationErrors,
  documents: FileDescriptor[],
  countryCode: string,
  countries: Array<string>,
  documentsAreRequired: boolean,
  processingCurrencies: string
};

export const AdditionalFunding = (props: FundingProps): Node => {
  const {
    dispatch,
    errors,
    values,
    address,
    documents,
    countryCode,
    countries,
    documentsAreRequired,
    processingCurrencies
  } = props;
  const config = countryConfig[countryCode];
  const handleFileCollectionAdd = (newFile: FileDescriptor) => {
    dispatch({
      type: "ADDITIONAL_FUNDING_DOCUMENT_ADD",
      payload: newFile
    });
  };

  const handleFileCollectionRemove = (fileToRemove: FileDescriptor) => {
    dispatch({
      type: "ADDITIONAL_FUNDING_DOCUMENT_REMOVE",
      payload: fileToRemove
    });
  };

  const handleFileUploadStatusChange = (status: FileUploadStatus) => {
    if (status === "uploading") {
      dispatch({ type: "UI_ASYNC_ACTION_START" });
    } else if (status === "complete") {
      dispatch({ type: "UI_ASYNC_ACTION_COMPLETE" });
    }
  };

  const handleFieldBlur = (
    event: SyntheticFocusEvent<HTMLInputElement>
  ): void => {
    const { name } = event.currentTarget;
    dispatch({ type: "ADDITIONAL_FUNDING_FIELD_BLUR", payload: { name } });
  };

  const handleAddressFieldBlur = (
    event:
      | SyntheticFocusEvent<HTMLInputElement>
      | SyntheticFocusEvent<HTMLSelectElement>
  ): void => {
    const { name } = event.currentTarget;
    dispatch({
      type: "ADDRESS_ADDITIONAL_FUNDING_FIELD_BLUR",
      payload: { name }
    });
  };

  const handleFieldChange = (
    event: SyntheticInputEvent<HTMLInputElement>
  ): void => {
    const { name, value } = event.currentTarget;
    dispatch({
      type: "ADDITIONAL_FUNDING_FIELD_CHANGE",
      payload: {
        name,
        value
      }
    });
  };

  const handleAddressFieldChange = (
    event:
      | SyntheticInputEvent<HTMLInputElement>
      | SyntheticInputEvent<HTMLSelectElement>
  ): void => {
    const { name, value } = event.currentTarget;
    dispatch({
      type: "ADDRESS_ADDITIONAL_FUNDING_FIELD_CHANGE",
      payload: {
        name,
        value
      }
    });
  };

  const title = (countryCode: string, processingCurrencies: string) => {
    if (countryCode === "CAN") {
      const currency = processingCurrencies === "USD" ? "CAD" : "USD";
      return `Banking Information (${currency})`;
    }
    return "Additional Banking Details for Disbursement";
  };

  const description = (countryCode: string, processingCurrencies: string) => {
    if (countryCode === "CAN") {
      if (processingCurrencies === "USD") {
        return "To accept payments in USD, it is required that the merchant have two bank accounts; a CAD account for fees that are withdrawn, and a USD account for deposits.";
      }
      // processingCurrencies == BOTH
      return "Please provide a checking account that settles in USD ($). Funds are typically disbursed within 2 business days for approved merchants. Savings, deposit-only, and prepaid debit accounts will not be funded by Braintree.\n\nIn order to be approved to process USD with Braintree, your online checkout must indicate to users that paying in USD is an option.";
    }
    return `Please provide a checking account that settles in ${config.currencyISOCode} (${config.currencySymbol}). 
      Funds are typically disbursed within two business days for approved merchants. 
      Braintree will not fund savings, deposit-only, and prepaid debit\u00A0accounts.`;
  };

  return (
    <Fieldset
      title={title(countryCode, processingCurrencies)}
      description={description(countryCode, processingCurrencies)}>
      <GroupedList title="Account Information" displayAsCard={true}>
        {["MYS", "HKG", "SGP"].includes(countryCode) ? (
          <Grid>
            <Half>
              <TextField
                errorText={errors.account_number}
                name="account_number"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.account_number}
                description={config.accountNumberDescription}
                labelText={config.accountNumberLabel}
                placeholder={config.accountNumberPlaceholder}
              />
            </Half>
            <Half>
              <TextField
                errorText={errors.bic}
                name="bic"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.bic}
                labelText="BIC (SWIFT) code"
              />
            </Half>
            <Whole>
              <TextField
                errorText={errors.bank_name}
                name="bank_name"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.bank_name}
                description="Enter bank name"
                labelText="Bank name"
              />
            </Whole>
            <Whole>
              <SelectField
                errorText={errors.country}
                labelText="Country"
                name="country"
                onBlur={handleAddressFieldBlur}
                onChange={handleAddressFieldChange}
                options={getCountriesForSelect(countries)}
                value={address.values.country}
              />
            </Whole>
            <TwoThirds>
              <TextField
                errorText={errors.street_address}
                labelText="Street Address"
                name="street_address"
                onBlur={handleAddressFieldBlur}
                onChange={handleAddressFieldChange}
                placeholder="Street Address"
                value={address.values.street_address}
              />
            </TwoThirds>
            <Third>
              <TextField
                errorText={errors.locality}
                labelText="City"
                name="locality"
                onBlur={handleAddressFieldBlur}
                onChange={handleAddressFieldChange}
                placeholder="City"
                value={address.values.locality}
              />
            </Third>
            <Third>
              <TextField
                errorText={errors.region}
                labelText="Region"
                name="region"
                onBlur={handleAddressFieldBlur}
                onChange={handleAddressFieldChange}
                placeholder="Region"
                value={address.values.region}
              />
            </Third>
            <Third>
              <TextField
                errorText={errors.postal_code}
                labelText="Postal Code"
                name="postal_code"
                placeholder={countryConfig[countryCode].postalCodePlaceholder}
                onBlur={handleAddressFieldBlur}
                onChange={handleAddressFieldChange}
                type="number"
                value={address.values.postal_code}
              />
            </Third>
          </Grid>
        ) : countryCode === "CAN" ? (
          <Grid>
            <Whole>
              <TextField
                errorText={errors.bank_name}
                name="bank_name"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.bank_name}
                description="Must be Canadian-based bank"
                labelText="Name Of Bank"
              />
            </Whole>
            <Whole>
              <TextField
                errorText={errors.bank_account_holder_name}
                name="bank_account_holder_name"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.bank_account_holder_name}
                labelText="Bank Account Holder Name"
              />
            </Whole>
            <Third>
              <TextField
                errorText={errors.transit_number}
                name="transit_number"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.transit_number}
                labelText="Transit Number"
                placeholder="00000"
              />
            </Third>
            <Third>
              <TextField
                errorText={errors.institution_code}
                name="institution_code"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.institution_code}
                labelText="Institution Code"
                placeholder="000"
              />
            </Third>
            <Third>
              <TextField
                errorText={errors.account_number}
                name="account_number"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.account_number}
                description={config.accountNumberDescription}
                labelText={config.accountNumberLabel}
                placeholder={config.accountNumberPlaceholder}
              />
            </Third>
          </Grid>
        ) : (
          <Grid>
            <Half>
              <TextField
                errorText={errors.routing_number}
                name="routing_number"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.routing_number}
                description={config.routingNumberDescription}
                labelText={config.routingNumberLabel}
                placeholder={config.routingNumberPlaceholder}
              />
            </Half>
            <Half>
              <TextField
                errorText={errors.account_number}
                name="account_number"
                onBlur={handleFieldBlur}
                onChange={handleFieldChange}
                type="text"
                value={values.account_number}
                description={config.accountNumberDescription}
                labelText={config.accountNumberLabel}
                placeholder={config.accountNumberPlaceholder}
              />
            </Half>
          </Grid>
        )}
      </GroupedList>

      {documentsAreRequired && (
        <FilePicker
          title="Recent Banking Statements"
          description="These documents help verify that the bank account provided is valid, active and associated with your business. It also confirms that we route settlements to the correct account. Accepted file formats include PDF, JPG, or PNG. File size is limited to 10 MB. Adding recent banking statements is optional at this time, however we will request them during the review process."
          files={documents}
          displayAsCard={true}
          onFileUpload={handleFileCollectionAdd}
          onFileRemove={handleFileCollectionRemove}
          onFileUploadStatus={handleFileUploadStatusChange}
        />
      )}
    </Fieldset>
  );
};

const mapStateToProps = ({
  additionalFunding: { errors, values, address, documents },
  countryCode,
  countries
}: RootState) => {
  return { errors, values, address, documents, countryCode, countries };
};

// $FlowFixMe
export default connect(mapStateToProps)(AdditionalFunding);
