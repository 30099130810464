/* @flow */

import React, { type ChildrenArray, type Element } from "react";
import Button from "./Button";
import styles from "./ButtonGroup.scss";
import type { EmptyNode } from "../types";

type ButtonGroupProps = {
  children: ChildrenArray<Element<typeof Button> | EmptyNode>
};

const ButtonGroup = ({ children }: ButtonGroupProps) => {
  return (
    <div className={styles.root}>
      {React.Children.map(children, (child, i) => {
        return (
          <div key={i} className={styles.item}>
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
