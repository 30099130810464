/* @flow */

import React from "react";
import { connect } from "react-redux";
import styles from "./FormPopulator.scss";
import type { Dispatch } from "redux";

type FormPopulatorProps = {
  dispatch: Dispatch<{ type: string }>
};

const FormPopulator = (props: FormPopulatorProps) => {
  const { dispatch } = props;

  return (
    <div className={styles.root}>
      <div className={styles.title}>[DEV ONLY]</div>
      <button
        className={styles.button}
        onClick={() => {
          dispatch({ type: "HYDRATE" });
        }}
        type="button">
        Populate Form
      </button>
    </div>
  );
};

// $FlowFixMe
export default connect()(FormPopulator);
