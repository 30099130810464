/* @flow */

import React, { type Node } from "react";
import styles from "./Badge.scss";

type BadgeProps = {
  label: string,
  children: Node
};

const Badge = ({ label, children }: BadgeProps) => (
  <div className={styles.root}>
    <div className={styles.label}>{label}</div>
    {children}
  </div>
);

export default Badge;
