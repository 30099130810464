function urlValidation(query) {
  // this pattern is used validate the salesforce referrer id.
  const pattern = /^0062[a-zA-Z0-9]{14}_0032[a-zA-Z0-9]{14}$/;

  const splits = query.split("=");

  return (
    splits.length === 3 &&
    splits[0] === "?partner_source" &&
    splits[1] === "referrer_id" &&
    pattern.test(splits[2])
  );
}

export default urlValidation;
