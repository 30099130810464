/* @flow */

import React, { type Node, Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import ContactPersonalInformationForm from "./steps/ContactPersonalInformationForm";
import type {
  FileDescriptor,
  RootState,
  UIState,
  Contact,
  ContactPersonalInformation,
  ContactOwnership
} from "../../types";
import { createEmptyContact } from "./contactHelpers";
import OwnershipResolution from "./steps/OwnershipResolution";
import DocumentManager from "./steps/DocumentManager";
import Button from "../Button";
import styles from "../Modal.scss";
import IdentityVerification from "./steps/IdentityVerification";

type Step = {
  previous: string | null,
  renderFn: () => Node,
  next: () => Promise<string>
};

type AddEditContactFlowProps = {
  commitContact: (contact: Contact, correlationId?: number) => void,
  isAuthorizedSigner: boolean,
  seedContact: Contact | null,
  ui: UIState,
  countryCode: string,
  initialIdNumbers: Array<string>
};

type AddEditContactFlowState = {
  canAdvance: boolean,
  currentStep: string,
  contact: Contact
};

export class AddEditContactFlow extends PureComponent<
  AddEditContactFlowProps,
  AddEditContactFlowState
> {
  steps: {
    [string]: Step
  };

  constructor(props: AddEditContactFlowProps) {
    super(props);

    this.steps = {
      PERSONAL_INFORMATION: {
        previous: null,
        renderFn: () => (
          <ContactPersonalInformationForm
            isAuthorizedSigner={this.state.contact.authorizedSigner}
            onSuccess={this.handlePersonalInformationUpdate}
            contact={this.state.contact}
            initialIdNumbers={this.props.initialIdNumbers}
          />
        ),
        next: () => Promise.resolve("OWNERSHIP_RESOLUTION")
      },
      OWNERSHIP_RESOLUTION: {
        previous: "PERSONAL_INFORMATION",
        renderFn: () => (
          <OwnershipResolution
            isAuthorizedSigner={this.state.contact.authorizedSigner}
            onSuccess={this.handleOwnershipUpdate}
            initialValues={this.state.contact.ownership}
          />
        ),
        next: () => {
          const isControlProng =
            this.state.contact.ownership.beneficial_owner === "1" ||
            this.state.contact.ownership.significant_responsibility === "1";

          return Promise.resolve(
            this.props.countryCode === "USA" &&
              this.state.contact.authorizedSigner
              ? "IDENTITY_VERIFICATION"
              : isControlProng
              ? "DOCUMENT_MANAGEMENT"
              : "COMMIT"
          );
        }
      },
      DOCUMENT_MANAGEMENT: {
        previous: "OWNERSHIP_RESOLUTION",
        renderFn: () => (
          <DocumentManager
            documents={this.state.contact.documents}
            onSuccess={this.handleDocumentsUpdate}
          />
        ),
        next: () => Promise.resolve("COMMIT")
      },
      IDENTITY_VERIFICATION: {
        previous: "OWNERSHIP_RESOLUTION",
        renderFn: () => <IdentityVerification gotoNextStep={this.advance} />,
        next: () => Promise.resolve("COMMIT")
      }
    };

    const contact =
      props.seedContact ||
      createEmptyContact({
        authorizedSigner: this.props.isAuthorizedSigner,
        countryCode: props.countryCode
      });

    this.state = {
      canAdvance: true,
      currentStep: "PERSONAL_INFORMATION",
      contact
    };
  }

  handlePreviousButtonClick = (event: SyntheticEvent<>) => {
    event.preventDefault();
    const previousStep = this.steps[this.state.currentStep].previous;

    if (previousStep !== null) {
      this.setState({ currentStep: previousStep });
    }
  };

  handlePersonalInformationUpdate = (
    personalInformation: ContactPersonalInformation
  ) => {
    this.setState(state => ({
      contact: {
        ...state.contact,
        personalInformation
      }
    }));
    this.advance();
  };

  handleOwnershipUpdate = (ownership: ContactOwnership) => {
    this.setState(state => ({
      contact: {
        ...state.contact,
        ownership
      }
    }));
    this.advance();
  };

  handleDocumentsUpdate = (documents: FileDescriptor[]) => {
    this.setState(state => ({
      contact: {
        ...state.contact,
        documents
      }
    }));
    this.advance();
  };

  advance = () => {
    const { canAdvance, currentStep } = this.state;

    if (!canAdvance) {
      return;
    }

    this.setState({ canAdvance: false }, async () => {
      const nextStep = await this.steps[currentStep].next();
      switch (nextStep) {
        case "COMMIT":
          this.props.commitContact(this.state.contact);
          break;
        default:
          this.setState({ currentStep: nextStep, canAdvance: true });
          break;
      }
    });
  };

  render() {
    const currentStep = this.steps[this.state.currentStep];
    const canNavigateToPrevious = currentStep.previous !== null;

    return (
      <Fragment>
        {canNavigateToPrevious && (
          <div className={styles.backButton}>
            <Button
              intent="simple"
              size="small"
              disabled={this.props.ui.asyncActionInFlight}
              onClick={this.handlePreviousButtonClick}>
              &lsaquo; Back
            </Button>
          </div>
        )}
        {currentStep.renderFn()}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ ui, countryCode }: RootState) => ({
  ui,
  countryCode
});

// $FlowFixMe
export default connect(mapStateToProps)(AddEditContactFlow);
