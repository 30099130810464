import { object, string } from "yup";

export default object().shape({
  paypal_payer_id: string().when("$countryCode", {
    is: "BRA",
    then: string()
      .trim()
      .min(13, "Please enter a valid PP encrypted account number")
      .max(13, "Please enter a valid PP encrypted account number")
      .required("Please fill out this field")
  })
});
