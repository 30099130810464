import { uniq } from "lodash";
import { object, string, array } from "yup";
import {
  numericString,
  stringExists,
  booleanString,
  USPhoneString
} from "./helpers";
import { isFormattedDate, isInAgeRange } from "../";
import { addressFieldsForContact } from "./address";
import { SUPPORTED_EU_COUNTRY_CODES } from "../../countryHelper";

export const ContactPersonalInformationSchema = object().shape({
  date_of_birth: stringExists
    .test(
      "format",
      "Please use a YYYY-MM-DD or MM/DD/YYYY format",
      isFormattedDate
    )
    .test("age", "Invalid age", isInAgeRange(18, 100)),
  email: stringExists.email(),
  first_name: stringExists,
  id_number: string()
    .when("$isIDRequired", {
      is: true,
      then: string().ensure(),
      otherwise: string().notRequired()
    })
    .when(
      ["$isIDRequired", "$initialIdNumbers"],
      (isIDRequired, initialIdNumbers = [], schema) => {
        return schema.test({
          test: id_number =>
            !isIDRequired ||
            (initialIdNumbers.length && initialIdNumbers.includes(id_number)) ||
            id_number.match(/^\d{3}-?\d{2}-?\d{4}$/),
          message:
            "Please enter a valid United States Social Security Number (SSN)"
        });
      }
    ),
  job_title: stringExists,
  last_name: stringExists,
  phone: stringExists.when("$countryCode", {
    is: "USA",
    then: USPhoneString
  }),
  id_type: string().when(["$isIDRequired", "$countryCode"], {
    is: (isIDRequired, countryCode) =>
      countryCode === "MYS" ||
      countryCode === "SGP" ||
      countryCode === "HKG" ||
      (isIDRequired && countryCode === "USA"),
    then: string()
      .ensure()
      .matches(
        /(Identity Card|Passport|SOCIAL_SECURITY_NUMBER|ITIN)/,
        "Please select ID Type"
      ),
    otherwise: string().notRequired()
  }),
  address: addressFieldsForContact,
  ever_filed_for_bankruptcy: string().when("$countryCode", {
    is: "CAN",
    then: stringExists.oneOf(["0", "1"], "Please select an option")
  })
});

const hasDuplicateIdNumbers = contacts => {
  const idNumbers = contacts
    .filter(contact => contact?.personalInformation?.id_number)
    .map(contact => contact?.personalInformation?.id_number);

  return uniq(idNumbers).length !== idNumbers.length;
};

export const ContactOwnershipSchema = object()
  .shape({
    beneficial_owner: booleanString,
    significant_responsibility: booleanString,
    ownership_percentage: numericString.when("beneficial_owner", {
      is: "1",
      then: numericString
        .min(
          25,
          "Please enter a number between 25 and 100. If this contact owns less that 25% of the business, they do not need to be included on this application."
        )
        .max(100, "Please enter a number between 25 and 100")
        .required("Please fill out this field")
    })
  })
  .when("$authorizedSigner", {
    is: true,
    otherwise: object().test(
      "isBeneficialOwnerOrHasSiginficantResponsibility",
      "Additional business representatives must be a beneficial owner and/or have signficant responsibility",
      ownership =>
        ownership.beneficial_owner === "1" ||
        ownership.significant_responsibility === "1"
    )
  });

export const ContactCollectionSchema = array()
  .min(1, "Please add a business representative")
  .when("$countryCode", {
    is: value => ["USA", ...SUPPORTED_EU_COUNTRY_CODES].includes(value),
    then: array().test(
      "hasSignificantResponsibility",
      "Please provide a business representative that holds significant responsibility for managing the business such as CEO, COO, CFO or similar role.",
      contacts =>
        contacts.length === 0 ||
        contacts.some(
          contact => contact.ownership.significant_responsibility === "1"
        )
    )
  })
  .when("$countryCode", {
    is: "USA",
    then: array().test(
      "isTotalOwnershipPercentageCorrect",
      "The sum of ownership percentages in benefical owners must be equal or lower to 100.",
      contacts =>
        contacts.reduce(
          (acc, curr) =>
            acc + parseInt(curr.ownership.ownership_percentage || "0", 10),
          0
        ) <= 100
    )
  })
  .when("$countryCode", {
    is: "USA",
    then: array().test(
      "isIdNumberRepeated",
      "Tax identifiers must be unique across all contacts.",
      contacts => !hasDuplicateIdNumbers(contacts)
    )
  })
  .when("$countryCode", {
    is: "NZL",
    then: array().test(
      "hasOwnership",
      "Please provide at least one business representative who owns 25% or more of the business.",
      contacts =>
        contacts.some(contact =>
          Boolean(contact.ownership.ownership_percentage)
        )
    )
  });
