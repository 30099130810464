/* @flow */

import React, { type Node } from "react";

type ComponentProps = {
  children: Node,
  willUnmount?: () => void
};

export class Component extends React.Component<ComponentProps> {
  componentWillUnmount(): mixed {
    if (this.props.willUnmount) {
      this.props.willUnmount();
    }
  }

  render(): Node {
    return this.props.children;
  }
}
