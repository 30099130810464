/* @flow */

import React, { type Element } from "react";
import classNames from "classnames";
import styles from "./TextField.scss";
import shortId from "shortid";
import { type TextFieldProps, formats } from "./TextField.types";

type TextFieldState = {
  isFocused: boolean
};

export class TextFieldBase<T> extends React.Component<
  TextFieldProps<T>,
  TextFieldState
> {
  static defaultProps: $Shape<TextFieldProps<T>> = {
    value: ""
  };

  state = {
    isFocused: false
  };

  id: string = this.props.id === undefined ? shortId.generate() : this.props.id;

  // $FlowIssue: #4743
  handleBlur: (SyntheticFocusEvent<T>) => void = (
    event: SyntheticFocusEvent<T>
  ): void => {
    this.setState({ isFocused: false });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  // $FlowIssue: #4743
  handleFocus: (SyntheticFocusEvent<T>) => void = (
    event: SyntheticFocusEvent<T>
  ): void => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  // $FlowIssue: #4743
  formatValue: string => string = (value: string): string => {
    switch (this.props.format) {
      case formats.CURRENCY:
        return `$${value
          .replace(/(,|\$|\D)/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      default:
        return value;
    }
  };

  // $FlowIssue: #4743
  onRenderInput: RenderFunction<TextFieldProps<T>> = (
    props: TextFieldProps<T>
  ): Element<"input"> => {
    const {
      id,
      inputClassName,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      value,
      type,
      list
    } = props;

    return (
      <input
        className={inputClassName}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        type={type}
        value={this.formatValue(value)}
        list={list}
      />
    );
  };

  render() {
    const {
      description,
      labelText,
      value,
      errorText,
      onRenderInput = this.onRenderInput,
      onRenderSuffix
    } = this.props;
    const { isFocused } = this.state;
    const hasErrorMessage = Boolean(errorText);
    const isActivated = value || isFocused;
    const wrapperClassName = classNames(styles.wrapper, {
      [styles.focus]: isFocused && !hasErrorMessage,
      [styles.invalid]: hasErrorMessage && !isFocused,
      [styles.invalidAndFocused]: hasErrorMessage && isFocused
    });
    const labelClassName = classNames(styles.label, {
      [styles.collapse]: isActivated,
      [styles.focus]: isFocused,
      [styles.invalid]: hasErrorMessage && isActivated
    });
    const fieldContainerClassName = classNames(styles.fieldContainer, {
      [styles.isVisible]: isActivated
    });
    const inputProps = {
      ...this.props,
      id: this.id,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      inputClassName: classNames(styles.field, {
        [styles.hasSuffix]: onRenderSuffix !== undefined
      })
    };

    return (
      <div>
        <div className={wrapperClassName}>
          <label htmlFor={this.id} className={labelClassName}>
            {labelText}
          </label>
          <div className={fieldContainerClassName}>
            {onRenderInput(inputProps)}
          </div>
          {onRenderSuffix && (
            <div className={styles.suffix}>{onRenderSuffix(inputProps)}</div>
          )}
        </div>
        {hasErrorMessage && <div className={styles.error}>{errorText}</div>}
        {description !== undefined && (
          <div className={styles.description}>{description}</div>
        )}
      </div>
    );
  }
}
