/* @flow */

import type { LifecycleState, Action } from "../types";

const initialState: LifecycleState = {
  isSubmittable: false,
  submitWasAttempted: false,
  sharedError: ""
};

const lifecycleReducer = (
  state: LifecycleState = initialState,
  action: Action
): LifecycleState => {
  switch (action.type) {
    case "LIFECYCLE_SUBMIT":
      return {
        ...state,
        submitWasAttempted: true
      };
    default:
      return state;
  }
};

export default lifecycleReducer;
