export const Kount = {
  initialize(kountId) {
    if (!BraintreeData) {
      return;
    }

    const getBraintreeDataEnv = (env: string) => {
      switch (env) {
        case "production":
        case "sandbox":
          return env;
        default:
          return "qa";
      }
    };

    const kountEnv = BraintreeData.environments[
      getBraintreeDataEnv(process.env.NODE_ENV)
    ].withId(kountId);

    BraintreeData._setupCollector(kountEnv);
    BraintreeData.FraudNet.initialize();
    this._deviceSessionId = BraintreeData.deviceSessionId;
  },

  get deviceSessionId() {
    if (this._deviceSessionId) {
      return this._deviceSessionId;
    }
    return "";
  }
};
