/* @flow */

import React, { type StatelessFunctionalComponent } from "react";
import classNames from "classnames";
import styles from "./Link.scss";
import Anchor from "./Anchor";

type LinkProps = {
  children: string,
  href: string,
  intent: "primary" | "simple",
  target?: string
};

const Link: StatelessFunctionalComponent<LinkProps> = ({
  children,
  href,
  intent,
  target
}) => {
  const className = classNames(styles.link, styles[intent]);

  return (
    <Anchor className={className} href={href} target={target}>
      {children}
    </Anchor>
  );
};

export default Link;
