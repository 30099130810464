/* @flow */

import React from "react";
import classNames from "classnames";
import styles from "./AddButton.scss";
import Focus from "./Focus";
import { AddIcon } from "./Icons";

type AddButtonProps = {
  title: string,
  autoFocus?: boolean,
  disabled?: boolean,
  onClick?: (event: SyntheticMouseEvent<HTMLButtonElement>) => void
};

type AddButtonState = {
  isFocused: boolean,
  isHovered: boolean
};

class AddButton extends React.Component<AddButtonProps, AddButtonState> {
  state = {
    isFocused: false,
    isHovered: false
  };

  handleFocusAndBlur = (): void => {
    this.setState(state => ({
      isFocused: !state.isFocused
    }));
  };

  handleMouseOver = (): void => {
    this.setState({
      isHovered: true
    });
  };

  handleMouseOut = (): void => {
    this.setState({
      isHovered: false
    });
  };

  render() {
    const { title, autoFocus, disabled, onClick } = this.props;

    const { isFocused, isHovered } = this.state;

    const rootClassName = classNames(styles.root, styles.button, {
      [styles.disabled]: disabled
    });

    const badgeClassName = classNames(styles.badge, {
      [styles.focus]: isFocused,
      [styles.hover]: isHovered
    });

    const titleClassName = classNames(styles.title, {
      [styles.focus]: isFocused,
      [styles.hover]: isHovered
    });

    return (
      <Focus focus={autoFocus}>
        <button
          data-test-id="AddButton"
          type="button"
          disabled={disabled}
          className={rootClassName}
          onClick={onClick}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onBlur={this.handleFocusAndBlur}
          onFocus={this.handleFocusAndBlur}>
          <div className={badgeClassName}>
            <AddIcon />
          </div>
          <div className={titleClassName}>{title}</div>
        </button>
      </Focus>
    );
  }
}

export default AddButton;
