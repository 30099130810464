/* @flow */

import { object } from "yup";
import business from "../validators/schemas/business";
import { DisclaimersSchema } from "../validators/schemas/disclaimers";
import funding from "../validators/schemas/funding";
import paypalDetails from "../validators/schemas/paypalDetails";
import midTypes from "../validators/schemas/midTypes";
export const schema = object({
  business,
  disclaimers: DisclaimersSchema,
  funding,
  paypalDetails,
  midTypes
});
