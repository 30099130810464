/** @flow */

import { object, boolean } from "yup";

export const DisclaimersSchema = object().shape({
  attestation_given: boolean().oneOf(
    [true],
    "Must accept terms and conditions"
  ),
  disclosures_approved: boolean().oneOf(
    [true],
    "Must accept terms and conditions"
  ),
  venmo_terms_accepted: boolean().oneOf(
    [true],
    "Must accept terms and conditions"
  )
});
