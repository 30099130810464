/* @flow */

import React, { Fragment, type Node, useState } from "react";
import { USA_STATES } from "../../fields/regions";
import Fieldset from "../../components/Fieldset";
import Grid from "../../components/Grid";
import { Half, Third, Whole } from "../../components/GridItem";
import TextField from "../../components/TextField";
import MultilineTextField from "../../components/MultilineTextField";
import SelectField from "../../components/SelectField";
import Link from "../../components/Link";
import type {
  FileDescriptor,
  FileUploadStatus,
  Action,
  BusinessAddressState,
  BusinessValues,
  FormBlurHandlerEventArg,
  FormChangeHandlerEventArg,
  RootState,
  ValidationErrors
} from "../../types";
import BusinessAddress from "./BusinessAddress";
import { connect } from "react-redux";
import countryConfig from "../../countryConfig";
import GroupedList from "../../components/GroupedList";
import Button from "../../components/Button";
import ListCell from "../../components/ListCell";
import { Component } from "../../components/Component";
import Avatar from "../../components/Avatar";
import FilePicker from "../../components/FilePicker";
import Fuse from "fuse.js";

type BusinessProps = {
  dispatch: Action => Action,
  values: BusinessValues,
  errors: ValidationErrors,
  address: BusinessAddressState,
  countryCode: string,
  venmoOnly: boolean
};

export const Business = (props: BusinessProps): Node => {
  const { dispatch, values, errors, address, countryCode, venmoOnly } = props;
  const isCountryUsa = countryCode === "USA";
  const isCountryCan = countryCode === "CAN";
  const currentCountryConfig = countryConfig[countryCode];
  const taxIdLabel = currentCountryConfig.businessTaxIdLabel;
  const taxIdFieldName =
    currentCountryConfig.businessTaxIdFieldName || "tax_id";
  const [suggestions, setSuggestions] = useState([]);
  const formConfig = document.querySelector("#formConfig") || {};
  const { mcc = [] } = JSON.parse(formConfig.textContent || "{}");
  const fuse = new Fuse(mcc, {
    shouldSort: true,
    threshold: 0.4,
    distance: 200,
    maxPatternLength: 256,
    keys: ["code", "description", "sector"]
  });

  const searchMcc = query => fuse.search(query);
  const findIndustry = industry =>
    mcc.find(item => item.description === industry);

  const handleBlur = (event: FormBlurHandlerEventArg) => {
    const { name } = event.currentTarget;

    if (isCountryUsa && name === "industry") {
      const { value } = event.currentTarget;

      if (!findIndustry(value)) {
        // 1. reset industry to empty
        dispatch({
          type: "BUSINESS_FIELD_CHANGE",
          payload: { name, value: "" }
        });

        // 2. reset the overridden MCC to empty
        dispatch({
          type: "BUSINESS_FIELD_CHANGE",
          payload: { name: "mcc", value: "" }
        });
      }

      // 3. reset the dynamic options
      setSuggestions([]);
    }
    dispatch({
      type: "BUSINESS_FIELD_BLUR",
      payload: { name }
    });
  };

  const handleChange = (event: FormChangeHandlerEventArg) => {
    const { name, value } = event.currentTarget;

    if (isCountryUsa && name === "industry") {
      const industry = findIndustry(value);

      if (industry) {
        dispatch({
          type: "BUSINESS_FIELD_CHANGE",
          payload: { name: "mcc", value: industry.code }
        });
      } else {
        setSuggestions(searchMcc(value));
      }
    }
    dispatch({
      type: "BUSINESS_FIELD_CHANGE",
      payload: { name, value }
    });
  };

  const handleAddressBlur = (event: FormBlurHandlerEventArg) => {
    const { name } = event.currentTarget;
    dispatch({
      type: "BUSINESS_ADDRESS_FIELD_BLUR",
      payload: { name }
    });
  };

  const handleAddressChange = (event: FormChangeHandlerEventArg) => {
    const { name, value } = event.currentTarget;
    dispatch({
      type: "BUSINESS_ADDRESS_FIELD_CHANGE",
      payload: { name, value }
    });
  };

  const handleFileCollectionAdd = (newFile: FileDescriptor) => {
    dispatch({
      type: "BUSINESS_LOGO_ADD",
      payload: newFile
    });
  };

  const handleFileCollectionRemove = (fileToRemove: FileDescriptor) => {
    dispatch({
      type: "BUSINESS_LOGO_REMOVE",
      payload: fileToRemove
    });
  };

  const handleFileUploadStatusChange = (status: FileUploadStatus) => {
    if (status === "uploading") {
      dispatch({ type: "UI_ASYNC_ACTION_START" });
    } else if (status === "complete") {
      dispatch({ type: "UI_ASYNC_ACTION_COMPLETE" });
    }
  };

  const renderBusinessLogoFields = () => {
    return (
      <FilePicker
        title="Business Logo"
        buttonTitle="Upload your business logo here..."
        files={values.logo_image}
        displayAsCard={true}
        onFileUpload={handleFileCollectionAdd}
        onFileRemove={handleFileCollectionRemove}
        onFileUploadStatus={handleFileUploadStatusChange}
        acceptType=".png"
        description="Business logo image will be displayed in venmo app. Image size should not exceed 700 kb. Image dimensions should be 1024X1024."
        requiredDimensions="1024X1024"
        maxSize="740000"
      />
    );
  };

  return (
    <Fieldset
      title="Information About Your Business"
      description="The information you provide below will help us verify and understand your&nbsp;business.">
      <GroupedList title="Business Location" displayAsCard={true}>
        <ListCell
          leftAccessory={
            <Avatar
              src={`https://assets.braintreegateway.com/images/country-flags/96/${countryCode.toLowerCase()}.png`}
            />
          }
          title={countryConfig[countryCode].name}
          rightAccessory={
            countryConfig[countryCode].isEU ? (
              <Button intent="secondary" size="small" href="/country/eu">
                Change
              </Button>
            ) : null
          }
        />
      </GroupedList>
      <GroupedList title="Business Details" displayAsCard={true}>
        <Grid>
          <Whole>
            <TextField
              errorText={errors.dba_name}
              labelText="Business Name"
              name="dba_name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.dba_name}
            />
          </Whole>
          <Third>
            <SelectField
              errorText={errors.registered_as}
              labelText="Business Type"
              name="registered_as"
              onBlur={handleBlur}
              onChange={handleChange}
              options={[
                {
                  text: "Limited Liability Company",
                  key: "limited_liability_corporation"
                },
                { text: "Private Corporation", key: "private_corporation" },
                { text: "Public Corporation", key: "public_corporation" },
                { text: "Sole Proprietorship", key: "sole_proprietorship" },
                { text: "Partnership / LLP", key: "partnership_llp" },
                { text: "Tax Exempt", key: "tax_exempt" }
              ]}
              value={values.registered_as}
            />
          </Third>
          <Third>
            {isCountryUsa && (
              <Fragment>
                <TextField
                  errorText={errors.industry}
                  labelText="Industry"
                  name="industry"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.industry}
                  placeholder="Search for an industry..."
                  list="mcc"
                />
                <datalist id="mcc">
                  {suggestions.map((item, key) => (
                    <option key={key} value={item.description}>
                      #{item.code} for {item.sector}
                    </option>
                  ))}
                </datalist>
              </Fragment>
            )}
            {!isCountryUsa && (
              <SelectField
                errorText={errors.industry}
                labelText="Industry"
                name="industry"
                onBlur={handleBlur}
                onChange={handleChange}
                options={[
                  { text: "Charity/Tax Exempt", key: "non_profit" },
                  { text: "Retail/Physical Goods", key: "generic_retail" },
                  { text: "Services", key: "generic_service" },
                  { text: "Software", key: "generic_software" },
                  { text: "Other", key: "retail_other" }
                ]}
                value={values.industry}
              />
            )}
          </Third>
          <Third>
            <TextField
              errorText={errors.established_on}
              labelText="Start Date"
              name="established_on"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={countryConfig[countryCode].datePlaceholder}
              value={values.established_on}
            />
          </Third>

          {isCountryCan && (
            <Whole>
              <SelectField
                errorText={errors.business_location_type}
                labelText="Type of Business Location"
                name="business_location_type"
                onBlur={handleBlur}
                onChange={handleChange}
                options={[
                  { text: "Retail Store Front", key: "retail_store_front" },
                  { text: "Warehouse", key: "warehouse" },
                  { text: "Home Office", key: "home_office" },
                  { text: "Kiosk", key: "kiosk" },
                  { text: "Website", key: "website" },
                  { text: "Office", key: "office" },
                  {
                    text: "Exhibition & Conventions",
                    key: "exhibition_and_conventions"
                  }
                ]}
                value={values.business_location_type}
              />
            </Whole>
          )}

          {isCountryUsa && values.registered_as !== "sole_proprietorship" && (
            <Component
              willUnmount={() => {
                dispatch({
                  type: "REMOVE_FORM_VALUE",
                  payload: ["legal_name", "tax_id", "state_incorporated"]
                });
              }}>
              <Half>
                <TextField
                  errorText={errors.legal_name}
                  labelText="Legal Name"
                  name="legal_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.legal_name}
                />
              </Half>
              <Half>
                <TextField
                  errorText={errors.tax_id}
                  labelText={taxIdLabel}
                  name="tax_id"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.tax_id}
                />
              </Half>
              <Whole>
                <SelectField
                  errorText={errors.state_incorporated}
                  labelText="State of Business Registration"
                  name="state_incorporated"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  options={USA_STATES}
                  value={values.state_incorporated}
                />
              </Whole>
            </Component>
          )}
          {!isCountryUsa && (
            <Fragment>
              <Half>
                <TextField
                  errorText={errors.legal_name}
                  labelText="Legal Name"
                  name="legal_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.legal_name}
                />
              </Half>
              <Half>
                <TextField
                  errorText={errors[taxIdFieldName]}
                  labelText={taxIdLabel}
                  name={taxIdFieldName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values[taxIdFieldName]}
                />
              </Half>
            </Fragment>
          )}
          <Half>
            <TextField
              errorText={errors.phone}
              labelText="Phone Number"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={countryConfig[countryCode].phonePlaceholder}
              type="tel"
              value={values.phone}
              description="Please note this phone number will appear on customer statements."
            />
          </Half>
          <Half>
            <TextField
              errorText={errors.website}
              labelText="Website"
              name="website"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="http://example.com"
              value={values.website}
            />
          </Half>
          <Whole>
            <MultilineTextField
              errorText={errors.description}
              description={
                <div>
                  Please tell us about your business including what products or
                  services you sell online and who you sell to. Your business
                  must operate within the permitted use of Braintree services
                  outlined in our{" "}
                  <Link
                    href={`${countryConfig[countryCode].legalUrl}/acceptable-use-policy`}
                    intent="simple"
                    target="_blank">
                    Acceptable Use Policy
                  </Link>
                  .
                </div>
              }
              labelText="Business Description"
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
            />
          </Whole>
          {venmoOnly && (
            <Whole>
              <TextField
                errorText={errors.email}
                labelText="Email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                description="Please note this email will appear on customer statements."
              />
            </Whole>
          )}
        </Grid>
      </GroupedList>
      {venmoOnly && renderBusinessLogoFields()}

      <GroupedList title="Business Address" displayAsCard={true}>
        <BusinessAddress
          address={address}
          onBlur={handleAddressBlur}
          onChange={handleAddressChange}
          countryCode={countryCode}
        />
      </GroupedList>

      {isCountryCan && (
        <GroupedList
          title="Staging Environment (Optional)"
          displayAsCard={true}>
          <Grid>
            <Whole>
              <TextField
                errorText={errors.staging_website_url}
                labelText="Staging Website URL"
                onBlur={handleBlur}
                onChange={handleChange}
                name="staging_website_url"
                value={values.staging_website_url}
              />
            </Whole>
            <Half>
              <TextField
                errorText={errors.staging_login}
                labelText="Staging Login"
                onBlur={handleBlur}
                onChange={handleChange}
                name="staging_login"
                value={values.staging_login}
              />
            </Half>
            <Half>
              <TextField
                errorText={errors.staging_password}
                labelText="Staging Password"
                onBlur={handleBlur}
                onChange={handleChange}
                name="staging_password"
                value={values.staging_password}
              />
            </Half>
          </Grid>
        </GroupedList>
      )}
    </Fieldset>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.business,
  countryCode: state.countryCode,
  venmoOnly: state.venmoOnly
});

// $FlowFixMe
export default connect(mapStateToProps)(Business);
