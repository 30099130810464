/* @flow */

import React from "react";
import styles from "./NavigationBar.scss";
import Logo from "./Logo";
import { useMedia } from "./useMedia";
import { CONTACT_SALES_LINK } from "../../../common/index";

const NavigationBar = () => {
  const matchesMedia = useMedia("(min-width: 640px)");

  return (
    <div className={styles.root}>
      <a
        className={styles.option}
        aria-label="Braintree"
        href="https://braintreepayments.com">
        <Logo />
      </a>

      <div>
        {matchesMedia && (
          <a
            className={styles.option}
            href="https://developer.paypal.com/braintree/help"
            target="_blank">
            Get Help
          </a>
        )}
        <a className={styles.option} href={CONTACT_SALES_LINK} target="_blank">
          Contact Sales
        </a>
      </div>
    </div>
  );
};

export default NavigationBar;
