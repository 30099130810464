/* @flow */

import React, { useCallback, type Element } from "react";
import { TextFieldBase } from "./TextFieldBase";
import type { TextFieldProps } from "./TextField.types";
import styles from "./MultilineTextField.scss";
import classNames from "classnames";

const TextField = class extends TextFieldBase<HTMLTextAreaElement> {};

const MultilineTextField = (
  props: TextFieldProps<HTMLTextAreaElement>
): Element<typeof TextField> => {
  const { value } = props;
  const adjustHeight = useCallback(
    (textArea: HTMLTextAreaElement | null): void => {
      if (textArea !== null) {
        textArea.style.height = "auto";
        textArea.style.height = `${textArea.scrollHeight}px`;
      }
    },
    [value]
  );

  return (
    <TextField
      {...props}
      onRenderInput={(
        fieldProps: TextFieldProps<HTMLTextAreaElement>
      ): Element<"textarea"> => {
        const {
          id,
          inputClassName,
          name,
          onBlur,
          onChange,
          onFocus,
          value
        } = fieldProps;

        return (
          <textarea
            className={classNames(styles.textArea, inputClassName)}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            ref={adjustHeight}
            rows={1}
            value={value}
          />
        );
      }}
    />
  );
};

MultilineTextField.defaultProps = TextField.defaultProps;

export default MultilineTextField;
