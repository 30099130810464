/* @flow */

import { omit } from "lodash";
import dayjs from "dayjs";

import type {
  BusinessAddressValues,
  BusinessState,
  BusinessValues,
  FileDescriptor,
  FundingState,
  FundingValues,
  FundingAddressValues,
  ProcessingState,
  ProcessingValues,
  PayPalDetailsValues,
  PayPalDetailsState,
  RootState,
  SerializedContact,
  SubscriptionPercentagesType,
  MidTypesState,
  MidTypesValues
} from "../types";
import countryConfig from "../countryConfig";

export const serializeDateForServer = (value: string): string => {
  const date = dayjs(value).format();

  return date.substr(0, 10);
};

const serializeDocuments = (documents: FileDescriptor[]): string[] =>
  documents.reduce((documents: string[], document: FileDescriptor) => {
    if (document.id !== undefined) {
      documents.push(document.id);
    }

    return documents;
  }, []);

const serializeLogoImage = (images: FileDescriptor[]): string => {
  if (images && images.length > 0 && images[0].id !== undefined) {
    return images[0].id;
  }
  return "";
};

const serializeBusiness = (
  business: BusinessState,
  countryCode: string,
  venmoOnly: boolean
): {
  ...BusinessValues,
  // $FlowFixMe
  ...BusinessAddressValues
} => {
  const { address, ...rootFields } = business;
  const result = {
    ...rootFields.values,
    established_on: serializeDateForServer(rootFields.values.established_on),
    address: { ...address.values, country: countryCode }
  };
  const isPayWithVenmo = rootFields.values.pay_with_venmo === "1";
  if (venmoOnly || isPayWithVenmo) {
    result.logo_image_id = serializeLogoImage(result.logo_image);
    return omit(result, "logo_image");
  }
  // Email, logo and logo_image_id are needed for venmo only form
  return omit(result, ["email", "logo_image", "logo_image_id"]);
};

const serializePayPalDetails = (
  paypalDetails: PayPalDetailsState
): {
  ...PayPalDetailsValues
} => {
  if (paypalDetails) {
    return {
      ...paypalDetails.values
    };
  }
  return {};
};

const serializeMidTypes = (
  midTypes: MidTypesState
): {
  ...MidTypesValues
} => {
  if (midTypes) {
    const result = { ...midTypes.values };
    if (!result.credit_mid) {
      result.credit_settlement_type = "";
    }
    return result;
  }
  return {};
};

type ProcessingSerializedValues = ProcessingValues & {
  documents?: string[],
  subscription_percentages?: SubscriptionPercentagesType
};

type FundingSerializedValues = FundingValues & {
  documents?: string[],
  iban?: string,
  bank_address?: FundingAddressValues
};

export const serializeProcessing = (
  processing: ProcessingState
): ProcessingSerializedValues => {
  const { documents, subscriptionPercentages, ...rootFields } = processing;
  const result: ProcessingSerializedValues = { ...rootFields.values };
  result.documents = serializeDocuments(documents);

  if (result.offers_subscriptions) {
    result.subscription_percentages = subscriptionPercentages.value;
  }

  return omit(result, ["existing_merchant", "merchant_public_id"]);
};

const serializeFunding = (
  funding: FundingState,
  countryCode: string
): {
  ...FundingSerializedValues,
  documents: string[]
} => {
  const { documents, ...rootFields } = funding;
  const result: FundingSerializedValues = { ...rootFields.values };
  result.documents = serializeDocuments(documents);

  if (result.account_number.includes("*****")) {
    delete result.account_number;
  }

  if (["MYS", "HKG", "SGP"].includes(countryCode)) {
    result.bank_address = rootFields.address.values;
    return omit(result, [
      "iban",
      "routing_number",
      "bank_account_holder_name",
      "transit_number",
      "institution_code"
    ]);
  }

  if (countryCode === "CAN") {
    return omit(result, ["bic", "iban", "routing_number"]);
  }

  if (countryConfig[countryCode].fillBicAndIbanFields) {
    result.iban = result.account_number;
    result.bic = result.routing_number;
    return omit(result, [
      "account_number",
      "routing_number",
      "bank_name",
      "bank_account_holder_name",
      "transit_number",
      "institution_code"
    ]);
  }

  return omit(result, [
    "bic",
    "iban",
    "bank_name",
    "bank_account_holder_name",
    "transit_number",
    "institution_code"
  ]);
};

const serializeContacts = (
  { contacts, disclaimers }: RootState,
  countryCode: string
): SerializedContact[] =>
  contacts.values.map(contact => {
    const personalInformation = ["MYS", "HKG", "SGP", "USA"].includes(
      countryCode
    )
      ? contact.personalInformation
      : omit(contact.personalInformation, ["id_type"]);
    if (
      typeof personalInformation.id_number !== "undefined" &&
      personalInformation.id_number.includes("*****")
    ) {
      delete personalInformation.id_number;
    }
    const serializedContact: SerializedContact = {
      ...(contact.id.length > 10 ? { id: contact.id } : {}), // frontend generated ids include 9-10 chars
      ...contact.ownership,
      ...personalInformation,
      date_of_birth: serializeDateForServer(
        contact.personalInformation.date_of_birth
      ),
      documents: serializeDocuments(contact.documents),
      authorized_signer: contact.authorizedSigner,
      _destroy: contact._destroy
    };

    if (countryCode === "USA" && contact.authorizedSigner) {
      serializedContact.attestation_given =
        disclaimers.values.attestation_given;
    }

    return serializedContact;
  });

type Metadata = { partner_id?: string | null, kount_id?: string };

export const serializeForm = (
  data: RootState,
  metadata: Metadata,
  countryCode: string,
  sendAdditionalFunding: boolean
) => {
  const payload = {
    currency_code: countryConfig[countryCode].currencyISOCode,
    existing_merchant: data.processing.values.existing_merchant,
    merchant_public_id: data.processing.values.merchant_public_id,
    disclosures_approved: data.disclaimers.values.disclosures_approved,
    metadata,
    workflow: countryConfig[countryCode].workflow,
    business: serializeBusiness(data.business, countryCode, data.venmoOnly),
    contacts: serializeContacts(data, countryCode),
    funding: serializeFunding(data.funding, countryCode),
    processing: serializeProcessing(data.processing),
    paypalDetails: serializePayPalDetails(data.paypalDetails),
    intake_profile: data.venmoOnly === true ? "venmo_midless" : null,
    mid_types: serializeMidTypes(data.midTypes),
    is_final_step: true
  };
  if (sendAdditionalFunding) {
    // $FlowFixMe
    payload.additional_funding = serializeFunding(
      data.additionalFunding,
      countryCode
    );
  }
  if (data.business.values.pay_with_venmo === "1") {
    payload.venmo_terms_accepted = data.disclaimers.values.venmo_terms_accepted;
  }
  return payload;
};

export const serializeFormForUpdate = (
  data: RootState,
  countryCode: string
) => ({
  currency_code: countryConfig[countryCode].currencyISOCode,
  existing_merchant: data.processing.values.existing_merchant,
  business: serializeBusiness(data.business, countryCode, data.venmoOnly),
  contacts: serializeContacts(data, countryCode),
  funding: serializeFunding(data.funding, countryCode),
  processing: serializeProcessing(data.processing),
  intake_profile: data.venmoOnly === true ? "venmo_midless" : null
});
