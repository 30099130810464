/* @flow */

import React, { type Element } from "react";
import classNames from "classnames";
import { TextFieldBase } from "./TextFieldBase";
import type { TextFieldProps } from "./TextField.types";
import styles from "./SelectField.scss";
import type { SelectFieldOption } from "../types";
import triangleDown from "../images/arrow-down.svg";

type SelectFieldProps = TextFieldProps<HTMLSelectElement> & {
  options: SelectFieldOption[]
};

const TextField = class extends TextFieldBase<HTMLSelectElement> {};

const SelectField = (props: SelectFieldProps): Element<typeof TextField> => (
  <TextField
    {...props}
    onRenderInput={(
      fieldProps: TextFieldProps<HTMLSelectElement>
    ): Element<"select"> => {
      const { options } = props;
      const {
        id,
        inputClassName,
        name,
        onBlur,
        onChange,
        onFocus,
        value
      } = fieldProps;

      return (
        <select
          className={classNames(styles.select, inputClassName)}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          value={value}>
          <option disabled={true} value="">
            Select One...
          </option>
          {options.map(({ key, text }: SelectFieldOption) => (
            <option key={key} value={key}>
              {text}
            </option>
          ))}
        </select>
      );
    }}
    onRenderSuffix={(): Element<"img"> => (
      <img src={triangleDown} width="24" height="24" alt="" />
    )}
  />
);

SelectField.defaultProps = TextField.defaultProps;

export default SelectField;
