import { object, array, string } from "yup";

export default object()
  .shape({
    registered_as: string(),
    beneficial_owners: array().of(string())
  })
  .when("$countryCode", {
    is: "USA",
    then: object().test(
      "hasUpToOneBeneficialOwner",
      "Please provide no more than 1 beneficial owner if your Business type is Sole Proprietorship.",
      data => {
        const { registered_as, beneficial_owners } = data;

        const validation =
          registered_as !== "sole_proprietorship" ||
          (registered_as === "sole_proprietorship" &&
            beneficial_owners.filter(b => b === "1").length <= 1);

        return validation;
      }
    )
  });
