/* @flow */

import React from "react";
import type { ContactPersonalInformation } from "../../types";
import styles from "./ContactPersonalInformationPopulator.scss";
import { createContact } from "../../dev/hydrateStore";

const ContactPersonalInformationPopulator = ({
  countryCode,
  onClick
}: {
  countryCode: string,
  onClick: (values: ContactPersonalInformation) => void
}) => (
  <div className={styles.bg}>
    <button
      className={styles.button}
      onClick={event => {
        event.preventDefault();
        onClick(createContact(countryCode).personalInformation);
      }}>
      Populate Contact Info
    </button>
  </div>
);

export default ContactPersonalInformationPopulator;
