/* @flow */

import React, { type ChildrenArray, type Node } from "react";

type Intrinsics = {
  color: string
};

type IconProps = {
  children: ChildrenArray<Node>
};

const SVG = ({ children, color }: Intrinsics & IconProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={color}>
    {children}
  </svg>
);

SVG.defaultProps = {
  color: "inherit"
};

export const AddIcon = ({ color }: Intrinsics) => (
  <SVG color={color}>
    <path d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" />
  </SVG>
);

AddIcon.defaultProps = SVG.defaultProps;

export const FileIcon = ({ color }: Intrinsics) => (
  <SVG color={color}>
    <path d="M6,2h8l6,6V20a1.88,1.88,0,0,1-.61,1.38A2,2,0,0,1,18,22H6a2,2,0,0,1-1.41-.58A1.88,1.88,0,0,1,4,20L4,4A1.89,1.89,0,0,1,4.62,2.6,1.88,1.88,0,0,1,6,2Zm7,7h5.54L13,3.52Z" />
  </SVG>
);

FileIcon.defaultProps = SVG.defaultProps;

export const HelpIcon = ({ color }: Intrinsics) => (
  <SVG color={color}>
    <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" />
  </SVG>
);

HelpIcon.defaultProps = SVG.defaultProps;

export const RemoveIcon = ({ color }: Intrinsics) => (
  <SVG color={color}>
    <path d="M19,6.42,13.41,12,19,17.58,17.58,19,12,13.41,6.42,19,5,17.58,10.59,12,5,6.42,6.42,5,12,10.59,17.58,5Z" />
  </SVG>
);

RemoveIcon.defaultProps = SVG.defaultProps;

export const AlertIcon = ({ color }: Intrinsics) => (
  <SVG color={color}>
    <path d="M5,5A9.57,9.57,0,0,1,12,2,9.57,9.57,0,0,1,19.05,5,9.57,9.57,0,0,1,22,12a9.57,9.57,0,0,1-2.93,7.05A9.57,9.57,0,0,1,12,22a9.57,9.57,0,0,1-7-2.93A9.57,9.57,0,0,1,2,12,9.57,9.57,0,0,1,5,5Zm8,8V7H11v6Zm0,4V15H11v2Z" />
  </SVG>
);

AlertIcon.defaultProps = SVG.defaultProps;

export type IconType =
  | typeof AddIcon
  | typeof FileIcon
  | typeof HelpIcon
  | typeof RemoveIcon
  | typeof AlertIcon;
