/* @flow */

import React, { type StatelessFunctionalComponent, type Node } from "react";
import classNames from "classnames";
import styles from "./GridItem.scss";

type GridItemProps = {|
  children: Node
|};

const getGridItemClassName = className =>
  classNames(styles.ie11GridItemSpacing, className);

const Whole: StatelessFunctionalComponent<GridItemProps> = props => (
  <div {...props} className={getGridItemClassName(styles.whole)} />
);

const Half: StatelessFunctionalComponent<GridItemProps> = props => (
  <div {...props} className={getGridItemClassName(styles.half)} />
);

const Third: StatelessFunctionalComponent<GridItemProps> = props => (
  <div {...props} className={getGridItemClassName(styles.third)} />
);

const Quarter: StatelessFunctionalComponent<GridItemProps> = props => (
  <div {...props} className={getGridItemClassName(styles.quarter)} />
);

const TwoThirds: StatelessFunctionalComponent<GridItemProps> = props => (
  <div {...props} className={getGridItemClassName(styles.twoThirds)} />
);

export type GridItemType =
  | typeof Whole
  | typeof Half
  | typeof Third
  | typeof Quarter;

export { Whole, Half, Third, Quarter, TwoThirds };
export default { Whole, Half, Third, Quarter, TwoThirds };
