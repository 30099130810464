/* @flow */

import React, {
  type StatelessFunctionalComponent,
  type ChildrenArray,
  type Node
} from "react";
import styles from "./Grid.scss";

type GridProps = {
  children: ChildrenArray<Node> | Node
};

const Grid: StatelessFunctionalComponent<GridProps> = ({ children }) => (
  <div className={styles.root}>{children}</div>
);

export default Grid;
