/* @flow */

import React, { type Element } from "react";
import { Switch, Route, type Match } from "react-router-dom";
import KiqQuestions from "./KiqQuestions";
import KiqDocuments from "./KiqDocuments";

type KiqProps = {
  match: Match
};

const Kiq = (props: KiqProps): Element<"div"> => {
  const { match } = props;

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/documents`} component={KiqDocuments} />
        <Route component={KiqQuestions} />
      </Switch>
    </div>
  );
};

export default Kiq;
