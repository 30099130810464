/* @flow */

import React, { type Node } from "react";
import type { DisclaimersValues, Action, Contact } from "../types";
import { connect } from "react-redux";
import { Checkbox } from "./Checkbox";
import ErrorBlock from "./ErrorBlock";
import Link from "./Link";
import styles from "./Disclaimers.scss";
import countryConfig from "../countryConfig";

type DisclaimerProps = {
  dispatch: Action => Action,
  values: DisclaimersValues,
  errorMessage?: string,
  contacts: Contact[],
  countryCode: string
};

export const Disclaimers = (props: DisclaimerProps): Node => {
  const {
    countryCode,
    values,
    errorMessage,
    dispatch,
    contacts,
    business
  } = props;
  const isPayWithVenmo = business?.values?.pay_with_venmo === "1";

  const handleBlur = (name: string) => () => {
    dispatch({
      type: "DISCLAIMERS_FIELD_BLUR",
      payload: { name }
    });
  };

  const handleChange = (name: string) => () => {
    dispatch({
      type: "DISCLAIMERS_FIELD_CHANGE",
      payload: { name, value: !values[name] }
    });
  };

  const getAttestationDescription = () => {
    let authorizedSignerName = " ";
    const authorizedSigner = contacts.find(contact => contact.authorizedSigner);

    if (authorizedSigner) {
      const { first_name, last_name } = authorizedSigner.personalInformation;

      authorizedSignerName = `, ${first_name} ${last_name}, `;
    }

    return `I${authorizedSignerName}hereby certify, to the best of my knowledge, that the information provided above is complete and correct.`;
  };

  const getDisclosuresDescription = (countryCode: string) => {
    const legalUrl = countryConfig[countryCode].legalUrl;

    const paymentServicesLink = (
      <Link
        href={`${legalUrl}/payment-services-agreement`}
        intent="simple"
        target="_blank">
        Payment Services Agreement
      </Link>
    );
    const bankAgreementLink = (
      <Link href={`${legalUrl}/bank-agreement`} intent="simple" target="_blank">
        Commercial Entity Agreement
      </Link>
    );
    const privacyPolicyLink = (
      <Link
        href="https://www.paypal.com/us/legalhub/privacy-full"
        intent="simple"
        target="_blank">
        Privacy Statement
      </Link>
    );
    const acceptableUsePolicyLink = (
      <Link
        href={`${legalUrl}/acceptable-use-policy`}
        intent="simple"
        target="_blank">
        Acceptable Use Policy
      </Link>
    );

    if (countryCode === "USA") {
      return (
        <div>
          By clicking Submit Application below, I agree to the{" "}
          {paymentServicesLink}, the applicable {bankAgreementLink}, the{" "}
          {privacyPolicyLink}, the {acceptableUsePolicyLink}, and the{" "}
          <Link
            href="https://www.paypal.com/us/webapps/mpp/ua/esign-full?locale.x=en_US"
            intent="simple"
            target="_blank">
            Electronic Signature Terms
          </Link>
          , and I am providing written instructions under the Fair Credit
          Reporting Act authorizing Braintree, a service of PayPal, to obtain
          and use information from my personal credit report to process this
          application.
        </div>
      );
    }

    return (
      <div>
        I agree to the {paymentServicesLink}, my applicable {bankAgreementLink},
        the {privacyPolicyLink}, and the {acceptableUsePolicyLink}.
      </div>
    );
  };

  const getVenmoTermsDescription = () => {
    const venmoTermsLink = (
      <Link
        href="https://www.paypal.com/us/legalhub/braintree/venmo-services-terms"
        intent="simple"
        target="_blank">
        Venmo Services Terms
      </Link>
    );

    return (
      <div>By enabling Pay with Venmo, I agree to the {venmoTermsLink}.</div>
    );
  };

  const formConfig = document.querySelector("#formConfig") || {};
  const { features = {} } = JSON.parse(formConfig.textContent || "{}");

  return (
    <div className={styles.root}>
      {errorMessage !== undefined && (
        <div className={styles.disclaimer}>
          <ErrorBlock message={errorMessage} />
        </div>
      )}

      <div className={styles.disclaimer}>
        <Checkbox
          name="attestation_given"
          description={getAttestationDescription()}
          checked={values.attestation_given}
          onBlur={handleBlur("attestation_given")}
          onChange={handleChange("attestation_given")}
        />
      </div>

      {features.payWithVenmo && isPayWithVenmo && (
        <div className={styles.disclaimer}>
          <Checkbox
            name="venmo_terms_accepted"
            description={getVenmoTermsDescription()}
            checked={values.venmo_terms_accepted}
            onBlur={handleBlur("venmo_terms_accepted")}
            onChange={handleChange("venmo_terms_accepted")}
          />
        </div>
      )}

      <div className={styles.disclaimer}>
        <Checkbox
          name="disclosures_approved"
          description={getDisclosuresDescription(countryCode)}
          checked={values.disclosures_approved}
          onBlur={handleBlur("disclosures_approved")}
          onChange={handleChange("disclosures_approved")}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { disclaimers, contacts, countryCode, business } = state;
  const { errors, values } = disclaimers;
  const errorMessage =
    errors.attestation_given ||
    errors.disclosures_approved ||
    errors.venmo_terms_accepted;
  return {
    contacts: contacts.values,
    errorMessage,
    values,
    countryCode,
    business
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(Disclaimers);
