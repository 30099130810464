/* @flow */

import React, { type Element } from "react";
import GroupedList from "./GroupedList";
import classNames from "classnames";
import styles from "./SliderGroup.scss";

export type SliderGroupOption = {
  key: string,
  text: string,
  value: number
};

type SliderGroupProps = {
  errorMessage?: string,
  onBlur?: (event: SyntheticFocusEvent<>) => void,
  onChange?: (event: SyntheticInputEvent<>, key: string, value: number) => void,
  onFocus?: (event: SyntheticFocusEvent<>) => void,
  options: SliderGroupOption[],
  step: number,
  title?: string,
  displayAsCard?: boolean
};

class SliderGroup extends React.Component<SliderGroupProps> {
  static defaultProps: $Shape<SliderGroupProps> = {
    step: 1
  };

  handleBlur = (event: SyntheticFocusEvent<HTMLElement>): void => {
    if (
      !event.currentTarget.contains(document.activeElement) &&
      this.props.onBlur
    ) {
      this.props.onBlur(event);
    }
  };

  handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>): void => {
    const onChange = this.props.onChange;
    if (onChange) {
      const { name, value } = event.currentTarget;
      onChange(event, name, Number(value));
    }
  };

  renderLabel(option: SliderGroupOption): Element<"span"> {
    return <span>{option.text}</span>;
  }

  renderField(option: SliderGroupOption): Element<"input"> {
    return (
      <input
        className={styles.input}
        name={option.key}
        onChange={this.handleInputChange}
        step={this.props.step}
        type="range"
        value={option.value}
      />
    );
  }

  renderValue(option: SliderGroupOption): Element<"span"> {
    return <span>{`${option.value}%`}</span>;
  }

  render() {
    const { options, errorMessage, onFocus, title, displayAsCard } = this.props;
    const total = options.reduce(
      (accumulator, option) => accumulator + option.value,
      0
    );
    const totalClass = classNames(styles.total, {
      [styles.error]: errorMessage
    });

    return (
      <div className={styles.root}>
        <GroupedList title={title} displayAsCard={displayAsCard}>
          <div onBlur={this.handleBlur} onFocus={onFocus}>
            {options.map(option => (
              <div className={styles.item} key={option.key}>
                <div className={styles.title}>{this.renderLabel(option)}</div>
                <div className={styles.total}>{this.renderValue(option)}</div>
                <div className={styles.description}>
                  {this.renderField(option)}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.item}>
            <div className={styles.title}>Total</div>
            <div className={totalClass}>{total}%</div>
            <div className={styles.description}>
              {errorMessage !== undefined && (
                <div className={styles.error}>{errorMessage}</div>
              )}
            </div>
          </div>
        </GroupedList>
      </div>
    );
  }
}

export default SliderGroup;
