/* @flow */

import React, { type StatelessFunctionalComponent, type Node } from "react";
import classNames from "classnames";
import styles from "./GroupedList.scss";
import RaisedSheet from "./RaisedSheet";

type GroupedListProps = {
  children: Node,
  description?: Node,
  title?: string,
  displayAsCard?: boolean
};

const { count, map } = React.Children;

const GroupedList: StatelessFunctionalComponent<GroupedListProps> = ({
  children,
  description,
  title,
  displayAsCard
}) => {
  const n = count(children) - 1;
  const titleClassName = classNames(styles.title, styles.item, styles.divide, {
    [styles.inset]: displayAsCard
  });
  const Component = displayAsCard ? RaisedSheet : "div";

  return (
    <div className={styles.root}>
      <Component>
        {title !== undefined && <div className={titleClassName}>{title}</div>}

        {map(children, (child, i) => {
          const className = classNames(styles.item, {
            [styles.divide]: i !== n,
            [styles.inset]: displayAsCard
          });

          return React.isValidElement(child) ? (
            <div key={i} className={className}>
              {child}
            </div>
          ) : null;
        })}
      </Component>

      {description !== undefined && (
        <div className={styles.description}>{description}</div>
      )}
    </div>
  );
};

export default GroupedList;
