/* @flow */

import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "../../Button";
import FilePicker from "../../FilePicker";
import Fieldset from "../../Fieldset";
import type {
  FileDescriptor,
  FileUploadStatus,
  Action,
  RootState,
  UIState
} from "../../../types";
import countryConfig from "../../../countryConfig";

type DocumentManagerProps = {
  documents: FileDescriptor[],
  onSuccess: (documents: FileDescriptor[]) => void,
  dispatch: Action => Action,
  ui: UIState,
  countryCode: string
};

type DocumentManagerState = {
  documents: FileDescriptor[]
};

export class DocumentManager extends Component<
  DocumentManagerProps,
  DocumentManagerState
> {
  constructor(props: DocumentManagerProps) {
    super(props);

    this.state = {
      documents: props.documents
    };
  }

  handleFileCollectionAdd = (newFile: FileDescriptor) => {
    this.setState({
      documents: this.state.documents.concat(newFile)
    });
  };

  handleFileCollectionRemove = (fileToRemove: FileDescriptor) => {
    this.setState({
      documents: this.state.documents.filter(({ id }) => id !== fileToRemove.id)
    });
  };

  handleFileUploadStatusChange = (status: FileUploadStatus) => {
    if (status === "uploading") {
      this.props.dispatch({ type: "UI_CONTACT_DOCUMENT_UPLOAD_START" });
    } else if (status === "complete") {
      this.props.dispatch({ type: "UI_ASYNC_ACTION_COMPLETE" });
    }
  };

  commitDocuments = () => {
    this.props.onSuccess(this.state.documents);
  };

  render() {
    return (
      <React.Fragment>
        <Fieldset
          title={
            countryConfig[this.props.countryCode].contacts.documentManager.title
          }
          description={
            countryConfig[this.props.countryCode].contacts.documentManager
              .description
          }>
          <FilePicker
            description="Adding documentation is optional at this time; however, we will request them later during the application review process. Adding documentation now can help speed up the application review process."
            files={this.state.documents}
            onFileUpload={this.handleFileCollectionAdd}
            onFileRemove={this.handleFileCollectionRemove}
            onFileUploadStatus={this.handleFileUploadStatusChange}
          />
        </Fieldset>

        <Button
          intent="primary"
          fullWidth={true}
          onClick={this.commitDocuments}
          disabled={this.props.ui.asyncActionInFlight}>
          Done
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ ui, countryCode }: RootState) => ({
  ui,
  countryCode
});

// $FlowFixMe
export default connect(mapStateToProps)(DocumentManager);
