/** @flow */

import { object, string } from "yup";
import { stringExists, poBox } from "./helpers";

export const addressFields = {
  street_address: string().when("$countryCode", {
    is: "USA",
    then: poBox,
    otherwise: stringExists
  }),
  extended_address: string(),
  locality: stringExists,
  postal_code: string().when("$countryCode", {
    is: "USA",
    then: stringExists.length(5, "Please enter a 5-digit postal code"),
    otherwise: stringExists
  })
};

export const addressFieldsForBusiness = object().shape({
  ...addressFields,
  region: string()
    .when("$countryCode", {
      is: "AUS",
      then: string()
        .ensure()
        .required("Please select an option from the list")
    })
    .when("$countryCode", {
      is: "NZL",
      then: stringExists
    })
    .when("$countryCode", {
      is: "MYS",
      then: stringExists
    })
    .when("$countryCode", {
      is: "SGP",
      then: stringExists
    })
    .when("$countryCode", {
      is: "HKG",
      then: stringExists
    })
    .when("$countryCode", {
      is: "USA",
      then: string().required("Please select an option from the list")
    })
    .when("$countryCode", {
      is: "BRA",
      then: stringExists
    })
});

export const addressFieldsForContact = object().shape({
  ...addressFields,
  region: string().when("$countryCode", {
    is: "USA",
    then: string().required("Please select an option from the list"),
    otherwise: stringExists
  }),
  country: string().when("$countryCode", {
    is: "USA",
    then: string()
      .ensure()
      .matches(/USA/, "Only US addresses are valid for contacts"),
    otherwise: stringExists
  })
});
