/* @flow */

import React, { PureComponent, Fragment } from "react";
import { ValidationError } from "yup";
import { merge, omit } from "lodash";
import { ContactPersonalInformationSchema } from "../../../validators/schemas/contact";
import type {
  FormBlurHandlerEventArg,
  FormChangeHandlerEventArg,
  Action,
  ContactPersonalInformation,
  Contact,
  UIState,
  ValidationErrors
} from "../../../types";
import TextField from "../../TextField";
import SelectField from "../../SelectField";
import Fieldset from "../../Fieldset";
import ContactPersonalInformationPopulator from "../../dev/ContactPersonalInformationPopulator";
import Grid from "../../Grid";
import { Half, Whole, Third, TwoThirds } from "../../GridItem";
import { ChoiceGroup } from "../../ChoiceGroup";
import { Choice } from "../../Choice";
import Button from "../../Button";
import { connect } from "react-redux";
import ContactPersonalInformationAddress from "./ContactPersonalInformationAddress";
import { unflattenKeys } from "../../../collection/index";
import countryConfig from "../../../countryConfig";

type ContactPersonalInformationFormProps = {
  ui: UIState,
  dispatch: Action => Action,
  contact: Contact,
  onSuccess: (fields: ContactPersonalInformation) => void,
  countryCode: string,
  countries: Array<string>,
  canRenderID: boolean,
  isAuthorizedSigner: boolean,
  initialIdNumbers: Array<string>
};

type ContactPersonalInformationFormState = {
  errors: ValidationErrors,
  values: ContactPersonalInformation
};

export class ContactPersonalInformationForm extends PureComponent<
  ContactPersonalInformationFormProps,
  ContactPersonalInformationFormState
> {
  constructor(props: ContactPersonalInformationFormProps) {
    super(props);

    this.state = {
      values: props.contact.personalInformation,
      errors: props.contact.serverSideValidationErrors || {
        address: {}
      },
      initialIdNumbers: props.initialIdNumbers
    };
  }

  handleComponentBlur = (updateAddressCollection: boolean = false) => (
    event: FormBlurHandlerEventArg
  ) => {
    const {
      countryCode,
      canRenderID: isIDRequired,
      initialIdNumbers
    } = this.props;
    const { name } = event.currentTarget;
    let error;

    try {
      ContactPersonalInformationSchema.validateSyncAt(
        updateAddressCollection ? `address.${name}` : name,
        this.state.values,
        {
          context: { countryCode, isIDRequired, initialIdNumbers }
        }
      );
    } catch (exception) {
      if (ValidationError.isError(exception)) {
        error = exception.message;
      } else {
        throw exception;
      }
    }

    this.setState(prevState => {
      let errors;

      if (error) {
        const newError = { [name]: error };
        errors = merge(
          {},
          prevState.errors,
          updateAddressCollection
            ? { address: merge(prevState.errors.address, newError) }
            : newError
        );
      } else {
        errors = omit(prevState.errors, [
          updateAddressCollection ? `address.${name}` : name
        ]);
      }

      return { errors };
    });
  };

  handleComponentChange = (updateAddressCollection: boolean = false) => (
    event: FormChangeHandlerEventArg
  ) => {
    const { name, value } = event.currentTarget;

    this.setState(prevState => {
      const newValue = { [name]: value };
      const values = merge(
        {},
        prevState.values,
        updateAddressCollection ? { address: newValue } : newValue
      );
      return { values };
    });

    if (!this.props.ui.contactEditInProgress) {
      this.props.dispatch({ type: "UI_CONTACT_EDIT_START" });
    }
  };

  handleFormKeydown = (
    event: SyntheticKeyboardEvent<
      HTMLInputElement | HTMLSelectElement | HTMLButtonElement
    >
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateAndSubmit();
    }
  };

  handleFormButtonClick = (
    event: SyntheticMouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    event.preventDefault();
    this.validateAndSubmit();
  };

  validateAndSubmit = (shouldSubmit: boolean = true) => {
    const {
      countryCode,
      canRenderID: isIDRequired,
      initialIdNumbers
    } = this.props;

    try {
      ContactPersonalInformationSchema.validateSync(this.state.values, {
        abortEarly: false,
        context: { countryCode, isIDRequired, initialIdNumbers }
      });
    } catch (exception) {
      if (ValidationError.isError(exception)) {
        this.setState({
          // $FlowFixMe: This can safely be ignored since we cannot type the `exception`
          errors: unflattenKeys(
            exception.inner.reduce(
              (accumulator, error) => {
                accumulator[error.params.path] = error.message;
                return accumulator;
              },
              { address: {} }
            )
          )
        });
        return;
      }
      throw exception;
    }
    if (shouldSubmit) {
      this.props.onSuccess(this.state.values);
    }
  };

  componentDidMount() {
    // validate existing contact
    const isPersistedContact =
      this.props.contact.id && this.props.contact.id.length > 10;
    if (isPersistedContact) {
      this.validateAndSubmit(false);
    }
  }

  render() {
    const { errors, values } = this.state;
    const {
      countryCode,
      countries,
      canRenderID,
      isAuthorizedSigner
    } = this.props;
    const fieldsetTitlePrefix = isAuthorizedSigner ? "Your " : "";

    return (
      <React.Fragment>
        <form
          onKeyDown={this.handleFormKeydown}
          data-test-id="ContactPersonalInformationForm">
          <Fieldset title={`${fieldsetTitlePrefix}Personal Information`}>
            <Grid>
              <Half>
                <TextField
                  errorText={errors.first_name}
                  labelText="First Name"
                  name="first_name"
                  onBlur={this.handleComponentBlur()}
                  onChange={this.handleComponentChange()}
                  placeholder="First Name"
                  value={values.first_name}
                />
              </Half>
              <Half>
                <TextField
                  errorText={errors.last_name}
                  labelText="Last Name"
                  name="last_name"
                  onBlur={this.handleComponentBlur()}
                  onChange={this.handleComponentChange()}
                  placeholder="Last Name"
                  value={values.last_name}
                />
              </Half>
              <Whole>
                <TextField
                  errorText={errors.job_title}
                  labelText="Position"
                  name="job_title"
                  onBlur={this.handleComponentBlur()}
                  onChange={this.handleComponentChange()}
                  placeholder="Job Title"
                  value={values.job_title}
                />
              </Whole>
              <Third>
                <TextField
                  errorText={errors.phone}
                  labelText="Personal Phone Number"
                  name="phone"
                  onBlur={this.handleComponentBlur()}
                  onChange={this.handleComponentChange()}
                  placeholder={
                    countryConfig[this.props.countryCode].phonePlaceholder
                  }
                  value={values.phone}
                />
              </Third>
              <Third>
                <TextField
                  errorText={errors.email}
                  labelText="Email Address"
                  name="email"
                  onBlur={this.handleComponentBlur()}
                  onChange={this.handleComponentChange()}
                  placeholder="Email Address"
                  value={values.email}
                />
              </Third>
              <Third>
                <TextField
                  errorText={errors.date_of_birth}
                  labelText="Date of Birth"
                  name="date_of_birth"
                  onBlur={this.handleComponentBlur()}
                  onChange={this.handleComponentChange()}
                  placeholder={
                    countryConfig[this.props.countryCode].datePlaceholder
                  }
                  value={values.date_of_birth}
                />
              </Third>
              {["MYS", "HKG", "SGP"].includes(countryCode) && (
                <Whole>
                  <SelectField
                    errorText={errors.id_type}
                    labelText="Id Type"
                    name="id_type"
                    onBlur={this.handleComponentBlur()}
                    onChange={this.handleComponentChange()}
                    options={[
                      { key: "Identity Card", text: "Identity Card" },
                      { key: "Passport", text: "Passport" }
                    ]}
                    value={values.id_type}
                  />
                </Whole>
              )}
              {canRenderID && (
                <Fragment>
                  <TwoThirds>
                    <SelectField
                      errorText={errors.id_type}
                      labelText="ID Type"
                      name="id_type"
                      onBlur={this.handleComponentBlur()}
                      onChange={this.handleComponentChange()}
                      options={[
                        {
                          key: "SOCIAL_SECURITY_NUMBER",
                          text: "Social Security Number (SSN)"
                        },
                        {
                          key: "ITIN",
                          text:
                            "Individual Taxpayer Identification Number (ITIN)"
                        }
                      ]}
                      value={values.id_type}
                      description="This information will help us verify the identity of this individual"
                    />
                  </TwoThirds>
                  <Third>
                    <TextField
                      errorText={errors.id_number}
                      labelText={countryConfig[countryCode].idNumberLabel}
                      name="id_number"
                      onBlur={this.handleComponentBlur()}
                      onChange={this.handleComponentChange()}
                      placeholder={
                        countryConfig[countryCode].idNumberPlaceholder
                      }
                      value={values.id_number}
                    />
                  </Third>
                </Fragment>
              )}
              <Whole>
                <ContactPersonalInformationAddress
                  values={this.state.values.address}
                  errors={this.state.errors.address || {}}
                  onBlur={this.handleComponentBlur(true)}
                  onChange={this.handleComponentChange(true)}
                  countryCode={countryCode}
                  countries={countries}
                />
              </Whole>

              {countryCode === "CAN" && (
                <Whole>
                  <ChoiceGroup
                    errorText={errors.ever_filed_for_bankruptcy}
                    label="Have You Ever Filed For Bankruptcy?"
                    name="ever_filed_for_bankruptcy"
                    onBlur={this.handleComponentBlur()}
                    onChange={this.handleComponentChange()}
                    value={values.ever_filed_for_bankruptcy}>
                    <Choice labelText="Yes" value="1" />
                    <Choice labelText="No" value="0" />
                  </ChoiceGroup>
                </Whole>
              )}
            </Grid>
          </Fieldset>
        </form>

        <Button
          intent="primary"
          fullWidth={true}
          submit={true}
          onClick={this.handleFormButtonClick}>
          Continue
        </Button>

        {["development", "qa", "qa2", "test"].includes(
          process.env.RAILS_ENV
        ) && (
          <ContactPersonalInformationPopulator
            countryCode={countryCode}
            onClick={values => {
              this.setState({ values });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { ui, business, countryCode, countries } = state;
  const isPublicCorporation =
    business.values.registered_as === "public_corporation";
  const canRenderID = countryConfig[countryCode].renderIdNumber;

  return {
    ui,
    countryCode,
    countries,
    canRenderID: canRenderID && !isPublicCorporation
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(ContactPersonalInformationForm);
