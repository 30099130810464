/* @flow */

import { readImageContents } from "../signups/src/io/file";
import { humanizeBytes } from "../signups/src/bytes";

export const readAndValidateLogo = (
  file: File,
  { requiredDimensions, maxSize, acceptType }
): Promise<any> =>
  new Promise(async (resolve, reject): void => {
    const { contents, width, height } = await readImageContents(file);
    const imageDimensions = `${width}X${height}`;
    if (!file.type.includes(acceptType.replace(/\./g, ""))) {
      reject(
        new Error(`(${imageDimensions}) - Image should be a ${acceptType} file`)
      );
    }
    if (
      typeof requiredDimensions === "string" &&
      requiredDimensions !== imageDimensions
    ) {
      reject(
        new Error(
          `(${imageDimensions}) - Image dimensions must be ${requiredDimensions}`
        )
      );
    }
    if (typeof maxSize === "string" && file.size > parseInt(maxSize, 10)) {
      reject(
        new Error(
          `${file.name} (${humanizeBytes(
            file.size
          )}) - Exceeds max file size of ${humanizeBytes(
            parseInt(maxSize, 10)
          )}`
        )
      );
    }
    resolve(contents);
  });
