/* @flow */

import React from "react";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import type { RouterHistory, Location, Match } from "react-router-dom";
import { connect } from "react-redux";
import {
  SUPPORTED_EU_COUNTRY_CODES,
  SUPPORTED_LOCAL_ACQUIRING_COUNTRY_CODES
} from "../../countryHelper";
import Business from "../../components/Business";
import PayWithVenmo from "../../components/PayWithVenmo";
import Processing from "../../components/Processing";
import PayPalDetails from "../../components/PayPalDetails";
import MidTypes from "../../components/MidTypes";
import Funding from "../../components/Funding";
import AdditionalFunding from "../../components/AdditionalFunding";
import type { Action, RootState } from "../../types";
import { Kount } from "../../Kount";
import Contacts from "../../components/Contacts";
import { serializeForm } from "../../serializers";
import { deserializeServerSideValidationErrors } from "../../serializers/deserializeServerSideValidationErrors";
import { getMetaNodeValue } from "../../dom";
import { captureException } from "@sentry/browser";
import Disclaimers from "../../components/Disclaimers";
import { schema } from "../../schema";
import Grid from "../../components/Grid";
import { Whole } from "../../components/GridItem";
import ErrorBlock from "../../components/ErrorBlock";
import HelpText from "../../components/HelpText";
import { find, get } from "lodash/fp";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../common";

type Config = {
  recaptchaSiteKey: string
};

type FormProps = {
  history: RouterHistory,
  location: Location,
  countryCode: string,
  dispatch: Action => Action,
  store: RootState,
  match: Match,
  config: Config
};

type FormState = {
  showModal: boolean,
  networkErrored: boolean,
  serverErrored: boolean,
  submitting: boolean,
  recaptchaToken: string | null
};

export class Form extends React.Component<FormProps, FormState> {
  state = {
    showModal: false,
    networkErrored: false,
    serverErrored: false,
    submitting: false,
    recaptchaToken: null
  };

  verifySubmission = (event: SyntheticEvent<>): void => {
    event.preventDefault();

    this.props.dispatch({ type: "LIFECYCLE_SUBMIT" });

    if (this.props.store.lifecycle.isSubmittable && this.isSchemaValid()) {
      this.setState({ showModal: true });
    }
  };

  submit = () => {
    this.setState({ submitting: true }, async () => {
      const urlParams = new URLSearchParams(
        window.location.search.substring(1)
      );
      const sendAdditionalFunding =
        this.props.store.countryCode === "CAN" &&
        ["USD", "BOTH"].includes(
          this.props.store.processing.values.processing_currencies
        );
      const config = {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "content-type": "application/json",
          "X-CSRF-Token": String(getMetaNodeValue("csrf-token")),
          Accept: "application/json"
        },
        body: JSON.stringify(
          serializeForm(
            this.props.store,
            {
              kount_id: Kount.deviceSessionId,
              partner_source: urlParams.get("partner_source")
            },
            this.props.store.countryCode,
            sendAdditionalFunding
          )
        )
      };

      let response;
      try {
        response = await fetch(
          `${window.location.origin}${window.location.pathname}`,
          config
        );
      } catch (error) {
        this.setState({ networkErrored: true, submitting: false });

        captureException(error);
      }

      if (response !== undefined) {
        const status = response.status;

        if (status === 200) {
          if (this.props.store.countryCode === "USA") {
            const { location_id, authorized_signer_id } = await response.json();
            this.props.history.push(`${this.props.match.url}/kiq`, {
              authorizedSigner: get(
                "personalInformation",
                find(get("authorizedSigner"), this.props.store.contacts.values)
              ),
              locationId: location_id,
              authorizedSignerId: authorized_signer_id
            });
          } else {
            this.props.history.push("/thanks", {
              email: this.props.store.contacts.values[0].personalInformation
                .email
            });
          }
        } else if (status === 422) {
          const payload = await response.json();

          this.setState(
            { serverErrored: true, showModal: false, submitting: false },
            () => {
              this.props.dispatch({
                type: "SERVER_SIDE_VALIDATION_ERROR",
                payload: deserializeServerSideValidationErrors(payload)
              });
            }
          );
        } else {
          this.setState({
            serverErrored: false,
            networkErrored: true,
            submitting: false
          });
        }
      }
    });
  };

  hideModal = (): void => {
    this.setState({ showModal: false });
  };

  handleModalExited = () => {
    if (this.state.networkErrored) {
      this.setState({ networkErrored: false });
    }
  };

  isSchemaValid(): boolean {
    const {
      business,
      disclaimers,
      funding,
      processing,
      paypalDetails,
      midTypes,
      additionalFunding,
      countryCode,
      venmoOnly
    } = this.props.store;
    return schema.isValidSync(
      {
        business: {
          ...business.values,
          address: business.address.values
        },
        disclaimers: disclaimers.values,
        funding: {
          ...funding.values,
          address: { ...funding.address.values },
          documents: [...funding.documents]
        },
        processing: {
          ...processing.values,
          subscription_percentages: processing.subscriptionPercentages
        },
        paypalDetails: paypalDetails.values,
        midTypes: midTypes.values,
        additionalFunding: {
          ...additionalFunding.values,
          address: { ...additionalFunding.address.values }
        }
      },
      {
        context: { countryCode, venmoOnly }
      }
    );
  }

  render() {
    const { countryCode } = this.props.store;
    const { sharedError } = this.props.store.lifecycle;
    const {
      config: { recaptchaSiteKey = RECAPTCHA_SITE_KEY }
    } = this.props;
    const {
      showModal,
      submitting,
      serverErrored,
      networkErrored,
      recaptchaToken
    } = this.state;
    const canSubmit =
      !submitting &&
      !this.props.store.ui.asyncActionInFlight &&
      this.isSchemaValid();
    const documentsAreRequired =
      countryCode === "AUS" ||
      countryCode === "NZL" ||
      countryCode === "CAN" ||
      (["USA", ...SUPPORTED_EU_COUNTRY_CODES].includes(countryCode) &&
        Number(this.props.store.processing.values.average_transaction_amount) >=
          1500);
    const isInternalLocalAcquiring = SUPPORTED_LOCAL_ACQUIRING_COUNTRY_CODES.includes(
      countryCode
    );
    const processingCurrencies = this.props.store.processing.values
      .processing_currencies;
    const showAdditionalFundingFirst =
      countryCode === "CAN" && processingCurrencies === "USD";
    const showAdditionalFundingLast =
      countryCode === "CAN" && processingCurrencies === "BOTH";
    const formConfig = document.querySelector("#formConfig") || {};
    const { features = {} } = JSON.parse(formConfig.textContent || "{}");

    // Devnote: Checking now for QA to avoid showing ReCaptcha below
    // since our ReCaptcha sitekey for QA isn't working. We need to migrate
    // to internal Google Cloud, but this is short term fix now to avoid
    // being blocked during some testing.
    const isQA = ["qa", "qa2"].includes(process.env.RAILS_ENV);

    return (
      <form onSubmit={this.verifySubmission}>
        <Modal
          isOpen={showModal}
          onDismiss={this.hideModal}
          onDismissed={this.handleModalExited}
          allowDismissal={!submitting}
          testId="Modal">
          {networkErrored ? (
            <div>
              <h2>Oops, there was an error.</h2>
              <p>
                Refresh your browser or click the "cancel" button to try
                submitting again.
              </p>
              <Button
                intent="secondary"
                onClick={() => {
                  location.href = location.href;
                }}>
                Refresh the browser
              </Button>
            </div>
          ) : (
            <div>
              <h2>Are you sure?</h2>
              <p>
                Please take a moment to review the information you entered.
                After&nbsp;submitting your application, it will not be editable.
              </p>
              <p>
                {!isQA && (
                  <ReCAPTCHA
                    sitekey={recaptchaSiteKey}
                    onChange={token => this.setState({ recaptchaToken: token })}
                  />
                )}
              </p>
              <Button
                intent="primary"
                onClick={this.submit}
                disabled={!isQA && !recaptchaToken}
                loading={submitting}>
                Yes, Submit for Review
              </Button>
            </div>
          )}
        </Modal>

        <Business />
        <Contacts />
        <Processing isInternalLocalAcquiring={isInternalLocalAcquiring} />
        {features.payWithVenmo && <PayWithVenmo />}
        {!isInternalLocalAcquiring && showAdditionalFundingFirst && (
          <AdditionalFunding
            documentsAreRequired={documentsAreRequired}
            processingCurrencies={processingCurrencies}
          />
        )}
        {!isInternalLocalAcquiring && (
          <Funding
            documentsAreRequired={documentsAreRequired}
            processingCurrencies={processingCurrencies}
          />
        )}
        {!isInternalLocalAcquiring && showAdditionalFundingLast && (
          <AdditionalFunding
            documentsAreRequired={documentsAreRequired}
            processingCurrencies={processingCurrencies}
          />
        )}
        {isInternalLocalAcquiring && <MidTypes />}
        {isInternalLocalAcquiring && <PayPalDetails />}
        <Disclaimers />

        <Grid>
          {serverErrored && (
            <Whole>
              <ErrorBlock message="Sorry, an error exists in the data provided on this application. Please correct the error and try again." />
            </Whole>
          )}
          {sharedError && (
            <Whole>
              <ErrorBlock message={sharedError} />
            </Whole>
          )}
          <Whole>
            <Button
              intent="primary"
              fullWidth={true}
              submit={true}
              disabled={!canSubmit}>
              Submit Application
            </Button>
          </Whole>

          {!canSubmit && (
            <Whole>
              <HelpText alignTextCenter={true}>
                Your application is incomplete. Please fill out all fields
                before submitting.
              </HelpText>
            </Whole>
          )}
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (store: RootState) => ({ store });

// $FlowFixMe
export default connect(mapStateToProps)(Form);
