/** @flow */

import { number, string, type StringSchema } from "yup";

export const REQUIRED_FIELD_MESSAGE = "Please fill out this field";

export const stringExists: StringSchema = string()
  .trim()
  .ensure()
  .required(REQUIRED_FIELD_MESSAGE);

export const booleanString = stringExists.oneOf(["0", "1"]);

export const numericString = number().transform((value, originalValue) =>
  originalValue === "" ? undefined : value
);

export const poBox = stringExists.matches(
  /(^\s*(?!(#\d+)|((box|bin)[-. /\\]?\d+)|(^(.* )?p[ .]? ?(o|0)[-. /\\]? *-?((((box|bin)|b[.]?)( *#?\d+)?$)|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+))(?!^.*\s(st|dr|ln|ave)[.]?\s(box)\s[#]?(\d+))/i,
  "Address cannot contain PO Box"
);

export const USPhoneString = stringExists.matches(
  /^((\+)?\d{1})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s-.]?\d{4}$/,
  "Please enter a valid US phone number"
);
