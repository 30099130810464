/* @flow */

import React, { type Node } from "react";
import Fieldset from "./Fieldset";
import Grid from "./Grid";
import { Checkbox } from "./Checkbox";
import { Whole, Third } from "./GridItem";
import { ChoiceGroup } from "./ChoiceGroup";
import { Choice } from "./Choice";
import { connect } from "react-redux";
import styles from "./MidTypes.scss";
import ErrorBlock from "./ErrorBlock";
import type {
  MidTypesValues,
  ValidationErrors,
  Action,
  RootState
} from "../types";

type MidTypesProps = {
  dispatch: Action => Action,
  values: MidTypesValues,
  errors: ValidationErrors,
  countryCode: string,
  errorMessage?: string
};

export const MidTypes = (props: MidTypesProps): Node => {
  const { dispatch, errors, values, errorMessage } = props;
  const handleFieldBlur = (
    event: SyntheticFocusEvent<HTMLInputElement>
  ): void => {
    const { name } = event.currentTarget;
    dispatch({ type: "MID_TYPES_FIELD_BLUR", payload: { name } });
  };

  const handleFieldChange = (
    event: SyntheticInputEvent<HTMLInputElement>
  ): void => {
    const { name } = event.currentTarget;
    dispatch({
      type: "MID_TYPES_FIELD_CHANGE",
      payload: {
        name,
        value: !values[name]
      }
    });
  };

  const handleChoiceChange = (
    event: SyntheticInputEvent<HTMLInputElement>
  ): void => {
    const { name, value } = event.currentTarget;
    dispatch({
      type: "MID_TYPES_FIELD_CHANGE",
      payload: {
        name,
        value
      }
    });
  };

  return (
    <div className={styles.root}>
      <Fieldset
        title="Local Acquiring MID Details"
        description={`Please provide the below information to determine the type of MIDs to be requested for merchant.`}>
        {errorMessage !== undefined && <ErrorBlock message={errorMessage} />}
        <p>MID type</p>
        <Grid>
          <Third>
            <Checkbox
              name="credit_mid"
              description="Credit MID"
              checked={values.credit_mid}
              onBlur={handleFieldBlur}
              onChange={handleFieldChange}
            />
          </Third>
          <Third>
            <Checkbox
              name="debit_mid"
              description="Debit MID"
              checked={values.debit_mid}
              onBlur={handleFieldBlur}
              onChange={handleFieldChange}
            />
          </Third>
          <Third>
            <Checkbox
              name="add_card_mid"
              description="Add Card MID"
              checked={values.add_card_mid}
              onBlur={handleFieldBlur}
              onChange={handleFieldChange}
            />
          </Third>
        </Grid>
        <Grid>
          <Whole>
            <div className={styles.midTypes}>
              <ChoiceGroup
                errorText={errors.credit_settlement_type}
                label="Settlement type for Credit"
                name="credit_settlement_type"
                onBlur={handleFieldBlur}
                onChange={handleChoiceChange}
                value={values.credit_settlement_type}>
                <Choice labelText="D+2" value="D+2" />
                <Choice labelText="D+15" value="D+15" />
                <Choice labelText="D+30" value="D+30" />
              </ChoiceGroup>
            </div>
          </Whole>
        </Grid>
        <p>Trusted</p>
        <Grid>
          <Third>
            <Checkbox
              name="trusted_credit_mid"
              description="Trusted Credit"
              checked={values.trusted_credit_mid}
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
            />
          </Third>
          <Third>
            <Checkbox
              name="trusted_debit_mid"
              description="Trusted Debit"
              checked={values.trusted_debit_mid}
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
            />
          </Third>
          <Third>
            <Checkbox
              name="trusted_add_card_mid"
              description="Trusted Add Card"
              checked={values.trusted_add_card_mid}
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
            />
          </Third>
        </Grid>
      </Fieldset>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { midTypes, countryCode } = state;
  const { errors } = midTypes;
  const errorMessage = errors.credit_mid;
  return {
    ...midTypes,
    errorMessage,
    countryCode
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(MidTypes);
