/* @flow */

import React, { type StatelessFunctionalComponent, type Node } from "react";
import classNames from "classnames";
import styles from "./Marquee.scss";

type MarqueeProps = {
  title: string | Node,
  subTitle?: string | Node,
  description: string | Node,
  darkStyle?: boolean
};

const Marquee: StatelessFunctionalComponent<MarqueeProps> = ({
  title,
  subTitle,
  description,
  darkStyle
}) => {
  const rootClassName = classNames(styles.root, {
    [styles.dark]: darkStyle
  });

  return (
    <div className={rootClassName}>
      <div className={styles.content}>
        <h1 className={styles.heading}>{title}</h1>
        {subTitle ? <h2 className={styles.description}>{subTitle}</h2> : null}
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

export default Marquee;
