/* @flow */

import React, { PureComponent } from "react";
import SliderGroup from "./SliderGroup";
import type { SubscriptionPercentagesType } from "../types";

type SubscriptionPercentagesProps = {
  subscriptionPercentages: {
    +value: SubscriptionPercentagesType,
    +error?: string
  },
  onChange: (payload: SubscriptionPercentagesType) => void,
  onBlur: () => void
};

class SubscriptionPercentages extends PureComponent<SubscriptionPercentagesProps> {
  // eslint-disable-next-line no-unused-vars
  handleChangeEvent = (
    _: SyntheticInputEvent<>,
    name: string,
    value: number
  ) => {
    this.props.onChange({
      ...this.props.subscriptionPercentages.value,
      [name]: value
    });
  };

  render() {
    const {
      subscriptionPercentages: {
        value: { monthly, annually, quarterly, semi_annually },
        error
      },
      onBlur
    } = this.props;
    return (
      <SliderGroup
        title="Your Subscription Breakdown"
        displayAsCard={true}
        options={[
          {
            key: "monthly",
            text: "Monthly",
            value: monthly
          },
          {
            key: "quarterly",
            text: "Quarterly",
            value: quarterly
          },
          {
            key: "semi_annually",
            text: "Semi-Annually",
            value: semi_annually
          },
          {
            key: "annually",
            text: "Annually",
            value: annually
          }
        ]}
        errorMessage={error}
        onBlur={onBlur}
        onChange={this.handleChangeEvent}
      />
    );
  }
}

export default SubscriptionPercentages;
