/* @flow */

import React, { type Node } from "react";
import Button from "../../Button";

type IdentityVerificationProps = {
  gotoNextStep: () => void
};

const IdentityVerification = (props: IdentityVerificationProps): Node => {
  const { gotoNextStep } = props;
  return (
    <div>
      <h2>Identity Verification</h2>
      <p>
        Immediately preceding the submission of this application, we may ask a
        series of knowledge-based question about your personal history or ask
        for documents that verify your identity.
      </p>
      <p>
        The security of our merchants is very important to us. We do this to
        protect yourself and your business against fraud.
      </p>
      <Button intent="primary" fullWidth={true} onClick={gotoNextStep}>
        Done
      </Button>
    </div>
  );
};

export default IdentityVerification;
