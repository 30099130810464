/* @flow */

import React, { type Node } from "react";
import TextField from "./TextField";
import Fieldset from "./Fieldset";
import Grid from "./Grid";
import { Whole } from "./GridItem";
import { connect } from "react-redux";
import type {
  PayPalDetailsValues,
  ValidationErrors,
  Action,
  RootState
} from "../types";

type PayPalDetailsProps = {
  dispatch: Action => Action,
  values: PayPalDetailsValues,
  errors: ValidationErrors,
  countryCode: string
};

export const PayPalDetails = (props: PayPalDetailsProps): Node => {
  const { dispatch, errors, values } = props;

  const handleFieldBlur = (
    event: SyntheticFocusEvent<HTMLInputElement>
  ): void => {
    const { name } = event.currentTarget;
    dispatch({ type: "PAYPAL_DETAILS_FIELD_BLUR", payload: { name } });
  };

  const handleFieldChange = (
    event: SyntheticInputEvent<HTMLInputElement>
  ): void => {
    const { name, value } = event.currentTarget;
    dispatch({
      type: "PAYPAL_DETAILS_FIELD_CHANGE",
      payload: {
        name,
        value
      }
    });
  };

  return (
    <Fieldset
      title="Additional PayPal Details"
      description={`Please provide the PayPal Admin Id associated with the location.`}>
      <Grid>
        <Whole>
          <TextField
            errorText={errors.paypal_payer_id}
            name="paypal_payer_id"
            onBlur={handleFieldBlur}
            onChange={handleFieldChange}
            type="text"
            value={values.paypal_payer_id}
            labelText="PP Business Payout Account ID"
            placeholder="A00AA0OAAA0AA"
          />
        </Whole>
      </Grid>
    </Fieldset>
  );
};

const mapStateToProps = (state: RootState) => ({ ...state.paypalDetails });

// $FlowFixMe
export default connect(mapStateToProps)(PayPalDetails);
