/* @flow */

import { AUS_STATES, USA_STATES, CANADA_PROVINCES } from "./fields/regions";
import { SUPPORTED_EU_COUNTRIES_METADATA } from "./countryHelper";

const euCountryToIdLabels = {
  AND: {
    legalUrl: "https://www.braintreepayments.com/ad/legal"
  },
  AUT: {
    legalUrl: "https://www.braintreepayments.com/at/legal"
  },
  BEL: {
    legalUrl: "https://www.braintreepayments.com/be/legal"
  },
  BGR: {
    currencySymbol: "лв",
    recommendedProcessingVolume: "60000 лв.",
    legalUrl: "https://www.braintreepayments.com/bg/legal"
  },
  CHE: {
    currencySymbol: "CHF",
    recommendedProcessingVolume: "CHF 60’000",
    legalUrl: "https://www.braintreepayments.com/ch/legal"
  },
  CYP: {
    legalUrl: "https://www.braintreepayments.com/cy/legal"
  },
  CZE: {
    currencySymbol: "Kč",
    recommendedProcessingVolume: "60 000 Kč",
    legalUrl: "https://www.braintreepayments.com/cz/legal"
  },
  DEU: {
    legalUrl: "https://www.braintreepayments.com/de/legal"
  },
  DNK: {
    currencySymbol: "kr",
    recommendedProcessingVolume: "60.000 kr.",
    idNumberLabel: "Central Person Register (CPR)",
    idNumberPlaceholder: "999999-9999",
    legalUrl: "https://www.braintreepayments.com/dk/legal"
  },
  ESP: {
    legalUrl: "https://www.braintreepayments.com/es/legal"
  },
  EST: {
    legalUrl: "https://www.braintreepayments.com/ee/legal"
  },
  FIN: {
    legalUrl: "https://www.braintreepayments.com/fi/legal"
  },
  FRA: {
    legalUrl: "https://www.braintreepayments.com/fr/legal"
  },
  GRC: {
    legalUrl: "https://www.braintreepayments.com/gr/legal"
  },
  GGY: {
    currencySymbol: "£",
    recommendedProcessingVolume: "£60,000",
    legalUrl: "https://www.braintreepayments.com/gg/legal"
  },
  GBR: {
    currencySymbol: "£",
    recommendedProcessingVolume: "£60,000",
    legalUrl: "https://www.braintreepayments.com/gb/legal"
  },
  HUN: {
    currencySymbol: "Ft",
    recommendedProcessingVolume: "60 000 Ft",
    legalUrl: "https://www.braintreepayments.com/hu/legal"
  },
  HRV: {
    currencySymbol: "kn",
    recommendedProcessingVolume: "60.000 kn",
    legalUrl: "https://www.braintreepayments.com/hr/legal"
  },
  ISL: {
    currencySymbol: "kr",
    recommendedProcessingVolume: "60,000 kr",
    legalUrl: "https://www.braintreepayments.com/is/legal"
  },
  IRL: {
    legalUrl: "https://www.braintreepayments.com/ie/legal"
  },
  IMN: {
    currencySymbol: "£",
    recommendedProcessingVolume: "£60,000",
    legalUrl: "https://www.braintreepayments.com/im/legal"
  },
  ITA: {
    idNumberLabel: "Codice Fiscale",
    idNumberPlaceholder: "AAA AAA 99A99 ****A",
    legalUrl: "https://www.braintreepayments.com/it/legal"
  },
  JEY: {
    currencySymbol: "£",
    recommendedProcessingVolume: "£60,000",
    legalUrl: "https://www.braintreepayments.com/je/legal"
  },
  LVA: {
    legalUrl: "https://www.braintreepayments.com/lv/legal"
  },
  LIE: {
    currencySymbol: "CHF",
    recommendedProcessingVolume: "60’000",
    legalUrl: "https://www.braintreepayments.com/li/legal"
  },
  LTU: {
    legalUrl: "https://www.braintreepayments.com/lt/legal"
  },
  LUX: {
    legalUrl: "https://www.braintreepayments.com/lu/legal"
  },
  MLT: {
    legalUrl: "https://www.braintreepayments.com/mt/legal"
  },
  MCO: {
    legalUrl: "https://www.braintreepayments.com/dc/legal"
  },
  NLD: {
    idNumberLabel: "BSN",
    idNumberPlaceholder: "99999999(?9)", // NLD can have 8-9 digits
    legalUrl: "https://www.braintreepayments.com/nl/legal"
  },
  NOR: {
    currencySymbol: "kr",
    recommendedProcessingVolume: "60,000 kr",
    idNumberLabel: "Birth Number (optional)",
    idNumberPlaceholder: undefined,
    legalUrl: "https://www.braintreepayments.com/nz/legal"
  },
  POL: {
    currencySymbol: "zł",
    recommendedProcessingVolume: "60 000 zł",
    legalUrl: "https://www.braintreepayments.com/pl/legal"
  },
  PRT: {
    idNumberLabel: "NIF (optional)",
    idNumberPlaceholder: undefined,
    legalUrl: "https://www.braintreepayments.com/pt/legal"
  },
  SMR: {
    legalUrl: "https://www.braintreepayments.com/sm/legal"
  },
  SVK: {
    idNumberLabel: "Birth Number (optional)",
    idNumberPlaceholder: undefined,
    legalUrl: "https://www.braintreepayments.com/sk/legal"
  },
  SVN: {
    legalUrl: "https://www.braintreepayments.com/si/legal"
  },
  SWE: {
    currencySymbol: "kr",
    recommendedProcessingVolume: "60 000 kr",
    idNumberLabel: "Swedish Personal Identification Number",
    idNumberPlaceholder: "AAA AAA 99A99 ****A",
    legalUrl: "https://www.braintreepayments.com/se/legal"
  }
};
const euCountryConfigs = SUPPORTED_EU_COUNTRIES_METADATA.reduce(
  (euConfig, { name, countryCode, currencyISOCode }) => {
    const defaultsForCountry = euCountryToIdLabels[countryCode] || {};
    euConfig[countryCode] = {
      name,
      currencyISOCode,
      isEU: true,
      currencySymbol: defaultsForCountry.currencySymbol || "€",
      recommendedProcessingVolume:
        defaultsForCountry.recommendedProcessingVolume || "60.000 €",
      fillBicAndIbanFields: true,
      businessTaxIdLabel: "Registration Number",
      businessTaxIdFieldName: "registration_number",
      localityLabel: "City/Town",
      regionLabel: "Province",
      datePlaceholder: "YYYY-MM-DD",
      phonePlaceholder: "+44 1234567890",
      fulfillmentCompletedInLabel: "When are products delivered?",
      workflow: "eu",
      legalUrl:
        defaultsForCountry.legalUrl ||
        "https://www.braintreepayments.com/legal",
      maxContacts: 6,
      contacts: {
        beneficialOwnerSection: {
          title: "Beneficial Owners",
          addButtonText: "Add Owner"
        },
        documentManager: {
          title: "Identity Verification",
          description:
            "To verify your identity, we require a copy of any valid government-issued national ID or passport. If you are a Sole Proprietor, we will also need proof of residential address, such as a utility bill dated within the past 12 months."
        }
      },
      accountNumberLabel: "IBAN",
      accountNumberDescription:
        "Your IBAN (International Bank Account Number) is used by banks in European countries for international fund transfers.",
      accountNumberPlaceholder: "",
      routingNumberLabel: "BIC",
      routingNumberDescription:
        "A BIC (Bank Identifier Code) is a unique identifier for a bank.",
      routingNumberPlaceholder: "",
      idNumberLabel: defaultsForCountry.idNumberLabel || "ID Num",
      idNumberPlaceholder:
        defaultsForCountry.idNumberPlaceholder || "I are placeholder",
      regions: [],
      renderRegionDropDown: false
    };
    return euConfig;
  },
  {}
);

const apacCountryConfigs = {
  AUS: {
    datePlaceholder: "MM/DD/YYYY",
    businessTaxIdLabel: "ABN or ACN",
    businessAddressStreetLabel:
      "Must be the address that your business registered with ASIC. We will not accept a PO Box.",
    currencyISOCode: "AUD",
    currencySymbol: "$",
    recommendedProcessingVolume: "$60,000 AUD",
    fulfillmentCompletedInLabel: "When are products delivered?",
    localityLabel: "Suburb",
    regionLabel: "State",
    maxContacts: 5,
    name: "Australia",
    phonePlaceholder: "00 0000 0000",
    postalCodePlaceholder: "0000",
    documentDescription:
      "We require at least one recent banking statement from the last 6 months. These documents help verify that the bank account provided is valid, active and associated with your business. It also confirms that we route settlements to the correct account. Accepted file formats include PDF, JPG, or PNG. File size is limited to 10 MB.",
    renderRefundPolicy: false,
    renderShipPhysicalGoods: false,
    renderIdNumber: false,
    workflow: "apac",
    contacts: {
      documentManager: {
        title: "Identity Verification (Optional)",
        description:
          "To verify your identity, we request a copy of any valid government-issued photo identification."
      }
    },
    fillBicAndIbanFields: false,
    accountNumberLabel: "Account Number",
    routingNumberLabel: "BSB Number",
    routingNumberPlaceholder: "00000",
    legalUrl: "https://www.braintreepayments.com/au/legal",
    regions: AUS_STATES,
    renderRegionDropDown: true
  },
  NZL: {
    businessTaxIdLabel: "NZBN",
    currencyISOCode: "NZD",
    currencySymbol: "$",
    recommendedProcessingVolume: "$60,000 NZD",
    fulfillmentCompletedInLabel: "When are products delivered?",
    localityLabel: "Suburb",
    regionLabel: "City/Town",
    maxContacts: 5,
    name: "New Zealand",
    phonePlaceholder: "00 0000 0000",
    postalCodePlaceholder: "0000",
    renderRefundPolicy: false,
    renderShipPhysicalGoods: false,
    renderIdNumber: false,
    workflow: "apac",
    contacts: {
      beneficialOwnerSection: {
        title: "Beneficial Owners",
        addButtonText: "Add Owner"
      },
      documentManager: {
        title: "Identity Verification",
        description:
          "To verify this individual's identity, we require a valid copy of a passport and proof of residential address, such as a utility bill dated within the past 12 months. If a passport is unavailable, we will accept a valid New Zealand Driver's License accompanied by another item issued by a New Zealand Government agency that contains the individual's name and signature. A notary or other certified signatory must certify all requested documentation."
      }
    },
    fillBicAndIbanFields: false,
    accountNumberLabel: "Individual Account Number",
    accountNumberDescription: "Last 9-12 digits of your full account number",
    accountNumberPlaceholder: "000000000000",
    routingNumberLabel: "Bank Code",
    routingNumberDescription: "First 6 digits of your full account number",
    routingNumberPlaceholder: "000000",
    legalUrl: "https://www.braintreepayments.com/nz/legal",
    regions: [],
    renderRegionDropDown: false
  },
  MYS: {
    businessTaxIdLabel: "Business registration number",
    currencyISOCode: "MYR",
    currencySymbol: "RMR",
    recommendedProcessingVolume: "RMR245,000 MYR",
    fulfillmentCompletedInLabel: "When are products delivered?",
    localityLabel: "Suburb",
    regionLabel: "Region",
    maxContacts: 5,
    name: "Malaysia",
    phonePlaceholder: "00 0000 0000",
    postalCodePlaceholder: "0000",
    renderRefundPolicy: false,
    renderShipPhysicalGoods: false,
    renderIdNumber: false,
    workflow: "apac",
    contacts: {
      documentManager: {
        title: "Identity Verification",
        description:
          "To verify this individual's identity, we require a valid copy of a passport and proof of residential address, such as a utility bill dated within the past 12 months. If a passport is unavailable, we will accept a valid Malaysia Driver's License accompanied by another item issued by a Malaysia Government agency that contains the individual's name and signature. A notary or other certified signatory must certify all requested documentation."
      }
    },
    fillBicAndIbanFields: false,
    accountNumberLabel: "Individual Account Number",
    accountNumberDescription: "Last 9-12 digits of your full account number",
    accountNumberPlaceholder: "000000000000",
    routingNumberLabel: "BIC (SWIFT) code",
    routingNumberDescription: "First 6 digits of your full account number",
    routingNumberPlaceholder: "000000",
    legalUrl: "https://www.braintreepayments.com/my/legal",
    regions: [],
    renderRegionDropDown: false
  },
  HKG: {
    businessTaxIdLabel: "Business registration number",
    currencyISOCode: "HKD",
    currencySymbol: "$",
    recommendedProcessingVolume: "$470,000 HKD",
    fulfillmentCompletedInLabel: "When are products delivered?",
    localityLabel: "Suburb",
    regionLabel: "District",
    maxContacts: 5,
    name: "Hong Kong",
    phonePlaceholder: "00 0000 0000",
    postalCodePlaceholder: "0000",
    renderRefundPolicy: false,
    renderShipPhysicalGoods: false,
    renderIdNumber: false,
    workflow: "apac",
    contacts: {
      documentManager: {
        title: "Identity Verification",
        description:
          "To verify this individual's identity, we require a valid copy of a passport and proof of residential address, such as a utility bill dated within the past 12 months. If a passport is unavailable, we will accept a valid Hong Kong Driver's License accompanied by another item issued by a Hong Kong Government agency that contains the individual's name and signature. A notary or other certified signatory must certify all requested documentation."
      }
    },
    fillBicAndIbanFields: false,
    accountNumberLabel: "Individual Account Number",
    accountNumberDescription: "Last 9-12 digits of your full account number",
    accountNumberPlaceholder: "000000000000",
    routingNumberLabel: "BIC (SWIFT) code",
    routingNumberDescription: "First 6 digits of your full account number",
    routingNumberPlaceholder: "000000",
    legalUrl: "https://www.braintreepayments.com/hk/legal",
    regions: [],
    renderRegionDropDown: false
  },
  SGP: {
    businessTaxIdLabel: "Business registration number",
    currencyISOCode: "SGD",
    currencySymbol: "$",
    recommendedProcessingVolume: "$80,000 SGD",
    fulfillmentCompletedInLabel: "When are products delivered?",
    localityLabel: "Suburb",
    regionLabel: "Region",
    maxContacts: 5,
    name: "Singapore",
    phonePlaceholder: "00 0000 0000",
    postalCodePlaceholder: "0000",
    renderRefundPolicy: false,
    renderShipPhysicalGoods: false,
    renderIdNumber: false,
    workflow: "apac",
    contacts: {
      documentManager: {
        title: "Identity Verification",
        description:
          "To verify this individual's identity, we require a valid copy of a passport and proof of residential address, such as a utility bill dated within the past 12 months. If a passport is unavailable, we will accept a valid Singapore Driver's License accompanied by another item issued by a Singapore Government agency that contains the individual's name and signature. A notary or other certified signatory must certify all requested documentation."
      }
    },
    fillBicAndIbanFields: false,
    accountNumberLabel: "Individual Account Number",
    accountNumberDescription: "Last 9-12 digits of your full account number",
    accountNumberPlaceholder: "000000000000",
    routingNumberLabel: "BIC (SWIFT) code",
    routingNumberDescription: "First 6 digits of your full account number",
    routingNumberPlaceholder: "000000",
    legalUrl: "https://www.braintreepayments.com/sg/legal",
    regions: [],
    renderRegionDropDown: false
  }
};
const northAmericaCountryConfigs = {
  USA: {
    datePlaceholder: "MM/DD/YYYY",
    businessTaxIdLabel: "Tax ID",
    currencyISOCode: "USD",
    currencySymbol: "$",
    recommendedProcessingVolume: "$60,000 USD",
    fulfillmentCompletedInLabel: "One-Time Purchase Delivery",
    localityLabel: "City",
    regionLabel: "State",
    maxContacts: 6,
    name: "United States",
    phonePlaceholder: "(000) 000-0000",
    postalCodePlaceholder: undefined,
    renderRefundPolicy: true,
    renderShipPhysicalGoods: true,
    renderIdNumber: true,
    idNumberLabel: "ID Number",
    idNumberPlaceholder: "123-45-6789",
    workflow: "us_direct",
    contacts: {
      beneficialOwnerSection: {
        title: "Beneficial Owners and Business Managers",
        addButtonText: "Add Owner or Manager"
      },
      documentManager: {
        title: "Identity Verification",
        description:
          "To verify your identity, we require a copy of any valid government-issued photo identification and proof of residential address, such as a utility bill dated within the past 12 months."
      }
    },
    fillBicAndIbanFields: false,
    accountNumberLabel: "Account Number",
    routingNumberLabel: "Routing Number",
    routingNumberPlaceholder: "00000",
    legalUrl: "https://www.braintreepayments.com/legal",
    regions: USA_STATES,
    renderRegionDropDown: true
  },
  CAN: {
    datePlaceholder: "MM/DD/YYYY",
    businessTaxIdLabel: "Provincial Business Registration Number",
    currencyISOCode: "CAD",
    currencySymbol: "$",
    recommendedProcessingVolume: "$60,000 CAD",
    fulfillmentCompletedInLabel: "When are products delivered?",
    name: "Canada",
    localityLabel: "City",
    maxContacts: 6,
    regionLabel: "Province",
    phonePlaceholder: "(000) 000-0000",
    postalCodePlaceholder: undefined,
    renderRefundPolicy: true,
    renderShipPhysicalGoods: true,
    renderIdNumber: false,
    idNumberLabel: "Social Security Number",
    idNumberPlaceholder: "123-45-6789",
    contacts: {
      beneficialOwnerSection: {
        title: "Beneficial Owners and Business Managers",
        addButtonText: "Add Owner or Manager"
      },
      documentManager: {
        title: "Identity Verification",
        description:
          "To verify your identity, we require a copy of drivers license (front only) or first 2 pages of passport"
      }
    },
    fillBicAndIbanFields: false,
    accountNumberLabel: "Account Number",
    accountNumberPlaceholder: "000000000000",
    accountNumberDescription: "",
    routingNumberLabel: "Routing Number",
    routingNumberPlaceholder: "00000",
    routingNumberDescription: "",
    renderRegionDropDown: true,
    legalUrl: "https://www.braintreepayments.com/ca/legal",
    regions: CANADA_PROVINCES,
    workflow: "canada"
  }
};

const localAcquiringCountryConfigs = {
  BRA: {
    datePlaceholder: "MM/DD/YYYY",
    businessTaxIdLabel: "Tax ID",
    currencyISOCode: "BRL",
    currencySymbol: "R$",
    recommendedProcessingVolume: "0 BRL",
    fulfillmentCompletedInLabel: "One-Time Purchase Delivery",
    localityLabel: "City",
    regionLabel: "State",
    maxContacts: 6,
    name: "Brazil",
    phonePlaceholder: "(000) 000-0000",
    postalCodePlaceholder: undefined,
    renderRefundPolicy: false,
    renderShipPhysicalGoods: false,
    renderIdNumber: false,
    workflow: "local_acquiring",
    contacts: {
      beneficialOwnerSection: {
        title: "Beneficial Owners and Business Managers",
        addButtonText: "Add Owner or Manager"
      },
      documentManager: {
        title: "Identity Verification",
        description:
          "To verify your identity, we require a copy of any valid government-issued photo identification and proof of residential address, such as a utility bill dated within the past 12 months."
      }
    },
    fillBicAndIbanFields: false,
    accountNumberLabel: "Account Number",
    routingNumberLabel: "Routing Number",
    routingNumberPlaceholder: "00000",
    legalUrl: "https://www.braintreepayments.com/legal",
    regions: [],
    renderRegionDropDown: false
  }
};
const countryConfig = {
  ...euCountryConfigs,
  ...apacCountryConfigs,
  ...northAmericaCountryConfigs,
  ...localAcquiringCountryConfigs
};

export default countryConfig;
