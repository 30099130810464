/* @flow */

import { omit } from "lodash";
import { ValidationError } from "yup";
import type { Action, ProcessingState } from "../types";
import processingValidationSchema from "../validators/schemas/processing";

export const initialState = {
  errors: {},
  values: {
    annual_volume_amount: undefined,
    average_transaction_amount: undefined,
    currently_processing_credit_cards: "0",
    customer_charged_on: "",
    existing_merchant: "0",
    fulfillment_completed_in: "",
    maximum_transaction_amount: undefined,
    offers_subscriptions: "0",
    refund_policy: "",
    ship_physical_goods: "0",
    ships_own_products: "0",
    in_person: "0",
    customer_statement_descriptor: "",
    customer_statement_phone_number: "",
    processing_currencies: "",
    accept_amex: "",
    merchant_public_id: ""
  },
  subscriptionPercentages: {
    value: {
      monthly: 0,
      quarterly: 0,
      semi_annually: 0,
      annually: 0
    }
  },
  documents: []
};

export const valuesForValidation = (state: ProcessingState) => ({
  ...state.values,
  subscription_percentages: state.subscriptionPercentages.value
});

export const validate = (countryCode: string) => (
  name: string,
  state: ProcessingState
): string | void => {
  try {
    processingValidationSchema.validateSyncAt(
      name,
      valuesForValidation(state),
      { context: { countryCode } }
    );
  } catch (error) {
    if (ValidationError.isError(error)) {
      return error.message;
    }
    throw error;
  }
  return undefined;
};

export const processingReducerSwitch = (
  state: ProcessingState,
  action: Action,
  countryCode: string
): ProcessingState => {
  switch (action.type) {
    case "PROCESSING_FIELD_CHANGE":
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value
        }
      };
    case "PROCESSING_FIELD_BLUR": {
      const fieldValidationError = validate(countryCode)(
        action.payload.name,
        state
      );
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: fieldValidationError
        }
      };
    }
    case "PROCESSING_SUBSCRIPTION_PERCENTAGES_CHANGE":
      return {
        ...state,
        subscriptionPercentages: {
          ...state.subscriptionPercentages,
          value: action.payload
        }
      };
    case "PROCESSING_SUBSCRIPTION_PERCENTAGES_BLUR": {
      const fieldValidationError = validate(countryCode)(
        "subscription_percentages",
        state
      );
      return {
        ...state,
        subscriptionPercentages: {
          ...state.subscriptionPercentages,
          error: fieldValidationError
        }
      };
    }
    case "LIFECYCLE_SUBMIT":
      try {
        processingValidationSchema.validateSync(valuesForValidation(state), {
          abortEarly: false,
          context: { countryCode }
        });
      } catch (error) {
        if (ValidationError.isError(error)) {
          const {
            subscription_percentages: subscriptionPercentagesError,
            ...errors
          } = error.inner.reduce((accumulator, error) => {
            accumulator[error.params.path] = error.message;
            return accumulator;
          }, {});

          return {
            ...state,
            errors,
            subscriptionPercentages: {
              ...state.subscriptionPercentages,
              error: subscriptionPercentagesError
            }
          };
        }
        throw error;
      }
      return { ...state, errors: {} };
    case "PROCESSING_DOCUMENT_ADD":
      return {
        ...state,
        documents: state.documents.concat(action.payload)
      };
    case "PROCESSING_DOCUMENT_REMOVE":
      return {
        ...state,
        documents: state.documents.filter(doc => doc.id !== action.payload.id)
      };
    case "PROCESSING_REMOVE_ALL_DOCUMENTS":
      return {
        ...state,
        documents: []
      };
    case "SERVER_SIDE_VALIDATION_ERROR": {
      const processing = action.payload.processing || {};

      return {
        ...state,
        errors: omit(processing, "subscription_percentages"),
        subscriptionPercentages: {
          ...state.subscriptionPercentages,
          error: processing.subscription_percentages
        }
      };
    }
    default:
      return state;
  }
};

const processingReducer = (countryCode: string) => (
  state: ProcessingState = initialState,
  action: Action
): ProcessingState => processingReducerSwitch(state, action, countryCode);

export default processingReducer;
