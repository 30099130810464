/* @flow */

import { omit } from "lodash";
import { ValidationError } from "yup";
import type { BusinessState, Action } from "../types";
import businessValidationSchema from "../validators/schemas/business";
import { unflattenKeys } from "../collection";

export const initialState = {
  values: {
    dba_name: "",
    registered_as: "",
    industry: "",
    established_on: "",
    legal_name: "",
    tax_id: "",
    state_incorporated: "",
    phone: "",
    website: "",
    description: "",
    business_location_type: "",
    staging_website_url: "",
    staging_login: "",
    staging_password: "",
    email: "",
    logo_image: [],
    logo_image_id: "",
    pay_with_venmo: "0"
  },
  errors: {},
  address: {
    values: {
      street_address: "",
      extended_address: "",
      locality: "",
      region: "",
      postal_code: ""
    },
    errors: {}
  }
};

export const validate = (countryCode: string, venmoOnly: boolean) => (
  name: string,
  state: BusinessState
): string | void => {
  try {
    businessValidationSchema.validateSyncAt(
      name,
      {
        ...state.values,
        address: { ...state.address.values }
      },
      {
        context: { countryCode, venmoOnly }
      }
    );
  } catch (error) {
    if (ValidationError.isError(error)) {
      return error.message;
    }

    throw error;
  }
  return undefined;
};

export const businessReducerSwitch = (
  state: BusinessState,
  action: Action,
  countryCode: string,
  venmoOnly: boolean
): BusinessState => {
  switch (action.type) {
    case "BUSINESS_FIELD_CHANGE":
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value
        }
      };
    case "BUSINESS_ADDRESS_FIELD_CHANGE":
      return {
        ...state,
        address: {
          ...state.address,
          values: {
            ...state.address.values,
            [action.payload.name]: action.payload.value
          }
        }
      };
    case "BUSINESS_FIELD_BLUR": {
      const fieldValidationError = validate(countryCode, venmoOnly)(
        action.payload.name,
        state
      );
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: fieldValidationError
        }
      };
    }
    case "BUSINESS_ADDRESS_FIELD_BLUR": {
      const fieldValidationError = validate(countryCode, venmoOnly)(
        `address.${action.payload.name}`,
        state
      );
      return {
        ...state,
        address: {
          ...state.address,
          errors: {
            ...state.address.errors,
            [action.payload.name]: fieldValidationError
          }
        }
      };
    }
    case "BUSINESS_LOGO_ADD":
      return {
        ...state,
        values: {
          ...state.values,
          logo_image: [action.payload],
          logo_image_id: action.payload.id
        }
      };
    case "BUSINESS_LOGO_REMOVE":
      return {
        ...state,
        values: {
          ...state.values,
          logo_image: [],
          logo_image_id: ""
        }
      };
    case "LIFECYCLE_SUBMIT":
      try {
        businessValidationSchema.validateSync(
          { ...state.values, address: state.address.values },
          {
            abortEarly: false,
            context: {
              countryCode,
              venmoOnly
            }
          }
        );
      } catch (error) {
        if (ValidationError.isError(error)) {
          const errorMessages = error.inner.reduce((accumulator, error) => {
            accumulator[error.params.path] = error.message;
            return accumulator;
          }, {});

          // $FlowFixMe: This can safely be ignored since we cannot type the `exception`
          const { address: addressErrors, ...errors } = unflattenKeys(
            errorMessages
          );

          return {
            ...state,
            errors: {
              ...errors
            },
            address: {
              ...state.address,
              errors: {
                ...addressErrors
              }
            }
          };
        }

        throw error;
      }
      return { ...state, errors: {} };
    case "SERVER_SIDE_VALIDATION_ERROR": {
      const business = action.payload.business || {};

      return {
        ...state,
        errors: omit(business, "address"),
        address: {
          ...state.address,
          errors: { ...business.address }
        }
      };
    }
    case "REMOVE_FORM_VALUE":
      return {
        ...state,
        values: omit(state.values, action.payload),
        errors: omit(state.errors, action.payload)
      };
    default:
      return state;
  }
};

const businessReducer = (countryCode: string, venmoOnly: boolean) => (
  state: BusinessState = initialState,
  action: Action
): BusinessState =>
  businessReducerSwitch(state, action, countryCode, venmoOnly);

export default businessReducer;
