/* @flow */

import React, { type Element, type ElementType } from "react";

type FocusProps = {
  children: Element<ElementType>,
  focus?: boolean
};

class Focus extends React.PureComponent<FocusProps> {
  child: ?Node;

  componentDidMount() {
    if (this.props.focus && this.child instanceof HTMLElement) {
      this.child.focus();
    }
  }

  componentDidUpdate(prevProps: FocusProps) {
    if (
      !prevProps.focus &&
      this.props.focus &&
      this.child instanceof HTMLElement
    ) {
      this.child.focus();
    }
  }

  render() {
    return (
      <span
        ref={node => {
          if (node != null) {
            this.child = node.firstChild;
          }
        }}>
        {this.props.children}
      </span>
    );
  }
}

export default Focus;
