/* @flow */

import React, { Fragment, PureComponent } from "react";
import Fieldset from "../Fieldset";
import AddButton from "../AddButton";
import GroupedList from "../GroupedList";
import Modal from "../Modal";
import { Portal } from "../Portal";
import AddEditContactFlow from "./AddEditContactFlow";
import { ResolvedContact } from "./ResolvedContact";
import { connect } from "react-redux";
import type { RootState, Contact, Action, UIState } from "../../types";
import countryConfig from "../../countryConfig";
import ErrorBlock from "../ErrorBlock";
import HelpText from "../HelpText";

type ContactsState = {
  modalIsOpen: boolean,
  contactIdForEdit: string | null
};

type ContactsProps = {
  errorText?: string,
  values: Contact[],
  submitWasAttempted: boolean,
  dispatch: Action => Action,
  ui: UIState,
  countryCode: string,
  isBeneficialOwnersRequired: boolean,
  initialIdNumbers: Array<string>,
  isPublicCorporation: boolean
};

export class Contacts extends PureComponent<ContactsProps, ContactsState> {
  state = {
    modalIsOpen: false,
    contactIdForEdit: null
  };

  handleModalDismiss = () => {
    let shouldDismiss = true;

    if (this.props.ui.contactEditInProgress) {
      // eslint-disable-next-line no-alert
      shouldDismiss = confirm("Unsaved changes. Are you sure?");
    }

    if (shouldDismiss) {
      this.setState({
        modalIsOpen: false
      });
    }
  };

  handleModalDismissed = () => {
    this.props.dispatch({ type: "UI_CONTACT_EDIT_COMPLETE" });
  };

  getAuthorizedSigner = () =>
    this.props.values.find(contact => contact.authorizedSigner);

  handleContactAddClick = () => {
    this.setState({
      modalIsOpen: true,
      contactIdForEdit: null
    });
  };

  handleContactEditClick = (id: string) => {
    this.setState({
      modalIsOpen: true,
      contactIdForEdit: id
    });
  };

  handleContactDeleteClick = (id: string) => {
    this.handleContactDelete(id);
  };

  handleContactAdd = (contact: Contact) => {
    this.setState({
      modalIsOpen: false
    });
    this.props.dispatch({
      type: "CONTACTS_ADD",
      payload: contact
    });
  };

  handleContactUpdate = (contact: Contact) => {
    this.setState({
      modalIsOpen: false
    });
    this.props.dispatch({
      type: "CONTACTS_UPDATE",
      payload: contact
    });
  };

  handleContactDelete = (id: string) => {
    this.props.dispatch({
      type: "CONTACTS_DELETE",
      payload: { id }
    });
  };

  renderAuthorizedSigner() {
    const authorizedSigner = this.getAuthorizedSigner();

    return authorizedSigner ? (
      <GroupedList title="Authorized Representative (You)" displayAsCard={true}>
        <ResolvedContact
          contact={authorizedSigner}
          countryCode={this.props.countryCode}
          initialIdNumbers={this.props.initialIdNumbers}
          onEditClick={this.handleContactEditClick}
          isPublicCorporation={this.props.isPublicCorporation}
        />
      </GroupedList>
    ) : (
      <GroupedList displayAsCard={true}>
        <AddButton
          title="Start by adding your information..."
          onClick={this.handleContactAddClick}
        />
      </GroupedList>
    );
  }

  renderBeneficialOwners(isBeneficialOwnersRequired: boolean) {
    const { countryCode } = this.props;

    let title = "Additional Representatives";
    let buttonText = "Add Representative";
    let description;

    if (isBeneficialOwnersRequired) {
      title = countryConfig[countryCode].contacts.beneficialOwnerSection.title;
      buttonText =
        countryConfig[countryCode].contacts.beneficialOwnerSection
          .addButtonText;
      description =
        "Do not include yourself in this section if you have already filled out your information as the Authorized Representative above.";
    }

    return this.getAuthorizedSigner() ? (
      <GroupedList title={title} description={description} displayAsCard={true}>
        {this.props.values
          .filter(contact => !contact.authorizedSigner && !contact._destroy)
          .map(contact => (
            <ResolvedContact
              key={contact.id}
              contact={contact}
              countryCode={this.props.countryCode}
              initialIdNumbers={this.props.initialIdNumbers}
              onEditClick={this.handleContactEditClick}
              onDeleteClick={this.handleContactDeleteClick}
              isPublicCorporation={this.props.isPublicCorporation}
            />
          ))}
        {this.props.values.length <
        countryConfig[this.props.countryCode].maxContacts ? (
          <AddButton title={buttonText} onClick={this.handleContactAddClick} />
        ) : null}
      </GroupedList>
    ) : null;
  }

  renderError() {
    const { errorText, submitWasAttempted } = this.props;
    const shouldRenderError = errorText !== undefined && submitWasAttempted;
    return shouldRenderError ? (
      <ErrorBlock message={errorText !== undefined ? errorText : ""} />
    ) : null;
  }

  render() {
    const { countryCode, values, isBeneficialOwnersRequired } = this.props;
    const seedContact =
      values.find(contact => contact.id === this.state.contactIdForEdit) ||
      null;

    let sectionTitle = "Business Representatives";
    let sectionDescription =
      "The information you provide is used to verify your identity as the authorized representative allowed to make decisions on behalf of the business and helps us understand the management structure of the business.";

    if (isBeneficialOwnersRequired) {
      sectionTitle = "Beneficial Owners and Business Representatives";
      sectionDescription =
        "Tell us about yourself and all individuals who own, directly or indirectly, 25% or more of the business. This information is used to verify your identity as the authorized representative allowed to make decisions on behalf of the business and helps us understand the management structure of the business.";
    }

    return (
      <Fragment>
        <Fieldset
          title={sectionTitle}
          description={
            <div>
              <p>{sectionDescription}</p>

              {this.renderError()}
            </div>
          }>
          {this.renderAuthorizedSigner()}
          {this.renderBeneficialOwners(isBeneficialOwnersRequired)}

          {isBeneficialOwnersRequired && (
            <Fragment>
              {countryCode === "NZL" && (
                <HelpText summary="In compliance with anti-money laundering laws in New Zealand, on behalf of our partner bank, you must provide personal information about those individuals who: (i) act on behalf of the business (ii) exercise effective control of the business and (iii) own, directly or indirectly, more than 25% of the equity of the business. The information provided for each individual who falls into one of the above categories will be used to verify the identity of each individual and help us understand the management structure of the business...">
                  <p>
                    In compliance with anti-money laundering laws in New
                    Zealand, on behalf of our partner bank, you must provide
                    personal information about those individuals who: (i) act on
                    behalf of the business (ii) exercise effective control of
                    the business and (iii) own, directly or indirectly, more
                    than 25% of the equity of the business. The information
                    provided for each individual who falls into one of the above
                    categories will be used to verify the identity of each
                    individual and help us understand the management structure
                    of the business.
                  </p>
                  <p>
                    The laws of New Zealand require our bank partner to obtain,
                    verify and record information about the beneficial owner(s)
                    and representative(s) of business customers. This is
                    required under sections 11-26 of the Anti-Money Laundering
                    and Countering Financing Terrorism Act 2009.
                  </p>
                  <p>
                    You are required to provide the name, position, phone
                    number, email address, date of birth and mailing address for
                    each of the following individuals:
                  </p>
                  <ol>
                    <li>
                      <strong>Persons acting on behalf of the business:</strong>{" "}
                      Those individuals who has authority to act on the
                      business’s behalf, including issuing instructions,
                      transferring funds, etc.;
                    </li>
                    <li>
                      <strong>Effective control:</strong> Those individuals who
                      (i) has the ability to control the business and/or dismiss
                      or appoint those in senior management position; (ii) hold
                      senior management positions (for example, the Chief
                      Executive Officer); and (iii) trustees and/or those with
                      trustee powers (where applicable); and
                    </li>
                    <li>
                      <strong>Beneficial owner:</strong> Those individuals who
                      holds more than 25% (being 25% and 1 share) or more equity
                      of the business, at an aggregated equity level.
                    </li>
                  </ol>
                  <p>
                    The number of individuals that fall into the above
                    categories for each business may vary. It is possible that
                    in some circumstances the same individual might be
                    identified as falling under more than one of the above
                    categories.
                  </p>
                </HelpText>
              )}

              {countryCode === "USA" && (
                <HelpText summary="To help the government fight financial crime, Federal regulation requires us, on behalf of our bank partner, to obtain, verify and record information about the key individuals who own or control legal entity customers (i.e. the “beneficial owners”). Requiring the disclosure of the beneficial owners of a legal entity helps law enforcement investigate and prosecute terrorist financing, money laundering, tax evasion, corruption, fraud, and other financial crimes...">
                  <p>
                    To help the government fight financial crime, Federal
                    regulation requires us, on behalf of our bank partner, to
                    obtain, verify and record information about the key
                    individuals who own or control legal entity customers (i.e.
                    the “beneficial owners”). Requiring the disclosure of the
                    beneficial owners of a legal entity helps law enforcement
                    investigate and prosecute terrorist financing, money
                    laundering, tax evasion, corruption, fraud, and other
                    financial crimes.
                  </p>
                  <p>
                    This beneficial owner information must be provided, and
                    certified as complete and correct, by the person applying
                    for a new account on behalf of a legal entity. A “legal
                    entity” includes a corporation, limited liability company,
                    or other entity that is created by a filing of a public
                    document with a Secretary of State or similar office, a
                    general partnership, and any similar business entity formed
                    in the United States.
                  </p>
                  <p>
                    You are required to provide the name, address, date of birth
                    and social security number (or passport number or similar
                    information, in the case of non-U.S. persons) for the
                    following individuals. We may also require you to provide us
                    with a copy of a driver’s license or other valid identifying
                    document for each beneficial owner listed on this
                    application.
                  </p>
                  <ul>
                    <li>
                      (i) Each individual who owns, directly or indirectly,
                      through any contract, arrangement, understanding,
                      relationship or otherwise, 25% or more of the equity
                      interests of the legal entity listed on the application
                      (e.g., each natural person that own 25% or more of the
                      shares of a corporation); and
                    </li>
                    <li>
                      (ii) An individual with significant responsibility for
                      managing the legal entity listed on the application (e.g.
                      a Chief Executive Officer, Chief Financial Officer, Chief
                      Operating Officer, Managing Member, General Partner,
                      President, Vice President, or Treasurer).
                    </li>
                  </ul>
                  <p>
                    The number of individuals that satisfy this definition of
                    “beneficial owner” may vary. Under section (i) above,
                    depending on the circumstances, up to four individuals (but
                    as few as zero) may need to be identified. Regardless of the
                    number of individuals identified under section (i), you must
                    provide the identifying information of one individual under
                    section (ii) above. It is possible that in some
                    circumstances the same individual might be identified under
                    both sections.
                  </p>
                </HelpText>
              )}
            </Fragment>
          )}
        </Fieldset>

        <Portal>
          <Modal
            allowDismissal={!this.props.ui.asyncActionInFlight}
            isOpen={this.state.modalIsOpen}
            onDismiss={this.handleModalDismiss}
            onDismissed={this.handleModalDismissed}
            testId="beneficial-owners-modal">
            <AddEditContactFlow
              isAuthorizedSigner={
                (seedContact && seedContact.authorizedSigner) ||
                values.length === 0
              }
              commitContact={
                seedContact ? this.handleContactUpdate : this.handleContactAdd
              }
              seedContact={seedContact}
              initialIdNumbers={this.props.initialIdNumbers}
            />
          </Modal>
        </Portal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { ui, business, countryCode } = state;
  const isPublicCorporation =
    business.values.registered_as === "public_corporation";

  return {
    ...state.contacts,
    countryCode: state.countryCode,
    submitWasAttempted: state.lifecycle.submitWasAttempted,
    ui,
    isBeneficialOwnersRequired:
      countryCode !== "AUS" &&
      countryCode !== "HKG" &&
      countryCode !== "SGP" &&
      countryCode !== "MYS" &&
      !isPublicCorporation,
    isPublicCorporation
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(Contacts);
