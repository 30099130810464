/* @flow */

import React, { type Node } from "react";
import { AlertIcon } from "./Icons";
import styles from "./ErrorBlock.scss";

type ErrorBlockProps = {
  message: Node
};

const ErrorBlock = ({ message }: ErrorBlockProps) => (
  <div className={styles.root}>
    <div className={styles.icon}>
      <AlertIcon />
    </div>

    <div className={styles.message}>{message}</div>
  </div>
);

export default ErrorBlock;
