/* @flow */

import React, { type Node, useState } from "react";
import TextField from "./TextField";
import SelectField from "./SelectField";
import Fieldset from "./Fieldset";
import Link from "./Link";
import Grid from "./Grid";
import { Half, Whole } from "./GridItem";
import { ChoiceGroup } from "./ChoiceGroup";
import { Choice } from "./Choice";
import type {
  FileDescriptor,
  FileUploadStatus,
  FormChangeHandlerEventArg,
  SubscriptionPercentagesType,
  Action,
  ValidationErrors,
  ProcessingSubscriptionPercentagesState,
  ProcessingValues,
  UIState
} from "../types";
import { connect } from "react-redux";
import SubscriptionPercentages from "./SubscriptionPercentages";
import FilePicker from "./FilePicker";
import classNames from "classnames";
import styles from "./Processing.scss";
import countryConfig from "../countryConfig";
import { SUPPORTED_EU_COUNTRY_CODES } from "../countryHelper";
import GroupedList from "./GroupedList";
import { Component } from "./Component";

type ProcessingProps = {
  dispatch: Action => Action,
  processing: {
    values: ProcessingValues,
    errors: ValidationErrors,
    subscriptionPercentages: ProcessingSubscriptionPercentagesState,
    documents: FileDescriptor[]
  },
  ui: UIState,
  countryCode: string,
  isInternalLocalAcquiring: boolean,
  venmoOnly: Boolean
};

export const Processing = (props: ProcessingProps): Node => {
  const {
    countryCode,
    dispatch,
    processing,
    ui,
    isInternalLocalAcquiring,
    venmoOnly
  } = props;

  const {
    errors,
    values,
    subscriptionPercentages,
    preferred_merchant_account_id
  } = processing;

  const inputState = preferred_merchant_account_id ? "1" : "0";
  const [hasPreferredMerchantId, setHasPreferredMerchantId] = useState(
    inputState
  );

  const conditionalDocumentGroupClassName = classNames(styles.documentGroup, {
    [styles.lockDuringUpload]: ui.asyncActionInFlight
  });

  const handleFileCollectionAdd = (newFile: FileDescriptor) => {
    dispatch({
      type: "PROCESSING_DOCUMENT_ADD",
      payload: newFile
    });
  };

  const handleFileCollectionRemove = (fileToRemove: FileDescriptor) => {
    dispatch({
      type: "PROCESSING_DOCUMENT_REMOVE",
      payload: fileToRemove
    });
  };

  const handleFileUploadStatusChange = (status: FileUploadStatus) => {
    if (status === "uploading") {
      dispatch({ type: "UI_ASYNC_ACTION_START" });
    } else if (status === "complete") {
      dispatch({ type: "UI_ASYNC_ACTION_COMPLETE" });
    }
  };

  const handleBlur = (name: string) => () => {
    dispatch({
      type: "PROCESSING_FIELD_BLUR",
      payload: { name }
    });
  };

  const handleChange = (name: string) => (event: FormChangeHandlerEventArg) => {
    const { value } = event.currentTarget;
    dispatch({
      type: "PROCESSING_FIELD_CHANGE",
      payload: { name, value }
    });
  };

  const handleSubscriptionPercentageBlur = () => {
    dispatch({
      type: "PROCESSING_SUBSCRIPTION_PERCENTAGES_BLUR"
    });
  };

  const handleSubscriptionPercentageChange = (
    payload: SubscriptionPercentagesType
  ) => {
    dispatch({
      type: "PROCESSING_SUBSCRIPTION_PERCENTAGES_CHANGE",
      payload
    });
  };

  const handleHasPreferredMerchantIdChange = event => {
    const value = event.currentTarget.value;
    setHasPreferredMerchantId(value);
  };

  const renderBRProcessingFields = () => {
    return (
      <Component>
        <Half>
          <ChoiceGroup
            errorText={errors.existing_merchant}
            label="Are you an existing Braintree merchant?"
            name="existing_merchant"
            onBlur={handleBlur("existing_merchant")}
            onChange={handleChange("existing_merchant")}
            value={values.existing_merchant}>
            <Choice labelText="Yes" value="1" />
            <Choice labelText="No" value="0" />
          </ChoiceGroup>
        </Half>
        <Half>
          <TextField
            errorText={errors.merchant_public_id}
            name="merchant_public_id"
            onBlur={handleBlur("merchant_public_id")}
            onChange={handleChange("merchant_public_id")}
            type="text"
            value={values.merchant_public_id}
            labelText="Merchant Public ID"
          />
        </Half>
      </Component>
    );
  };
  const renderProcessingFields = () => {
    return (
      <React.Fragment>
        <GroupedList displayAsCard={true}>
          <Grid>
            {countryCode === "CAN" && (
              <Component>
                <Half>
                  <TextField
                    errorText={errors.customer_statement_descriptor}
                    labelText="Customer Statement Descriptor"
                    name="customer_statement_descriptor"
                    onBlur={handleBlur("customer_statement_descriptor")}
                    onChange={handleChange("customer_statement_descriptor")}
                    value={values.customer_statement_descriptor}
                    description="How your business appears on a customers' statement"
                  />
                </Half>

                <Half>
                  <TextField
                    errorText={errors.customer_statement_phone_number}
                    labelText="Customer Statement Phone Number"
                    name="customer_statement_phone_number"
                    onBlur={handleBlur("customer_statement_phone_number")}
                    onChange={handleChange("customer_statement_phone_number")}
                    value={values.customer_statement_phone_number}
                    description="This will appear on your customers' statements"
                    placeholder={
                      countryConfig[props.countryCode].phonePlaceholder
                    }
                  />
                </Half>
              </Component>
            )}

            <Whole>
              <TextField
                errorText={errors.annual_volume_amount}
                labelText="Projected Annual Volume"
                name="annual_volume_amount"
                onBlur={handleBlur("annual_volume_amount")}
                onChange={handleChange("annual_volume_amount")}
                placeholder={countryConfig[countryCode].currencySymbol}
                type="number"
                value={values.annual_volume_amount}
                description={`For start-ups with no processing history, we recommend entering ${countryConfig[countryCode].recommendedProcessingVolume} or less unless you have some special circumstances around your launch. Otherwise, use your current processing statements as a guide.`}
              />
            </Whole>
            <Half>
              <TextField
                errorText={errors.average_transaction_amount}
                labelText="Average Transaction"
                name="average_transaction_amount"
                onBlur={handleBlur("average_transaction_amount")}
                onChange={handleChange("average_transaction_amount")}
                placeholder={countryConfig[countryCode].currencySymbol}
                type="number"
                value={values.average_transaction_amount}
              />
            </Half>
            <Half>
              <TextField
                errorText={errors.maximum_transaction_amount}
                labelText="Largest Transaction"
                name="maximum_transaction_amount"
                onBlur={handleBlur("maximum_transaction_amount")}
                onChange={handleChange("maximum_transaction_amount")}
                placeholder={countryConfig[countryCode].currencySymbol}
                type="number"
                value={values.maximum_transaction_amount}
              />
            </Half>
            <Half>
              <SelectField
                errorText={errors.customer_charged_on}
                labelText="When do you charge?"
                name="customer_charged_on"
                onBlur={handleBlur("customer_charged_on")}
                onChange={handleChange("customer_charged_on")}
                options={[
                  { key: "order", text: "Date of Order" },
                  {
                    key: "service_provided",
                    text: "When Services Are Provided"
                  },
                  { key: "delivery", text: "Date of Delivery" }
                ]}
                value={values.customer_charged_on}
              />
            </Half>
            <Half>
              <SelectField
                errorText={errors.fulfillment_completed_in}
                labelText={
                  countryConfig[countryCode].fulfillmentCompletedInLabel
                }
                name="fulfillment_completed_in"
                onBlur={handleBlur("fulfillment_completed_in")}
                onChange={handleChange("fulfillment_completed_in")}
                options={[
                  { key: "7", text: "Within 7 Days" },
                  { key: "14", text: "7-14 Days" },
                  { key: "30", text: "15-30 Days" },
                  { key: "60", text: "31-60 Days" },
                  { key: "90", text: "Later Than 60 Days" }
                ]}
                value={values.fulfillment_completed_in}
              />
            </Half>
            {countryConfig[countryCode].renderRefundPolicy && (
              <Whole>
                <SelectField
                  errorText={errors.refund_policy}
                  labelText="Refund Policy"
                  name="refund_policy"
                  onBlur={handleBlur("refund_policy")}
                  onChange={handleChange("refund_policy")}
                  options={[
                    {
                      text: "Exchange Only",
                      key: "exchange_only"
                    },
                    {
                      text: "Refund Cardholder",
                      key: "refund_cardholder"
                    },
                    {
                      text: "No Refund Or Exchange",
                      key: "no_refund_or_exchange"
                    }
                  ]}
                  value={values.refund_policy}
                />
              </Whole>
            )}
          </Grid>

          <Grid>
            <Half>
              <ChoiceGroup
                errorText={errors.currently_processing_credit_cards}
                label="Have you processed cards online before?"
                name="currently_processing_credit_cards"
                onBlur={handleBlur("currently_processing_credit_cards")}
                onChange={handleChange("currently_processing_credit_cards")}
                value={values.currently_processing_credit_cards}>
                <Choice labelText="Yes" value="1" />
                <Choice labelText="No" value="0" />
              </ChoiceGroup>
            </Half>
            <Half>
              <ChoiceGroup
                errorText={errors.ships_own_products}
                label="Do you ship your own products?"
                name="ships_own_products"
                onBlur={handleBlur("ships_own_products")}
                onChange={handleChange("ships_own_products")}
                value={values.ships_own_products}>
                <Choice labelText="Yes" value="1" />
                <Choice labelText="No" value="0" />
              </ChoiceGroup>
            </Half>
            {countryConfig[countryCode].renderShipPhysicalGoods && (
              <Half>
                <ChoiceGroup
                  errorText={errors.ship_physical_goods}
                  label="Do you sell physical products?"
                  name="ship_physical_goods"
                  onBlur={handleBlur("ship_physical_goods")}
                  onChange={handleChange("ship_physical_goods")}
                  value={values.ship_physical_goods}>
                  <Choice labelText="Yes" value="1" />
                  <Choice labelText="No" value="0" />
                </ChoiceGroup>
              </Half>
            )}
            <Half>
              <ChoiceGroup
                errorText={errors.in_person}
                label="Are you processing Paypal Braintree In-Person payments?"
                name="in_person"
                onBlur={handleBlur("in_person")}
                onChange={handleChange("in_person")}
                value={values.in_person}
                description="Select Yes, only if you have confirmed this with your Sales representative.">
                <Choice labelText="Yes" value="1" />
                <Choice labelText="No" value="0" />
              </ChoiceGroup>
            </Half>
            {countryCode === "CAN" && (
              <Component>
                <Half>
                  <ChoiceGroup
                    errorText={errors.processing_currencies}
                    label="What Currencies Do You Want To Accept?"
                    name="processing_currencies"
                    onBlur={handleBlur("processing_currencies")}
                    onChange={handleChange("processing_currencies")}
                    value={values.processing_currencies}>
                    <Choice labelText="CAD" value="CAD" />
                    <Choice labelText="USD" value="USD" />
                    <Choice labelText="Both" value="BOTH" />
                  </ChoiceGroup>
                </Half>
                <Half>
                  <ChoiceGroup
                    errorText={errors.accept_amex}
                    label="Would You Like To Accept AMEX?"
                    name="accept_amex"
                    onBlur={handleBlur("accept_amex")}
                    onChange={handleChange("accept_amex")}
                    value={values.accept_amex}>
                    <Choice labelText="Yes" value="1" />
                    <Choice labelText="No" value="0" />
                  </ChoiceGroup>
                </Half>
              </Component>
            )}
          </Grid>
        </GroupedList>

        {["USA", ...SUPPORTED_EU_COUNTRY_CODES].includes(countryCode) &&
          processing.values.currently_processing_credit_cards === "1" &&
          Number(processing.values.annual_volume_amount) >= 500000 && (
            <Component
              willUnmount={() => {
                dispatch({ type: "PROCESSING_REMOVE_ALL_DOCUMENTS" });
              }}>
              <FilePicker
                title="Recent Processing Statements"
                description="We need these documents because it helps us understand your business and projected processing needs based on your current processing volume. Adding processing statements is optional at this time, however we will request them during the application review process."
                files={processing.documents}
                displayAsCard={true}
                onFileUpload={handleFileCollectionAdd}
                onFileRemove={handleFileCollectionRemove}
                onFileUploadStatus={handleFileUploadStatusChange}
              />
            </Component>
          )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className={conditionalDocumentGroupClassName}>
        <Fieldset
          title={venmoOnly ? "Processing Volume" : "Card Processing"}
          description="Tell us about your current and projected processing volume on credit or debit cards for items purchased through an online or physical store. This information helps us better understand the processing needs for your business.">
          {isInternalLocalAcquiring
            ? renderBRProcessingFields()
            : renderProcessingFields()}
        </Fieldset>
      </div>
      {!isInternalLocalAcquiring && (
        <Fieldset
          title="Existing Merchant"
          description="If you are an existing Braintree merchant, you can add this merchant account to your gateway by selecting yes.">
          <GroupedList displayAsCard={true}>
            <ChoiceGroup
              errorText={errors.existing_merchant}
              label="Are you adding this account to an existing Braintree gateway?"
              name="existing_merchant"
              onBlur={handleBlur("existing_merchant")}
              onChange={handleChange("existing_merchant")}
              value={values.existing_merchant}>
              <Choice labelText="Yes" value="1" />
              <Choice labelText="No" value="0" />
            </ChoiceGroup>
            {values.existing_merchant === "1" && (
              <Component>
                <Half>
                  <TextField
                    errorText={errors.merchant_public_id}
                    name="merchant_public_id"
                    onBlur={handleBlur("merchant_public_id")}
                    onChange={handleChange("merchant_public_id")}
                    type="text"
                    value={values.merchant_public_id}
                    labelText="Merchant ID"
                    description={
                      <a href="https://developer.paypal.com/braintree/articles/control-panel/important-gateway-credentials#merchant-id">
                        See here for more details about your Merchant ID
                      </a>
                    }
                  />
                </Half>
              </Component>
            )}
          </GroupedList>
        </Fieldset>
      )}
      {!isInternalLocalAcquiring && (
        <Fieldset
          title="Preferred Merchant Account ID"
          description={
            <div>
              The merchant account ID is the unique name for your merchant
              account. It is used to call a specific account in your gateway in
              order to create a transaction. You can find more details about the
              merchant account ID{" "}
              <Link
                href={
                  "https://developer.paypal.com/braintree/articles/control-panel/important-gateway-credentials#merchant-account-id"
                }
                intent="simple"
                target="_blank">
                here.
              </Link>
              <br />
              <br />
              If you select No, your default merchant account ID is
              automatically generated.
            </div>
          }>
          <GroupedList displayAsCard={true}>
            <ChoiceGroup
              label="Do you have a preferred merchant account ID?"
              name="has_preferred_merchant_id"
              onChange={handleHasPreferredMerchantIdChange}
              value={hasPreferredMerchantId}>
              <Choice labelText="Yes" value="1" />
              <Choice labelText="No" value="0" />
            </ChoiceGroup>
            {hasPreferredMerchantId === "1" && (
              <Component>
                <Half>
                  <TextField
                    errorText={errors.preferred_merchant_account_id}
                    name="preferred_merchant_account_id"
                    onBlur={handleBlur("preferred_merchant_account_id")}
                    onChange={handleChange("preferred_merchant_account_id")}
                    type="text"
                    value={values.preferred_merchant_account_id}
                    labelText="Preferred merchant account ID"
                  />
                </Half>
              </Component>
            )}
          </GroupedList>
        </Fieldset>
      )}

      <Fieldset
        title="Subscriptions"
        description={
          <div>
            A subscription allows you to charge your customers automatically in
            monthly increments for a service or product. Learn more about
            recurring billing and subscriptions in the Braintree documentation.{" "}
            <Link
              href="https://developer.paypal.com/braintree/articles/guides/recurring-billing/overview"
              intent="simple"
              target="_blank">
              Learn More
            </Link>
          </div>
        }>
        <GroupedList displayAsCard={true}>
          <ChoiceGroup
            label="Do you regularly charge customers at regular intervals for the purchase of goods or services?"
            errorText={errors.offers_subscriptions}
            name="offers_subscriptions"
            onBlur={handleBlur("offers_subscriptions")}
            onChange={handleChange("offers_subscriptions")}
            value={values.offers_subscriptions}>
            <Choice labelText="Yes, I offer subscriptions" value="1" />
            <Choice labelText="No" value="0" />
          </ChoiceGroup>
          {values.offers_subscriptions === "1" && (
            <SubscriptionPercentages
              subscriptionPercentages={subscriptionPercentages}
              onChange={handleSubscriptionPercentageChange}
              onBlur={handleSubscriptionPercentageBlur}
            />
          )}
        </GroupedList>
      </Fieldset>
    </React.Fragment>
  );
};

// $FlowFixMe
export default connect(({ processing, ui, countryCode, venmoOnly }) => ({
  processing,
  ui,
  countryCode,
  venmoOnly
}))(Processing);
