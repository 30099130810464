/* @flow */

import React, { useState, type Node, type Element } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./Modal.scss";
import Button from "./Button";
import Popup from "./Popup";
import Overlay from "./Overlay";

type ModalProps = {
  allowDismissal: boolean,
  children: Node,
  isOpen: boolean,
  onDismiss: () => void,
  onDismissed?: () => void,
  testId?: string
};

const Modal = (props: ModalProps): Element<typeof CSSTransition> => {
  const {
    allowDismissal,
    children,
    isOpen,
    testId,
    onDismiss,
    onDismissed
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  const attemptDismissal = (): void => {
    if (allowDismissal) {
      onDismiss();
    }
  };

  return (
    <CSSTransition
      classNames={{
        enter: `${styles.fade} ${styles.fadeIn}`,
        enterActive: styles.fadeInActive,
        exit: `${styles.fade} ${styles.fadeOut}`,
        exitActive: styles.fadeOutActive
      }}
      in={isOpen}
      mountOnEnter={true}
      onEntered={() => {
        setIsVisible(true);
      }}
      onExited={() => {
        setIsVisible(false);
        if (onDismissed) {
          onDismissed();
        }
      }}
      timeout={200}
      unmountOnExit={true}>
      <div className={styles.root} data-test-id={testId}>
        <Popup onDismiss={attemptDismissal}>
          <Overlay onClick={attemptDismissal} />
          <CSSTransition
            appear={true}
            classNames={{
              appear: `${styles.slide} ${styles.slideUp}`,
              appearActive: styles.slideUpActive,
              exit: `${styles.slide} ${styles.slideDown}`,
              exitActive: styles.slideDownActive
            }}
            in={isOpen}
            timeout={400}>
            <div className={styles.frame}>
              <div className={styles.header}>
                <div className={styles.pushRight}>
                  <Button
                    autoFocus={isVisible}
                    disabled={!allowDismissal}
                    intent="secondary"
                    onClick={attemptDismissal}
                    size="small">
                    Cancel
                  </Button>
                </div>
              </div>
              <div className={styles.content}>{children}</div>
            </div>
          </CSSTransition>
        </Popup>
      </div>
    </CSSTransition>
  );
};

export default Modal;
