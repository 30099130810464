/* @flow */

import React from "react";
import countryConfig from "../countryConfig";
import styles from "./LegalFooter.scss";
import Link from "./Link";

type LegalFooterProps = {
  countryCode: string
};

const LegalFooter = ({ countryCode }: LegalFooterProps) => {
  const config = countryConfig[countryCode.toUpperCase()];
  const legalUrl =
    config !== undefined
      ? config.legalUrl
      : "https://braintreepayments.com/legal";

  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.root}>
      <p>
        Braintree is a service of PayPal. &copy; {currentYear} PayPal{" "}
        <span className={styles.nobreak}>
          <Link
            href={`${legalUrl}/braintree-privacy-policy`}
            intent="simple"
            target="_blank">
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link href={legalUrl} intent="simple" target="_blank">
            Legal
          </Link>
        </span>
      </p>

      {countryCode === "aus" && (
        <p>
          The PayPal service is provided by PayPal Australia Pty Limited (ABN 93
          111 195 389) which holds Australian Financial Services Licence number
          304962. Any information provided is general only and does not take
          into account your objectives, financial situation or needs.{" "}
          <Link
            href="https://www.paypal.com/au/webapps/mpp/ua/cfsgpds-full"
            intent="simple"
            target="_blank">
            Combined Financial Services Guide and Product Disclosure Statement
          </Link>{" "}
          before acquiring or using the PayPal service.
        </p>
      )}

      {countryCode === "nzl" && (
        <p>
          Consumer Advisory – PayPal Pte. Ltd. the holder of PayPal&rsquo;s
          stored value facility, does not require the approval of the Monetary
          Authority of Singapore. Users are advised to read the terms and
          conditions carefully.{" "}
          <Link
            href="https://www.paypal.com/sg/webapps/mpp/ua/legalhub-full"
            intent="simple"
            target="_blank">
            terms and conditions
          </Link>{" "}
          carefully..
        </p>
      )}
    </div>
  );
};

export default LegalFooter;
