/* @flow */

import { ValidationError } from "yup";
import paypalDetailsValidationSchema from "../validators/schemas/paypalDetails";
import type { PayPalDetailsState, Action } from "../types";

export const initialState: PayPalDetailsState = {
  values: {
    paypal_payer_id: ""
  },
  errors: {}
};

export const validate = (countryCode: string) => (
  name: string,
  state: PayPalDetailsState
): string | void => {
  try {
    paypalDetailsValidationSchema.validateSyncAt(name, state.values, {
      context: { countryCode }
    });
  } catch (error) {
    if (ValidationError.isError(error)) {
      return error.message;
    }
    throw error;
  }
  return undefined;
};

export const paypalDetailsReducerSwitch = (
  state: PayPalDetailsState,
  action: Action,
  countryCode: string
): PayPalDetailsState => {
  switch (action.type) {
    case "PAYPAL_DETAILS_FIELD_CHANGE":
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value
        }
      };
    case "PAYPAL_DETAILS_FIELD_BLUR": {
      const fieldValidationError = validate(countryCode)(
        action.payload.name,
        state
      );
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: fieldValidationError
        }
      };
    }
    case "LIFECYCLE_SUBMIT":
      try {
        paypalDetailsValidationSchema.validateSync(
          { ...state.values },
          {
            abortEarly: false,
            context: {
              countryCode
            }
          }
        );
      } catch (error) {
        if (ValidationError.isError(error)) {
          const errors = error.inner.reduce((accumulator, error) => {
            accumulator[error.params.path] = error.message;
            return accumulator;
          }, {});

          return {
            ...state,
            errors
          };
        }

        throw error;
      }
      return { ...state, errors: {} };

    default:
      return state;
  }
};

const paypalDetailsReducer = (countryCode: string) => (
  state: PayPalDetailsState = initialState,
  action: Action
): PayPalDetailsState => paypalDetailsReducerSwitch(state, action, countryCode);

export default paypalDetailsReducer;
