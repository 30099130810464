/* @flow */

import React, { useEffect, type Node } from "react";

type PopupProps = {
  children: Node,
  onDismiss?: () => void
};

const Popup = (props: PopupProps) => {
  const { children, onDismiss } = props;

  useEffect(() => {
    const elementToFocusOnDismiss = document.activeElement;

    return () => {
      if (elementToFocusOnDismiss instanceof HTMLElement) {
        elementToFocusOnDismiss.focus();
      }
    };
  }, []);

  return (
    <div
      onKeyDown={(event: SyntheticKeyboardEvent<>) => {
        if (event.key === "Escape") {
          if (onDismiss) {
            onDismiss();
          }
        }
      }}>
      {children}
    </div>
  );
};

export default Popup;
