/* @flow */

import React, { type Node, PureComponent } from "react";
import styles from "./Thanks.scss";
import Button from "../../components/Button";
import Avatar from "../../components/Avatar";

type ThanksProps = {
  message: string | Node,
  countryCode: string
};

class Thanks extends PureComponent<ThanksProps> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { message, countryCode } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.flag}>
          <Avatar
            size="large"
            src={`https://assets.braintreegateway.com/images/country-flags/96/${countryCode.toLowerCase()}.png`}
          />
        </div>
        <h1>Thank You!</h1>

        <div className={styles.subheading}>{message}</div>

        <Button
          href="https://articles.braintreepayments.com/risk-and-security/underwriting/overview"
          intent="secondary"
          target="_blank">
          Read the Underwriting Overview &rsaquo;
        </Button>
      </div>
    );
  }
}

export default Thanks;
