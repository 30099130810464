/* @flow */

import type { RootState, Action } from "../types";
import { combineReducers } from "redux";
import contactsReducer from "./contacts";
import businessReducer from "./business";
import processingReducer from "./processing";
import lifecycleReducer from "./lifecycle";
import fundingReducer from "./funding";
import uiReducer from "./ui";
import disclaimersReducer from "./disclaimers";
import paypalDetailsReducer from "./paypalDetails";
import midTypesReducer from "./midTypes";
import additionalFundingReducer from "./additionalFunding";
import { hydrateStore } from "../dev/hydrateStore";
import sharedValidationSchema from "../validators/schemas/shared";
import { ValidationError } from "yup";

const combinedReducer = (
  countryCode: string,
  countries: Array<string>,
  venmoOnly: boolean
): ((RootState | void, Action) => RootState) =>
  combineReducers({
    business: businessReducer(countryCode, venmoOnly),
    contacts: contactsReducer(countryCode),
    funding: fundingReducer(countryCode),
    lifecycle: lifecycleReducer,
    processing: processingReducer(countryCode),
    disclaimers: disclaimersReducer,
    ui: uiReducer,
    countryCode: (state = countryCode) => state || "",
    countries: (state = countries) => state || [],
    paypalDetails: paypalDetailsReducer(countryCode),
    additionalFunding: additionalFundingReducer(countryCode),
    venmoOnly: (state = venmoOnly) => state || false,
    midTypes: midTypesReducer(countryCode)
  });

const validateShared = (state: RootState): string | void => {
  try {
    sharedValidationSchema.validateSync(
      {
        registered_as: state.business.values?.registered_as,
        beneficial_owners: [
          ...state.contacts.values?.map(c => c?.ownership?.beneficial_owner)
        ]
      },
      { context: { countryCode: state.countryCode } }
    );
  } catch (error) {
    if (ValidationError.isError(error)) {
      return error.message;
    }

    throw error;
  }
  return undefined;
};

const reducer = (
  countryCode: string,
  countries: Array<string>,
  venmoOnly: boolean
) => (state: RootState | void, action: Action) => {
  let nextState = state;

  if (action.type === "HYDRATE") {
    nextState = hydrateStore(countryCode, venmoOnly);
  }

  const newState = combinedReducer(countryCode, countries, venmoOnly)(
    nextState,
    action
  );
  const sharedError = validateShared(newState);
  const isSubmittable =
    newState.contacts.errorText === undefined && sharedError === undefined;

  return {
    ...newState,
    lifecycle: {
      ...newState.lifecycle,
      isSubmittable,
      sharedError
    }
  };
};

export default reducer;
