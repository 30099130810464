/* @flow */

import type { Node } from "react";
import type { RenderFunction } from "../types";

export const formats = {
  TEXT: "text",
  CURRENCY: "currency"
};

export type TextFieldProps<T> = {
  description?: Node,
  labelText: string,
  id?: string,
  placeholder?: string,
  type?: "email" | "number" | "tel" | "text" | "password",
  name?: string,
  errorText?: string | Node,
  value: string,
  onBlur?: (event: SyntheticFocusEvent<T>) => void,
  onChange?: (event: SyntheticInputEvent<T>) => void,
  onFocus?: (event: SyntheticFocusEvent<T>) => void,
  onRenderInput?: RenderFunction<TextFieldProps<T>>,
  onRenderCurrencyInput?: RenderFunction<TextFieldProps<T>>,
  onRenderSuffix?: RenderFunction<TextFieldProps<T>>,
  inputClassName?: string,
  format?: $Values<typeof formats>
};
