/* @flow */

import React, { type StatelessFunctionalComponent } from "react";
import ListCell from "./ListCell";
import { RemoveIcon } from "./Icons";
import Button from "./Button";

type FileDetailsRowProps = {
  fileName: string,
  onDismiss: (event: SyntheticMouseEvent<>) => void
};

const FileDetailsRow: StatelessFunctionalComponent<FileDetailsRowProps> = ({
  fileName,
  onDismiss
}) => {
  const fileNameArray = fileName.split(".");
  const fileExtension = fileNameArray[fileNameArray.length - 1];

  let iconName;

  switch (fileExtension) {
    case "pdf":
      iconName = "pdf";
      break;
    case "png":
    case "jpg":
    case "jpeg":
    case "svg":
    case "gif":
      iconName = "image";
      break;
    case "txt":
    case "doc":
    case "docx":
      iconName = "document";
      break;
    case "csv":
    case "xls":
    case "xlsx":
      iconName = "sheet";
      break;
    case "ppt":
    case "pptx":
      iconName = "presentation";
      break;
    case "zip":
      iconName = "zip";
      break;
    default:
      iconName = "generic";
      break;
  }

  return (
    <div>
      <ListCell
        leftAccessory={
          <img
            src={`https://assets.braintreegateway.com/images/file-types/${iconName}.svg`}
            width="48"
            height="48"
            alt={`${iconName} file`}
          />
        }
        title={fileName}
        rightAccessory={
          <Button
            intent="danger"
            size="small"
            type="button"
            title="Remove"
            onClick={onDismiss}>
            <RemoveIcon />
          </Button>
        }
      />
    </div>
  );
};

export default FileDetailsRow;
