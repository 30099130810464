/* @flow */

import React, { type Node } from "react";
import styles from "./Fieldset.scss";

type FieldsetProps = {
  children: Node,
  title: string,
  description?: Node
};

const Fieldset = (props: FieldsetProps) => {
  const { children, title, description } = props;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h3>{title}</h3>
        {description !== undefined && (
          <div className={styles.description}>{description}</div>
        )}
      </div>
      {children}
    </div>
  );
};

export default Fieldset;
