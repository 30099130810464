/* @flow */

import React, { Fragment, Component, type Node } from "react";
import styles from "./HelpText.scss";
import classNames from "classnames";

type HelpTextProps = {
  summary?: string,
  children: Node,
  alignTextCenter?: boolean
};

type HelpTextState = {
  expanded: boolean
};

class HelpText extends Component<HelpTextProps, HelpTextState> {
  state = {
    expanded: false
  };

  handleOnClick = (): void => {
    this.setState(state => ({
      expanded: !state.expanded
    }));
  };

  render() {
    const { summary, children, alignTextCenter } = this.props;
    const { expanded } = this.state;
    const showSummary = summary !== undefined && !expanded;
    const className = classNames(styles.root, {
      [styles.center]: alignTextCenter
    });

    return (
      <div className={className}>
        {showSummary ? (
          <Fragment>
            {summary}{" "}
            <button
              className={styles.button}
              type="button"
              onClick={this.handleOnClick}>
              More
            </button>
          </Fragment>
        ) : (
          children
        )}
      </div>
    );
  }
}

export default HelpText;
