/* @flow */

import { ValidationError } from "yup";
import fundingValidationSchema from "../validators/schemas/funding";
import type {
  FundingState,
  FundingStateForInit,
  FundingValues,
  Action
} from "../types";

export const initialState: FundingState = {
  values: {
    account_number: "",
    routing_number: "",
    bank_name: "",
    bic: "",
    bank_account_holder_name: "",
    transit_number: "",
    institution_code: ""
  },
  address: {
    errors: {},
    values: {
      country: "",
      street_address: "",
      region: "",
      postal_code: "",
      locality: ""
    }
  },
  errors: {},
  documents: []
};

export const validate = (
  countryCode: string,
  initialAccountNumber: ?string
) => (name: string, values: FundingValues): string | void => {
  try {
    fundingValidationSchema.validateSyncAt(name, values, {
      context: { countryCode, initialAccountNumber }
    });
  } catch (error) {
    if (ValidationError.isError(error)) {
      return error.message;
    }
    throw error;
  }
  return undefined;
};

export const fundingReducerSwitch = (
  state: FundingState,
  action: Action,
  countryCode: string,
  persistedState: ?FundingStateForInit
): FundingState => {
  switch (action.type) {
    case "FUNDING_FIELD_CHANGE":
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value
        },
        address: {
          ...state.address
        }
      };
    case "ADDRESS_FUNDING_FIELD_CHANGE":
      return {
        ...state,
        values: {
          ...state.values
        },
        address: {
          errors: state.address.errors,
          values: {
            ...state.address.values,
            [action.payload.name]: action.payload.value
          }
        }
      };
    case "FUNDING_FIELD_BLUR": {
      const fieldValidationError = validate(
        countryCode,
        persistedState ? persistedState.account_number : undefined
      )(action.payload.name, {
        ...state.values,
        address: { ...state.address.values }
      });
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: fieldValidationError
        }
      };
    }
    case "ADDRESS_FUNDING_FIELD_BLUR": {
      const fieldValidationError = validate(
        countryCode,
        persistedState ? persistedState.account_number : undefined
      )(`address.${action.payload.name}`, {
        ...state.values,
        address: { ...state.address.values }
      });

      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: fieldValidationError
        }
      };
    }
    case "LIFECYCLE_SUBMIT":
      try {
        fundingValidationSchema.validateSync(
          { ...state.values, address: state.address.values },
          {
            abortEarly: false,
            context: {
              countryCode,
              initialAccountNumber: persistedState
                ? persistedState.account_number
                : undefined
            }
          }
        );
      } catch (error) {
        if (ValidationError.isError(error)) {
          const errors = error.inner.reduce((accumulator, error) => {
            accumulator[error.params.path] = error.message;
            return accumulator;
          }, {});

          return {
            ...state,
            errors
          };
        }

        throw error;
      }
      return { ...state, errors: {} };
    case "FUNDING_DOCUMENT_ADD":
      return {
        ...state,
        documents: state.documents.concat(action.payload)
      };
    case "FUNDING_DOCUMENT_REMOVE":
      return {
        ...state,
        documents: state.documents.filter(doc => doc.id !== action.payload.id)
      };
    case "SERVER_SIDE_VALIDATION_ERROR":
      return {
        ...state,
        errors: { ...action.payload.funding }
      };

    default:
      return state;
  }
};

const fundingReducer = (
  countryCode: string,
  persistedState: ?FundingStateForInit
) => (state: FundingState = initialState, action: Action): FundingState =>
  fundingReducerSwitch(state, action, countryCode, persistedState);

export default fundingReducer;
