/* @flow */

export const readAsDataUrl = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject): void => {
    const fileReader = new FileReader();
    fileReader.onerror = (): void => {
      fileReader.abort();
      reject(fileReader.error);
    };
    fileReader.onload = (): void => {
      resolve(String(fileReader.result));
    };
    fileReader.readAsDataURL(blob);
  });

// $FlowFixMe
export const readImageContents = (blob: Blob): Promise<any> =>
  new Promise((resolve, reject): void => {
    const fileReader = new FileReader();
    fileReader.onerror = (): void => {
      fileReader.abort();
      reject(fileReader.error);
    };
    fileReader.onload = (): void => {
      const image = new Image();
      image.src = String(fileReader.result);
      image.onload = function() {
        resolve({
          contents: String(fileReader.result),
          height: this.naturalHeight,
          width: this.naturalWidth
        });
      };
      image.onerror = (): void => {
        fileReader.abort();
        reject(fileReader.error);
      };
    };
    fileReader.readAsDataURL(blob);
  });
