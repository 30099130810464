import { object, string } from "yup";
import { stringExists, USPhoneString } from "./helpers";
import { addressFieldsForBusiness } from "./address";
import {
  isFormattedDate,
  isInBusinessAgeRange,
  isAbn,
  isAcn,
  isDateInThePast
} from "../";
import { overSome } from "lodash";
import { isEurope } from "../../countryHelper";

export default object().shape({
  dba_name: stringExists,
  description: stringExists,
  // $FlowFixMe
  tax_id: string().when(
    ["$countryCode", "registered_as"],
    (countryCode, registeredAs, schema) => {
      if (countryCode === "USA") {
        if (registeredAs === "sole_proprietorship") {
          return schema.notRequired();
        }
        return schema
          .matches(/^\d+$/, {
            message: "Sorry, only numbers (0-9) are allowed",
            excludeEmptyString: true
          })
          .length(
            9,
            "Please enter a valid United States Federal Tax Identification Number (TIN)"
          );
      }
      if (countryCode === "AUS") {
        return schema.test(
          "abnOrAcn",
          "Please enter an 11-digit ABN or 9-digit ACN",
          overSome([isAbn, isAcn])
        );
      }
      if (countryCode === "BRA") {
        return schema
          .matches(/^\d+$/, {
            message: "Sorry, only numbers (0-9) are allowed",
            excludeEmptyString: true
          })
          .length(
            14,
            "Please enter a 14 digit valid Brazil Federal Tax Identification Number (CNPJ)"
          );
      }
      if (isEurope(countryCode)) {
        return schema.notRequired();
      }
      return stringExists;
    }
  ),
  registration_number: string().when(["$countryCode"], (countryCode, schema) =>
    isEurope(countryCode) ? stringExists : schema.notRequired()
  ),
  established_on: stringExists
    .test(
      "format",
      "Please use a YYYY-MM-DD or MM/DD/YYYY format",
      isFormattedDate
    )
    .test("age", "A valid date is required", isInBusinessAgeRange)
    .test("date", "Please select a date in the past", isDateInThePast),
  legal_name: string().when(["$countryCode", "registered_as"], {
    is: (countryCode, registeredAs) =>
      countryCode === "USA" && registeredAs === "sole_proprietorship",
    then: string().notRequired(),
    otherwise: stringExists
  }),
  registered_as: string().required("Please select an option from the list"),
  industry: string().when(["$isReopening"], (isReopening, schema) => {
    if (isReopening) {
      return schema.notRequired();
    }
    return schema.required("Please select an option from the list");
  }),
  mcc: string().notRequired(),
  // $FlowFixMe
  state_incorporated: string().when(
    ["$countryCode", "registered_as"],
    (countryCode, registeredAs, schema) => {
      if (countryCode === "USA") {
        if (registeredAs === "sole_proprietorship") {
          return schema.notRequired();
        }
        return schema.required("Please select an option from the list");
      }
      return schema.notRequired();
    }
  ),
  phone: string().when("$countryCode", {
    is: "USA",
    then: USPhoneString,
    otherwise: string()
      .ensure()
      .required("Please enter a phone number with country code")
  }),
  website: stringExists.url(
    `A valid URL including "http://" or "https://" is required`
  ),
  address: addressFieldsForBusiness,
  business_location_type: string().when(
    ["$countryCode"],
    (countryCode, schema) => {
      if (countryCode === "CAN") {
        return schema.required("Please select an option from the list");
      }
      return schema.notRequired();
    }
  ),
  staging_website_url: string().notRequired(),
  staging_login: string().notRequired(),
  staging_password: string().notRequired(),
  email: string().when(["$venmoOnly"], {
    is: venmoOnly => venmoOnly,
    then: stringExists,
    otherwise: string().notRequired()
  }),
  logo_image_id: string().when(["$venmoOnly", "pay_with_venmo"], {
    is: (venmoOnly, pay_with_venmo) => venmoOnly || pay_with_venmo === "1",
    then: stringExists,
    otherwise: string().notRequired()
  }),
  pay_with_venmo: string().notRequired()
});
