/* @flow */

import React, { type Node } from "react";
import { type Store, createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "../reducers";
import type { RootState, Action } from "../types";

type StoreProviderProps = {
  countryCode: string,
  countries: Array<string>,
  children: Node,
  venmoOnly: boolean
};

export const StoreProvider = (props: StoreProviderProps) => {
  const { children, countryCode, countries, venmoOnly } = props;
  const store: Store<RootState, Action> = createStore(
    reducer(countryCode, countries, venmoOnly),
    ["development", "qa", "qa2"].includes(process.env.RAILS_ENV) &&
      window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : undefined
  );

  return <Provider store={store}>{children}</Provider>;
};
