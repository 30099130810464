/* @flow */

import React from "react";
import type { RadioProps } from "./Radio.types";
import styles from "./Radio.scss";

export const Radio = ({
  name,
  value,
  checked,
  labelText,
  onBlur,
  onChange,
  onFocus
}: RadioProps) => (
  <label className={styles.root}>
    <input
      className={styles.input}
      type="radio"
      checked={checked}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
    <div className={styles.text}>
      <div className={styles.title}>{labelText}</div>
    </div>
  </label>
);
