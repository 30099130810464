/* @flow */

import React, { type StatelessFunctionalComponent, type Node } from "react";
import styles from "./ListCell.scss";

type ListCellProps = {
  rightAccessory?: Node,
  leftAccessory?: Node,
  description?: string,
  title: string
};

const ListCell: StatelessFunctionalComponent<ListCellProps> = ({
  rightAccessory,
  leftAccessory,
  description,
  title
}) => (
  <div className={styles.root}>
    {leftAccessory !== undefined && (
      <div className={styles.leftAccessory}>{leftAccessory}</div>
    )}
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      {description !== undefined && (
        <div className={styles.description}>{description}</div>
      )}
    </div>
    {rightAccessory !== undefined && (
      <div className={styles.rightAccessory}>{rightAccessory}</div>
    )}
  </div>
);

export default ListCell;
