import { object, string, array } from "yup";
import { isRoutingNumber } from "../";
import {
  SUPPORTED_EU_COUNTRY_CODES,
  SUPPORTED_APAC_COUNTRY_CODES
} from "../../countryHelper";

import { addressFieldsForContact } from "./address";
import { stringExists } from "./helpers";

export default object().shape({
  routing_number: string()
    .when("$countryCode", {
      is: "USA",
      then: string().test(
        "isRoutingNumber",
        "Please enter a valid 9-digit routing number",
        isRoutingNumber
      )
    })
    .when("$countryCode", {
      is: countryCode => countryCode === "AUS" || countryCode === "NZL",
      then: string().matches(
        /^\d{6}$/,
        "Please enter a 6-digit number without hyphens, spaces or special characters"
      )
    })
    .when("$countryCode", {
      is: countryCode => SUPPORTED_EU_COUNTRY_CODES.includes(countryCode),
      then: string().matches(
        /^([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)$/,
        "Please enter a valid BIC"
      )
    }),
  account_number: string()
    .when(["$countryCode", "$initialAccountNumber"], {
      is: (countryCode, initialAccountNumber) =>
        // for USA signups validate as usual
        countryCode === "USA" && !initialAccountNumber,
      then: string().matches(/^\d+$/, "Please enter a valid account number")
    })
    .when(
      ["$countryCode", "$initialAccountNumber"],
      (countryCode, initialAccountNumber, schema) => {
        return schema.test({
          test: account_number =>
            // for USA reopenings validate only if the account number was changed (because of asterisks)
            countryCode === "USA"
              ? account_number === initialAccountNumber ||
                account_number.match(/^\d+$/)
              : true,
          message: "Please enter a valid account number"
        });
      }
    )
    .when("$countryCode", {
      is: "CAN",
      then: string().matches(
        /^\d{1,15}$/,
        "Please enter a valid account number"
      )
    })
    .when("$countryCode", {
      is: countryCode =>
        countryCode === "AUS" ||
        countryCode === "NZL" ||
        SUPPORTED_APAC_COUNTRY_CODES.includes(countryCode),
      then: stringExists
    })
    .when(["$countryCode", "$initialAccountNumber"], {
      is: (countryCode, initialAccountNumber) =>
        // for EU signups validate as usual
        SUPPORTED_EU_COUNTRY_CODES.includes(countryCode) &&
        !initialAccountNumber,
      then: string().matches(
        /^([A-Z]{2}[-]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/,
        "Please enter a valid IBAN"
      )
    })
    .when(
      ["$countryCode", "$initialAccountNumber"],
      (countryCode, initialAccountNumber, schema) => {
        return schema.test({
          test: account_number =>
            // for EU reopenings validate only if the account number was changed (because of asterisks)
            SUPPORTED_EU_COUNTRY_CODES.includes(countryCode)
              ? account_number === initialAccountNumber ||
                account_number.match(
                  /^([A-Z]{2}[-]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/
                )
              : true,
          message: "Please enter a valid IBAN"
        });
      }
    ),
  bank_name: string().when("$countryCode", {
    is: countryCode =>
      SUPPORTED_APAC_COUNTRY_CODES.includes(countryCode) ||
      countryCode === "CAN",
    then: stringExists
  }),

  bic: string().when("$countryCode", {
    is: countryCode => SUPPORTED_APAC_COUNTRY_CODES.includes(countryCode),
    then: stringExists
  }),
  address: object().when("$countryCode", {
    is: countryCode => SUPPORTED_APAC_COUNTRY_CODES.includes(countryCode),
    then: addressFieldsForContact
  }),
  bank_account_holder_name: string().when("$countryCode", {
    is: "CAN",
    then: stringExists
  }),
  transit_number: string().when("$countryCode", {
    is: "CAN",
    then: string().matches(
      /^\d{1,6}$/,
      "Please enter an up to 6 digit number without hyphens, spaces or special characters"
    )
  }),
  institution_code: string().when("$countryCode", {
    is: "CAN",
    then: string().matches(/^\d{1,4}$/, "Please enter a valid institution code")
  }),
  documents: array().when("$countryCode", {
    is: countryCode => countryCode === "AUS",
    then: array()
      .min(1, "Please upload at least 1 document")
      .required("Please fill out this field")
  })
});
