/* @flow */

import React, {
  type StatelessFunctionalComponent,
  type ElementProps
} from "react";
import styles from "./RaisedSheet.scss";

const RaisedSheet: StatelessFunctionalComponent<
  ElementProps<"div">
> = props => <div {...props} className={styles.root} />;

export default RaisedSheet;
