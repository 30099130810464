/* @flow */

export const humanizeBytes = (bytes: number): string => {
  if (bytes === 0) return "0 B";
  const unitMultiplier = 1000;
  const sizes = ["B", "kB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(unitMultiplier));
  const suffix = sizes[i];
  const value = parseFloat((bytes / Math.pow(unitMultiplier, i)).toFixed(1));
  return `${value} ${suffix}`;
};
