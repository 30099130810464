/* @flow */

import { omit } from "lodash/fp";
import { isEmpty, isEqual } from "lodash";
import { ValidationError } from "yup";
import type { ContactsState, Action, Contact } from "../types";
import { ContactCollectionSchema } from "../validators/schemas/contact";

export const validate = (
  values: Contact[],
  countryCode: string,
  initialIdNumbers: ?Array<string>
): string | void => {
  try {
    ContactCollectionSchema.validateSync(values, {
      abortEarly: false,
      context: {
        countryCode,
        initialIdNumbers
      }
    });
  } catch (error) {
    if (ValidationError.isError(error)) {
      if (error.errors.length > 1) {
        return error.errors[0];
      }
      return error.message;
    }
    throw error;
  }
  return undefined;
};

export const initialState: ContactsState = {
  errorText: validate([], ""),
  values: []
};

const DEFAULT_ERROR_WITH_ADDRESS = { address: {} };

export const contactsReducerSwitch = (
  state: ContactsState,
  action: Action,
  countryCode: string,
  persistedState: ?(Contact[])
): ContactsState => {
  let newValues = [];
  switch (action.type) {
    case "CONTACTS_ADD":
      newValues = state.values.concat(action.payload);
      break;
    case "CONTACTS_UPDATE":
      let updated = false;

      newValues = state.values.map(contact => {
        if (contact.id === action.payload.id) {
          updated = true;
          return omit(["serverSideValidationErrors"])(action.payload);
        }

        return contact;
      });

      if (!updated) {
        throw new Error(`contact ${action.payload.id} not found`);
      }

      break;
    case "CONTACTS_DELETE":
      if (action.payload.id.length <= 10) {
        // removing new contact
        newValues = state.values.filter(
          contact => contact.id !== action.payload.id
        );

        if (newValues.length === state.values.length) {
          throw new Error(`contact ${action.payload.id} not found`);
        }
      } else {
        // removing existing contact
        newValues = state.values.map(contact =>
          contact.id === action.payload.id
            ? { ...contact, _destroy: "1" }
            : { ...contact }
        );

        if (
          newValues.filter(contact => contact._destroy === "1").length === 0
        ) {
          throw new Error(`contact ${action.payload.id} not found`);
        }
      }

      break;
    case "SERVER_SIDE_VALIDATION_ERROR":
      const nextValues: Contact[] = state.values.map((contact, i) => {
        const serverSideValidationErrors = (action.payload.contacts || [])[i];
        if (
          isEqual(serverSideValidationErrors, DEFAULT_ERROR_WITH_ADDRESS) ||
          isEmpty(serverSideValidationErrors)
        ) {
          return contact;
        }

        return {
          ...contact,
          serverSideValidationErrors
        };
      });

      return {
        ...state,
        values: nextValues,
        errorText: nextValues.every(contact =>
          isEmpty(contact.serverSideValidationErrors)
        )
          ? undefined
          : "Sorry, there are errors with one or more of the contacts below."
      };

    default:
      return state;
  }

  return {
    errorText: validate(
      newValues,
      countryCode,
      persistedState
        ? persistedState.reduce((initialIdNumbers, contact) => {
            const idNumber = contact.personalInformation.id_number;
            if (idNumber != null) {
              initialIdNumbers.push(idNumber);
            }
            return initialIdNumbers;
          }, [])
        : undefined
    ),
    values: newValues
  };
};

const contactsReducer = (countryCode: string) => (
  state: ContactsState = initialState,
  action: Action
): ContactsState => contactsReducerSwitch(state, action, countryCode);

export default contactsReducer;
