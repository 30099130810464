/* @flow */

import { Third, TwoThirds } from "../../components/GridItem";
import Grid from "../../components/Grid";
import TextField from "../../components/TextField";
import RegionField from "../RegionField";
import React from "react";
import type {
  BusinessAddressState,
  FormBlurHandlerFn,
  FormChangeHandlerFn
} from "../../types";
import countryConfig from "../../countryConfig";

const BusinessAddress = ({
  address: { errors, values },
  onBlur,
  onChange,
  countryCode
}: {
  address: BusinessAddressState,
  onBlur: FormBlurHandlerFn,
  onChange: FormChangeHandlerFn,
  countryCode: string
}) => (
  <Grid>
    <TwoThirds>
      <TextField
        errorText={errors.street_address}
        labelText="Street Address"
        name="street_address"
        onBlur={onBlur}
        onChange={onChange}
        value={values.street_address}
        description={countryConfig[countryCode].businessAddressStreetLabel}
      />
    </TwoThirds>
    <Third>
      <TextField
        labelText="Address 2 (Optional)"
        name="extended_address"
        onBlur={onBlur}
        onChange={onChange}
        value={values.extended_address}
      />
    </Third>
    <Third>
      <TextField
        errorText={errors.locality}
        labelText={countryConfig[countryCode].localityLabel}
        name="locality"
        onBlur={onBlur}
        onChange={onChange}
        value={values.locality}
      />
    </Third>
    <Third>
      <RegionField
        labelText="State"
        name="region"
        countryCode={countryCode}
        errors={errors.region}
        values={values.region}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Third>
    <Third>
      <TextField
        errorText={errors.postal_code}
        labelText="Postal Code"
        name="postal_code"
        placeholder={countryConfig[countryCode].postalCodePlaceholder}
        onBlur={onBlur}
        onChange={onChange}
        value={values.postal_code}
      />
    </Third>
  </Grid>
);

export default BusinessAddress;
