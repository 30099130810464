/** @flow */

import type { DisclaimersAction, DisclaimersState } from "../types";
import { ValidationError } from "yup";
import { DisclaimersSchema } from "../validators/schemas/disclaimers";

export const initialState = {
  values: {
    attestation_given: false,
    disclosures_approved: false,
    venmo_terms_accepted: true
  },
  errors: {}
};

const validate = (name: string, state: DisclaimersState): string | void => {
  try {
    DisclaimersSchema.validateSyncAt(name, state.values);
  } catch (error) {
    if (ValidationError.isError(error)) {
      return error.message;
    }
    throw error;
  }
  return undefined;
};

const disclaimersReducer = (
  state: DisclaimersState = initialState,
  action: DisclaimersAction
): DisclaimersState => {
  switch (action.type) {
    case "DISCLAIMERS_FIELD_CHANGE":
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value
        }
      };
    case "DISCLAIMERS_FIELD_BLUR":
      const fieldValidationError = validate(action.payload.name, state);
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: fieldValidationError
        }
      };
    case "LIFECYCLE_SUBMIT":
      try {
        DisclaimersSchema.validateSync(state.values, {
          abortEarly: false
        });
      } catch (error) {
        const errors = error.inner.reduce((accumulator, error) => {
          accumulator[error.params.path] = error.message;
          return accumulator;
        }, {});

        return {
          ...state,
          errors
        };
      }
      return state;
    default:
      return state;
  }
};

export default disclaimersReducer;
