import React from "react";
import TextField from "./TextField";
import SelectField from "./SelectField";
import countryConfig from "../countryConfig";
import { COUNTRIES_REGION_DROPDOWN } from "../../../common";

const RegionField = ({
  labelText,
  name,
  countryCode,
  errors = "",
  values,
  onChange,
  onBlur
}) => {
  const label = countryConfig[countryCode]
    ? countryConfig[countryCode].regionLabel
    : labelText;
  const options = countryConfig[countryCode]
    ? countryConfig[countryCode].regions
    : [];
  return (
    <>
      {COUNTRIES_REGION_DROPDOWN.includes(countryCode) ? (
        <SelectField
          errorText={errors}
          labelText={label}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          options={options}
          value={values}
        />
      ) : (
        <TextField
          errorText={errors}
          labelText={label}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={values}
        />
      )}
    </>
  );
};

export default RegionField;
