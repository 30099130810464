/* @flow */

import React, { useState } from "react";
import styles from "./EuCountry.scss";
import { SUPPORTED_EU_COUNTRIES_METADATA } from "../../countryHelper";
import Button from "../../components/Button";
import GroupedList from "../../components/GroupedList";
import SelectField from "../../components/SelectField";
import urlValidation from "../../urlValidation";

const EuCountry = () => {
  const [countryCode, setCountryCode] = useState("");
  const euCountries = SUPPORTED_EU_COUNTRIES_METADATA.map(data => ({
    key: data.countryCode,
    text: data.name
  }));

  const handleSubmit = e => {
    e.preventDefault();
    const query = document.location.search;
    if (urlValidation(query)) {
      document.location.assign(`/country/${countryCode}${query}`);
    } else {
      document.location.assign(`/country/${countryCode}`);
    }
  };

  return (
    <form
      id="eu-country-select"
      onSubmit={handleSubmit}
      className={styles.form}>
      <h2>Select Business Location</h2>
      <GroupedList displayAsCard={true}>
        <SelectField
          required={true}
          labelText="European Country"
          value={countryCode}
          onChange={e => {
            setCountryCode(e.target.value);
          }}
          options={euCountries}
        />
        <Button submit={true} disabled={!countryCode}>
          Next
        </Button>
      </GroupedList>
    </form>
  );
};

export default EuCountry;
