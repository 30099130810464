/* @flow */

import React from "react";
import styles from "./Avatar.scss";
import classNames from "classnames";

type AvatarProps = {
  src: string,
  size: "medium" | "large"
};

const Avatar = ({ size, src }: AvatarProps) => (
  <div className={classNames(styles.root, styles[size])}>
    <img className={styles.image} src={src} alt="Avatar" />
  </div>
);

Avatar.defaultProps = {
  src: "https://assets.braintreegateway.com/images/ui/person.svg",
  size: "medium"
};

export default Avatar;
