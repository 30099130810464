/* @flow */

import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./Button.scss";
import Spinner from "./Spinner";
import Focus from "./Focus";
import Anchor from "./Anchor";

type ButtonProps = {
  autoFocus?: boolean,
  children: Node,
  disabled?: boolean,
  fullWidth?: boolean,
  href?: string,
  intent?: "primary" | "secondary" | "simple" | "danger",
  onClick?: (
    event: SyntheticMouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => void,
  loading?: boolean,
  target?: string,
  size: "small" | "medium",
  submit?: boolean,
  title?: string
};

const Button = ({
  autoFocus,
  children,
  disabled,
  fullWidth,
  href,
  intent,
  onClick,
  loading,
  target,
  size,
  submit,
  title
}: ButtonProps) => {
  const sizeClassName = fullWidth ? styles.fullWidth : styles.autoWidth;
  const isDisabled = disabled || loading;
  const className = classNames(
    styles.button,
    sizeClassName,
    styles[intent],
    styles[size],
    {
      [styles.disabled]: isDisabled
    }
  );
  const childrenWrapperClassName = classNames({
    [styles.hideVisually]: loading
  });
  let Component;
  let props;

  if (href !== undefined) {
    Component = Anchor;
    props = { href, target };
  } else {
    Component = "button";
    props = {
      type: submit ? "submit" : "button",
      disabled: isDisabled
    };
  }

  return (
    <Focus focus={autoFocus}>
      <Component
        {...props}
        title={title}
        className={className}
        onClick={onClick}>
        {loading && (
          <div className={styles.loader}>
            <Spinner />
          </div>
        )}
        <div className={childrenWrapperClassName}>{children}</div>
      </Component>
    </Focus>
  );
};

Button.defaultProps = {
  size: "medium"
};

export default Button;
