/* @flow */

import React, { useEffect } from "react";
import styles from "./Overlay.scss";

type OverlayProps = {
  onClick?: (event: SyntheticMouseEvent<HTMLElement>) => void
};

const Overlay = (props: OverlayProps) => {
  const { onClick } = props;

  useEffect(() => {
    const { body } = document;
    const className = styles.disableScrolling;

    if (body !== null) {
      body.classList.add(className);
    }

    return () => {
      if (body !== null) {
        body.classList.remove(className);
      }
    };
  }, []);

  return <div className={styles.root} onClick={onClick} />;
};

export default Overlay;
