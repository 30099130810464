/* @flow */
import { omit } from "lodash";
const countries = require("country-data")
  .countries.all.filter(country => country.alpha3 !== "")
  .map(country => [country.name, country.alpha3]);
import { isEurope } from "../countryHelper";

function getPostalCode(countryCode: string) {
  switch (countryCode) {
    case "GBR":
      return "SW1W 0NY";
    default:
      return "12345";
  }
}

function getRegion(countryCode: string) {
  switch (countryCode) {
    case "MYS":
      return "Sabah";
    case "SGP":
      return "Tampines";
    case "HKG":
      return "Kwun Tong";
    case "AUS":
      return "ACT";
    case "NZL":
      return "Auckland";
    case "USA":
      return "IL";
    case "CAN":
      return "BC";
    default:
      return "Fakeville";
  }
}

function getAccountNumber(countryCode: string) {
  switch (countryCode) {
    case "AUS":
    case "NZL":
    case "USA":
    case "CAN":
      return "123456789";
    case "BRA":
      return "";
    default:
      return "NL19INGB1022564560";
  }
}

function getRoutingNumber(countryCode: string) {
  switch (countryCode) {
    case "AUS":
    case "NZL":
      return "123456";
    case "USA":
      return "211370545";
    case "BRA":
      return "";
    default:
      return "CECBCY2N";
  }
}

export const createContact = (countryCode: string) => {
  const contact = {
    id: "",
    authorizedSigner: true,
    personalInformation: {
      first_name: countryCode === "USA" ? "Robert" : "Lumpy",
      last_name: countryCode === "USA" ? "Birmingham" : "Space Princess",
      job_title: "Princess of Space",
      email: "lumpy@space.com",
      phone: "5554678950",
      date_of_birth: "10/10/1988",
      nationality: countryCode,
      id_type: "Passport",
      ever_filed_for_bankruptcy: countryCode === "CAN" ? "0" : "",
      address: {
        country: countryCode,
        locality: "Beltsville",
        postal_code: getPostalCode(countryCode),
        region: getRegion(countryCode),
        street_address: "111 N Canal Street"
      }
    },
    documents: [],
    ownership: {
      beneficial_owner: "1",
      significant_responsibility: "1",
      ownership_percentage: "100"
    }
  };

  if (countryCode === "USA") {
    // $FlowFixMe – the personalInformation Object is not typed, but id_number is valid
    contact.personalInformation.id_number = "666-52-2949";
  }

  return contact;
};

const createPayPalDetails = (countryCode: string) => {
  if (countryCode === "BRA") {
    return {
      values: {
        paypal_payer_id: "Z43JK2OLKI4ST"
      },
      errors: {}
    };
  }
  return {
    values: {},
    errors: {}
  };
};

const createMidTypes = (countryCode: string) => {
  if (countryCode === "BRA") {
    return {
      values: {
        trusted_credit_mid: true,
        trusted_debit_mid: true,
        trusted_add_card_mid: false,
        credit_mid: true,
        debit_mid: true,
        add_card_mid: false,
        credit_settlement_type: "D+15"
      },
      errors: {}
    };
  }
  return {
    values: {
      trusted_credit_mid: false,
      trusted_debit_mid: false,
      trusted_add_card_mid: false,
      credit_mid: false,
      debit_mid: false,
      add_card_mid: false
    },
    errors: {}
  };
};

const fundingValues = (countryCode: string) => ({
  values: {
    account_number: getAccountNumber(countryCode),
    routing_number: countryCode === "CAN" ? "" : getRoutingNumber(countryCode),
    bic: "BOFAUS",
    bank_name: "Bank of America",
    bank_account_holder_name: countryCode === "CAN" ? "Scott Alba" : "",
    transit_number: countryCode === "CAN" ? "123456" : "",
    institution_code: countryCode === "CAN" ? "1234" : ""
  },
  address: {
    errors: {},
    values: {
      country: countryCode,
      region: getRegion(countryCode),
      postal_code: getPostalCode(countryCode),
      street_address: "123 Street Ave",
      locality: "Here"
    }
  },
  errors: {},
  documents: []
});

const getTaxId = (countryCode: string) => {
  if (countryCode === "BRA") return "12345678909875";
  return isEurope(countryCode) ? "" : "000000019";
};

export const hydrateStore = (countryCode: string, venmoOnly: boolean) => {
  let storeObj = {
    venmoOnly,
    business: {
      values: {
        dba_name: countryCode === "USA" ? "Howdy Ho" : "This Is Fine",
        registered_as:
          countryCode === "USA"
            ? "limited_liability_corporation"
            : "public_corporation",
        industry: countryCode === "USA" ? "non_profit" : "generic_retail",
        established_on: "10/18/2001",
        legal_name: countryCode === "USA" ? "Howdy Ho Co" : "This Is Fine, Ltd",
        tax_id: getTaxId(countryCode),
        registration_number: isEurope(countryCode) ? "000000019" : "",
        state_incorporated: getRegion(countryCode),
        phone: "5554678950",
        website:
          countryCode === "USA" ? "http://howdyho.com" : "http://thisisfine.co",
        description:
          countryCode === "USA" ? "A howdy'in and a ho'din" : "It be burnin!",
        business_location_type: countryCode === "CAN" ? "warehouse" : "",
        email: "howdy@ho.com",
        logo_image: [],
        logo_image_id: "",
        pay_with_venmo: "0",
        venmo_terms_accepted: "0"
      },
      errors: {},
      address: {
        values: {
          street_address: "123 Street Ave",
          extended_address: "3",
          locality: "Here",
          region: getRegion(countryCode),
          postal_code: getPostalCode(countryCode)
        },
        errors: {}
      }
    },
    funding: fundingValues(countryCode),
    additionalFunding: fundingValues(countryCode),
    processing: {
      errors: {},
      values: {
        annual_volume_amount: "1000",
        average_transaction_amount: "100",
        currently_processing_credit_cards: "1",
        customer_charged_on: "order",
        existing_merchant: "0",
        fulfillment_completed_in: "7",
        maximum_transaction_amount: "300",
        offers_subscriptions: "0",
        refund_policy: "exchange_only",
        ship_physical_goods: "0",
        ships_own_products: "0",
        in_person: "0",
        customer_statement_descriptor:
          countryCode === "CAN" ? "This Is Fine Company" : "",
        customer_statement_phone_number:
          countryCode === "CAN" ? "5554678950" : "",
        processing_currencies: countryCode === "CAN" ? "CAD" : "",
        accept_amex: countryCode === "CAN" ? "0" : ""
      },
      subscriptionPercentages: {
        value: {
          monthly: 0,
          quarterly: 0,
          semi_annually: 0,
          annually: 0
        }
      },
      documents: []
    },
    contacts: {
      errorText: undefined,
      values: [createContact(countryCode)]
    },
    disclaimers: {
      values: {
        attestation_given: true,
        disclosures_approved: true
      },
      errors: {}
    },
    lifecycle: {
      isSubmittable: false,
      submitWasAttempted: false
    },
    ui: {
      asyncActionInFlight: false,
      contactEditInProgress: false
    },
    countries,
    countryCode,
    paypalDetails: createPayPalDetails(countryCode),
    midTypes: createMidTypes(countryCode)
  };
  if (!venmoOnly) {
    storeObj.business.values = omit(
      storeObj.business.values,
      "email",
      "logo_image",
      "logo_image_id"
    );
  }
  if (countryCode === "BRA") {
    storeObj = omit(storeObj, ["processing", "funding"]);
  }
  return storeObj;
};

export const hydratePersistedStore = (countryCode: string) => {
  return {
    business: {
      dba_name: countryCode === "USA" ? "Howdy Ho" : "This Is Fine",
      registered_as:
        countryCode === "USA"
          ? "limited_liability_corporation"
          : "public_corporation",
      industry: countryCode === "USA" ? "non_profit" : "generic_retail",
      established_on: "10/18/2001",
      legal_name: countryCode === "USA" ? "Howdy Ho Co" : "This Is Fine, Ltd",
      tax_id: countryCode === "BRA" ? "12345678909876" : "000000019",
      state_incorporated: getRegion(countryCode),
      phone: "5554678950",
      pay_with_venmo: "0",
      website:
        countryCode === "USA" ? "http://howdyho.com" : "http://thisisfine.co",
      description:
        countryCode === "USA" ? "A howdy'in and a ho'din" : "It be burnin!",
      address: {
        street_address: "123 Street Ave",
        extended_address: "3",
        locality: "Here",
        region: getRegion(countryCode),
        postal_code: getPostalCode(countryCode),
        country: countryCode
      }
    },
    funding: {
      account_number: getAccountNumber(countryCode),
      routing_number: getRoutingNumber(countryCode),
      bic: "BOFAUS",
      bank_name: "Bank of America",
      address: {
        country: countryCode,
        region: getRegion(countryCode),
        postal_code: getPostalCode(countryCode),
        street_address: "123 Street Ave",
        locality: "Here"
      },
      documents: []
    },
    processing: {
      annual_volume_amount: "1000",
      average_transaction_amount: "100",
      currently_processing_credit_cards: "1",
      customer_charged_on: "order",
      existing_merchant: "0",
      fulfillment_completed_in: "7",
      maximum_transaction_amount: "300",
      offers_subscriptions: "0",
      refund_policy: "exchange_only",
      ship_physical_goods: "0",
      ships_own_products: "0",
      subscriptionPercentages: {
        monthly: 0,
        quarterly: 0,
        semi_annually: 0,
        annually: 0
      },
      documents: []
    },
    contacts: [createContact(countryCode)],
    countryCode,
    countries
  };
};
