/* @flow */

import type { Contact } from "../../types";
import ListCell from "../ListCell";
import { RemoveIcon } from "../Icons";
import ButtonGroup from "../ButtonGroup";
import Button from "../Button";
import React, { Fragment } from "react";
import Avatar from "../Avatar";
import Badge from "../Badge";
import ErrorBlock from "../ErrorBlock";
import { ContactPersonalInformationSchema } from "../../validators/schemas/contact";
import { ValidationError } from "yup";
import countryConfig from "../../countryConfig";

type ResolvedContactProps = {
  contact: Contact,
  countryCode: string,
  initialIdNumbers: Array<string>,
  onEditClick: (id: string) => void,
  onDeleteClick?: (id: string) => void,
  isPublicCorporation: boolean
};

const hasError = (
  contact: Contact,
  countryCode: string,
  initialIdNumbers: Array<string>,
  isPublicCorporation: boolean
) => {
  try {
    const isIDRequired =
      countryConfig[countryCode].renderIdNumber && !isPublicCorporation;
    ContactPersonalInformationSchema.validateSync(contact.personalInformation, {
      abortEarly: false,
      context: { countryCode, isIDRequired, initialIdNumbers }
    });
  } catch (exception) {
    if (ValidationError.isError(exception)) {
      return true;
    }
  }
  return false;
};

export const ResolvedContact = (props: ResolvedContactProps) => {
  const hasServerError = Boolean(props.contact.serverSideValidationErrors);
  const fullName = `${props.contact.personalInformation.first_name}
    ${props.contact.personalInformation.last_name}`;

  let Wrapper = Fragment;
  let wrapperProps;

  if (hasServerError) {
    Wrapper = Badge;
    wrapperProps = {
      label: "!"
    };
  }

  return (
    <React.Fragment>
      <ListCell
        leftAccessory={
          <Wrapper {...wrapperProps}>
            <Avatar />
          </Wrapper>
        }
        description={props.contact.personalInformation.job_title}
        title={fullName}
        rightAccessory={
          <ButtonGroup>
            <Button
              intent="secondary"
              size="small"
              onClick={() => props.onEditClick(props.contact.id)}>
              Edit
            </Button>
            {!props.contact.authorizedSigner ? (
              <Button
                intent="danger"
                size="small"
                title="Remove"
                onClick={() =>
                  props.onDeleteClick && props.onDeleteClick(props.contact.id)
                }>
                <RemoveIcon />
              </Button>
            ) : null}
          </ButtonGroup>
        }
      />
      {hasError(
        props.contact,
        props.countryCode,
        props.initialIdNumbers,
        props.isPublicCorporation
      ) && (
        <ErrorBlock message="There is an error with the contact information, please update it" />
      )}
    </React.Fragment>
  );
};
