/* @flow */

import React, { type Node } from "react";
import Button from "../../components/Button";
import GroupedList from "../../components/GroupedList";
import FilePicker from "../../components/FilePicker";
import { negate, isEqual } from "lodash/fp";
import type { FileDescriptor } from "../../types";
import type { Location, RouterHistory } from "react-router-dom";
import ListCell from "../../components/ListCell";
import Avatar from "../../components/Avatar";

type KiqDocumentsProps = {
  location: Location,
  history: RouterHistory
};

type KiqDocumentsState = {
  files: FileDescriptor[],
  uploading: boolean
};

class KiqDocuments extends React.Component<
  KiqDocumentsProps,
  KiqDocumentsState
> {
  state = {
    files: [],
    uploading: false
  };

  render(): Node {
    const { location, history } = this.props;
    const { authorizedSigner, authorizedSignerId } = location.state || {};
    const { files, uploading } = this.state;

    return (
      <div>
        <GroupedList displayAsCard={true}>
          <ListCell
            leftAccessory={<Avatar />}
            title={`${authorizedSigner.first_name} ${authorizedSigner.last_name}`}
            description={authorizedSigner.job_title}
          />
          <div>
            Please provide a copy of any valid government-issued photo
            identification and proof of residential address such as a utility
            bill dated within the past 12 months.
          </div>
        </GroupedList>

        <FilePicker
          documentableId={authorizedSignerId}
          description="Adding documentation is optional at this time; however, we will request them later during the application review process."
          documentableType="contact"
          displayAsCard={true}
          files={files}
          onFileRemove={file => {
            this.setState(prevState => ({
              files: prevState.files.filter(negate(isEqual(file)))
            }));
          }}
          onFileUpload={file => {
            this.setState(prevState => ({
              files: [...prevState.files, file]
            }));
          }}
          onFileUploadStatus={status => {
            this.setState({
              uploading: status === "uploading"
            });
          }}
        />
        <Button
          intent="primary"
          fullWidth={true}
          disabled={uploading}
          onClick={() => {
            history.push("/thanks", { email: authorizedSigner.email });
          }}>
          Done
        </Button>
      </div>
    );
  }
}
export default KiqDocuments;
