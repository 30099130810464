/* @flow */

import React from "react";

const Logo = () => (
  <svg viewBox="0 0 117 28" width="100" height="24" fill="white">
    <path d="M116.36,22.69a1.18,1.18,0,0,0-.93-.36,1.78,1.78,0,0,0-1.05.3,2.09,2.09,0,0,0-.84,1.62,1.74,1.74,0,0,0,.17.77,1.3,1.3,0,0,0,.51.53,1.5,1.5,0,0,0,.78.19,2.05,2.05,0,0,0,1.19-.34l.08-.49a2.12,2.12,0,0,1-.53.25,2.23,2.23,0,0,1-.6.08,1.09,1.09,0,0,1-.75-.26,1,1,0,0,1-.25-.76h2.46c0-.11.05-.21.07-.29a2.73,2.73,0,0,0,0-.32A1.28,1.28,0,0,0,116.36,22.69Zm-.24,1.06h-1.9a1.3,1.3,0,0,1,.42-.66,1.08,1.08,0,0,1,.73-.27.73.73,0,0,1,.56.21.81.81,0,0,1,.19.58Z" />
    <path d="M111.49,22.6a1.78,1.78,0,0,0-.67.72,1.94,1.94,0,0,0-.23.95,1.6,1.6,0,0,0,.18.75,1.41,1.41,0,0,0,.5.53,1.39,1.39,0,0,0,.72.19,2,2,0,0,0,.55-.07,2,2,0,0,0,.51-.24l.09-.6a1.47,1.47,0,0,1-.48.29,1.7,1.7,0,0,1-.54.09.9.9,0,0,1-.68-.27,1,1,0,0,1-.25-.71,1.42,1.42,0,0,1,.17-.67,1.33,1.33,0,0,1,.46-.51,1.2,1.2,0,0,1,.68-.19,1.18,1.18,0,0,1,.87.35l.09-.59a1.46,1.46,0,0,0-.93-.29A2,2,0,0,0,111.49,22.6Z" />
    <path d="M110.55,21.26a.32.32,0,0,0-.23-.09.41.41,0,0,0-.3.13.37.37,0,0,0-.13.29.32.32,0,0,0,.09.23.29.29,0,0,0,.22.09.38.38,0,0,0,.3-.13.38.38,0,0,0,.14-.29A.32.32,0,0,0,110.55,21.26Z" />
    <polygon points="109.33 25.63 109.91 25.63 110.41 22.43 109.82 22.43 109.33 25.63" />
    <polygon points="108.94 22.43 107.62 24.93 107.07 22.43 106.49 22.43 107.25 25.63 107.79 25.63 109.55 22.43 108.94 22.43" />
    <path d="M105.18,23l.09-.55h-.57l-.5,3.2h.58L105,24a1.45,1.45,0,0,1,.35-.81.89.89,0,0,1,.65-.27h.26l.08-.51-.26,0A1.13,1.13,0,0,0,105.18,23Z" />
    <path d="M102.77,22.33a1.8,1.8,0,0,0-1,.3,2.09,2.09,0,0,0-.84,1.62,1.74,1.74,0,0,0,.17.77,1.3,1.3,0,0,0,.51.53,1.5,1.5,0,0,0,.78.19,2.05,2.05,0,0,0,1.19-.34l.08-.49a2.12,2.12,0,0,1-.53.25,2.23,2.23,0,0,1-.6.08,1,1,0,0,1-.74-.26.94.94,0,0,1-.26-.76h2.46c0-.11,0-.21.07-.29a2.73,2.73,0,0,0,0-.32,1.28,1.28,0,0,0-.33-.92A1.17,1.17,0,0,0,102.77,22.33Zm.69,1.42h-1.9a1.3,1.3,0,0,1,.42-.66,1.08,1.08,0,0,1,.73-.27.73.73,0,0,1,.56.21.81.81,0,0,1,.19.58Z" />
    <path d="M99.52,21a2,2,0,0,0-.83.17,1.21,1.21,0,0,0-.77,1.17,1,1,0,0,0,.21.62,2.12,2.12,0,0,0,.7.47l.57.24a1.18,1.18,0,0,1,.37.27.58.58,0,0,1,.11.34,1,1,0,0,1-.11.43.82.82,0,0,1-.35.34,1.17,1.17,0,0,1-.59.14,1.93,1.93,0,0,1-1.3-.51l-.1.65a2,2,0,0,0,1.33.42,2,2,0,0,0,1-.22,1.34,1.34,0,0,0,.57-.56,1.76,1.76,0,0,0,.17-.76,1.05,1.05,0,0,0-.18-.62,1.81,1.81,0,0,0-.64-.48l-.56-.25a2.11,2.11,0,0,1-.47-.28.42.42,0,0,1-.12-.3.64.64,0,0,1,.25-.52,1.11,1.11,0,0,1,.67-.2,1.73,1.73,0,0,1,1.17.46l.1-.61a1.58,1.58,0,0,0-.52-.3A2.32,2.32,0,0,0,99.52,21Z" />
    <path d="M94.89,18.75H93.74a.2.2,0,0,0-.2.17l-1,6.47a.21.21,0,0,0,.2.24h1a.35.35,0,0,0,.34-.29l1-6.35A.21.21,0,0,0,94.89,18.75Z" />
    <path d="M92.14,21H91a.2.2,0,0,0-.2.17l0,.33-.08-.12a1.71,1.71,0,0,0-1.41-.49,2.72,2.72,0,0,0-2.64,2.38A2.22,2.22,0,0,0,87,25.13a1.84,1.84,0,0,0,1.49.61,2.25,2.25,0,0,0,1.64-.68l0,.33a.21.21,0,0,0,.21.24h1.06a.34.34,0,0,0,.34-.29l.64-4.06A.2.2,0,0,0,92.14,21Zm-3,3.44a1,1,0,0,1-.8-.32,1,1,0,0,1-.18-.83,1.31,1.31,0,0,1,1.33-1.14,1,1,0,0,1,.79.32,1,1,0,0,1,.2.84A1.33,1.33,0,0,1,89.15,24.48Z" />
    <path d="M86.54,19.38a2.29,2.29,0,0,0-1.8-.63H82.26a.34.34,0,0,0-.34.29l-1,6.35a.21.21,0,0,0,.21.24H82.4a.24.24,0,0,0,.23-.21l.29-1.8a.34.34,0,0,1,.34-.29H84A2.55,2.55,0,0,0,86.86,21,1.91,1.91,0,0,0,86.54,19.38ZM85,21.07c-.13.89-.81.89-1.47.89h-.37l.26-1.66a.21.21,0,0,1,.21-.18h.17c.44,0,.87,0,1.08.26A.82.82,0,0,1,85,21.07Z" />
    <path d="M80.79,21H79.6a.35.35,0,0,0-.28.15l-1.65,2.42L77,21.28a.35.35,0,0,0-.33-.24H75.48a.21.21,0,0,0-.2.27l1.31,3.85-1.23,1.75a.2.2,0,0,0,.17.32h1.19a.32.32,0,0,0,.28-.15l4-5.72A.2.2,0,0,0,80.79,21Z" />
    <path d="M74.48,21H73.29a.2.2,0,0,0-.2.17l0,.33L73,21.42a1.67,1.67,0,0,0-1.4-.49,2.72,2.72,0,0,0-2.64,2.38,2.22,2.22,0,0,0,.44,1.82,1.84,1.84,0,0,0,1.49.61,2.25,2.25,0,0,0,1.64-.68l0,.33a.21.21,0,0,0,.2.24H73.7a.34.34,0,0,0,.34-.29l.64-4.06A.2.2,0,0,0,74.48,21Zm-3,3.44a.91.91,0,0,1-1-1.15,1.31,1.31,0,0,1,1.33-1.14,1,1,0,0,1,.79.32,1,1,0,0,1,.2.84A1.34,1.34,0,0,1,71.49,24.48Z" />
    <path d="M68.88,19.38a2.29,2.29,0,0,0-1.8-.63H64.6a.34.34,0,0,0-.34.29l-1,6.35a.21.21,0,0,0,.21.24h1.18a.35.35,0,0,0,.34-.29l.27-1.72a.34.34,0,0,1,.34-.29h.78A2.55,2.55,0,0,0,69.2,21,1.91,1.91,0,0,0,68.88,19.38Zm-1.52,1.69c-.13.89-.81.89-1.47.89h-.37l.26-1.66a.21.21,0,0,1,.21-.18h.17c.44,0,.87,0,1.08.26A.82.82,0,0,1,67.36,21.07Z" />
    <path d="M58.89,21.91,57.78,24h1.55Zm-1.38,2.66-.54,1h-.66l2.41-4.51h.59l1,4.51h-.63l-.22-1Z" />
    <path d="M116.69,10.91c0-3.32-2.25-5.77-5.44-5.77a5.28,5.28,0,0,0-5.54,5.39c0,3.11,2.39,5.38,5.8,5.38a7.06,7.06,0,0,0,5-1.87l-1.4-1.47a4.93,4.93,0,0,1-3.56,1.4,3.44,3.44,0,0,1-3.6-2.71h8.76ZM108,9.49a3.2,3.2,0,0,1,3.23-2.42,3.07,3.07,0,0,1,3,2.42Z" />
    <path d="M98.19,5.14a5.28,5.28,0,0,0-5.53,5.39c0,3.11,2.39,5.38,5.8,5.38a7.06,7.06,0,0,0,5-1.87L102,12.57A4.93,4.93,0,0,1,98.48,14a3.44,3.44,0,0,1-3.6-2.71h8.75v-.35C103.63,7.59,101.39,5.14,98.19,5.14ZM95,9.49a3.2,3.2,0,0,1,3.22-2.42,3.07,3.07,0,0,1,3,2.42Z" />
    <path d="M87.79,5.14a4.09,4.09,0,0,0-3.7,2V5.48H80.25v2H82v6.08H79.78v2h7.87v-2H84.12V11A3.57,3.57,0,0,1,87.7,7.14a1.65,1.65,0,0,1,1.62,1.28l2-.57A3.37,3.37,0,0,0,87.79,5.14Z" />
    <path d="M73.52,14a2.12,2.12,0,0,1-2.36-2.21V7.49H76.1v-2H71.16V1.82h-2.1V5.48H66.41v2h2.65v4.32c0,2.71,1.91,4.1,4.46,4.1A5.82,5.82,0,0,0,77.87,14l-1.41-1.44A4.28,4.28,0,0,1,73.52,14Z" />
    <path d="M64.63,8.87a3.61,3.61,0,0,0-3.89-3.73,4,4,0,0,0-3.49,1.64V5.48H53.72v2h1.46v6.08H53.63v2H58.9v-2H57.28V10.2a3.11,3.11,0,0,1,3.19-3,2,2,0,0,1,2.06,2.26v4.13H60.9v2h5.28v-2H64.63Z" />
    <rect x="44.82" y="0.38" width="2.36" height="3.08" />
    <polygon points="47.18 5.48 41.84 5.48 41.84 7.49 45.09 7.49 45.09 13.57 40.84 13.57 40.84 15.58 51.1 15.58 51.1 13.57 51.1 13.57 47.18 13.57 47.18 5.48" />
    <path d="M36.88,9.06c0-2.56-2-3.92-4.54-3.92A6.14,6.14,0,0,0,28,6.83l1.38,1.49a4.42,4.42,0,0,1,3-1.21c1.24,0,2.39.6,2.39,2V9.7A10,10,0,0,0,32,9.3c-2.24,0-4.7.94-4.7,3.44,0,2.11,1.82,3.17,3.92,3.17a4.27,4.27,0,0,0,3.48-1.39v1.06h3.63v-2H36.88ZM34.73,11.5v.29C34.73,13.21,33,14,31.44,14c-1.12,0-1.94-.43-1.94-1.33,0-1.21,1.29-1.64,2.63-1.64a8.31,8.31,0,0,1,2.6.43Z" />
    <path d="M22.47,5.14a4.07,4.07,0,0,0-3.7,2V5.48H14.93v2h1.76v6.08H14.45v2h7.87v-2H18.79V11a3.57,3.57,0,0,1,3.58-3.82A1.63,1.63,0,0,1,24,8.42l2-.57A3.37,3.37,0,0,0,22.47,5.14Z" />
    <path d="M9.58,8.47a3,3,0,0,0,2.05-3c0-2.34-1.78-3.7-4.72-3.7H.52v2h1.6v9.73H.52v2H7.24c3.2,0,5.16-1.4,5.16-3.91A3.41,3.41,0,0,0,9.58,8.47ZM4.31,3.84H6.67c1.79,0,2.75.64,2.75,1.87S8.49,7.59,6.7,7.59H4.31ZM7,13.57H4.31v-4H6.86c2.2,0,3.32.71,3.32,2S9.13,13.57,7,13.57Z" />
  </svg>
);

export default Logo;
