/** @flow */

import { isString } from "lodash";
import { type ServerSideValidationErrors } from "../types";

export const deserializeServerSideValidationErrors = <T>(
  collection: T
): ServerSideValidationErrors => {
  // $FlowFixMe
  return Object.entries(collection).reduce((accum, [key, value]) => {
    if (Array.isArray(value)) {
      if (isString(value[0])) {
        accum[key] = value[0];
      } else {
        accum[key] = value.map(deserializeServerSideValidationErrors);
      }
    } else {
      if (isString(value)) {
        accum[key] = value;
      } else {
        accum[key] = deserializeServerSideValidationErrors(value);
      }
    }
    return accum;
  }, {});
};
