import { object, string, boolean } from "yup";
import { stringExists } from "./helpers";
export default object().shape({
  credit_mid: boolean().when(["$countryCode", "debit_mid", "add_card_mid"], {
    is: (countryCode, debit_mid, add_card_mid) =>
      countryCode === "BRA" && !debit_mid && !add_card_mid,
    then: boolean().oneOf([true], "Must select one of the mid types")
  }),
  debit_mid: boolean().when("$countryCode", {
    is: "BRA",
    then: boolean()
  }),
  add_card_mid: boolean().when("$countryCode", {
    is: "BRA",
    then: boolean()
  }),
  credit_settlement_type: string().when("$countryCode", {
    is: "BRA",
    then: stringExists
  })
});
