/* @flow */

import { ValidationError } from "yup";
import midTypesValidationSchema from "../validators/schemas/midTypes";
import type { MidTypesState, Action } from "../types";

export const initialState: MidTypesState = {
  values: {
    trusted_credit_mid: false,
    trusted_debit_mid: false,
    trusted_add_card_mid: false,
    credit_mid: false,
    debit_mid: false,
    add_card_mid: false,
    credit_settlement_type: "D+30"
  },
  errors: {}
};

export const validate = (countryCode: string) => (
  name: string,
  state: MidTypesState
): string | void => {
  try {
    midTypesValidationSchema.validateSyncAt(name, state.values, {
      context: { countryCode }
    });
  } catch (error) {
    if (ValidationError.isError(error)) {
      return error.message;
    }
    throw error;
  }
  return undefined;
};

export const midTypesReducerSwitch = (
  state: MidTypesState,
  action: Action,
  countryCode: string
): MidTypesState => {
  switch (action.type) {
    case "MID_TYPES_FIELD_CHANGE":
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value
        }
      };
    case "MID_TYPES_FIELD_BLUR": {
      const fieldName = ["credit_mid", "add_card_mid", "debit_mid"].includes(
        action.payload.name
      )
        ? "credit_mid"
        : action.payload.name;
      const fieldValidationError = validate(countryCode)(fieldName, state);
      return {
        ...state,
        errors: {
          ...state.errors,
          [fieldName]: fieldValidationError
        }
      };
    }
    case "LIFECYCLE_SUBMIT":
      try {
        midTypesValidationSchema.validateSync(
          { ...state.values },
          {
            abortEarly: false,
            context: {
              countryCode
            }
          }
        );
      } catch (error) {
        if (ValidationError.isError(error)) {
          const errors = error.inner.reduce((accumulator, error) => {
            accumulator[error.params.path] = error.message;
            return accumulator;
          }, {});

          return {
            ...state,
            errors
          };
        }

        throw error;
      }
      return { ...state, errors: {} };

    default:
      return state;
  }
};

const midTypesReducer = (countryCode: string) => (
  state: MidTypesState = initialState,
  action: Action
): MidTypesState => midTypesReducerSwitch(state, action, countryCode);

export default midTypesReducer;
