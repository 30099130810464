/* @flow */

import { useEffect, useRef, type Node } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  children: Node
};

export const Portal = (props: PortalProps) => {
  const { children } = props;
  const container = useRef(document.createElement("div"));

  useEffect(() => {
    if (document.body !== null) {
      document.body.appendChild(container.current);
    }

    return () => {
      const { body } = document;

      if (body !== null && body.contains(container.current)) {
        body.removeChild(container.current);
      }
    };
  }, []);

  return createPortal(children, container.current);
};
