/* @flow */

import { ValidationError } from "yup";
import React, { PureComponent } from "react";
import Button from "../../Button";
import { ContactOwnershipSchema } from "../../../validators/schemas/contact";
import type {
  ContactOwnership,
  FormChangeHandlerEventArg,
  Action,
  UIState,
  RootState
} from "../../../types";
import Fieldset from "../../Fieldset";
import { ChoiceGroup } from "../../ChoiceGroup";
import { Choice } from "../../Choice";
import TextField from "../../TextField";
import { connect } from "react-redux";
import ErrorBlock from "../../ErrorBlock";
import Grid from "../../Grid";
import { Whole } from "../../GridItem";
import { SUPPORTED_EU_COUNTRY_CODES } from "../../../countryHelper";

type OwnershipResolutionProps = {
  dispatch: Action => Action,
  isAuthorizedSigner: boolean,
  onSuccess: (ownership: ContactOwnership) => void,
  initialValues: ContactOwnership,
  ui: UIState,
  countryCode: string
};

type OwnershipResolutionState = {
  values: ContactOwnership,
  errors: {
    significant_responsibility?: string,
    beneficial_owner?: string,
    ownership_percentage?: string,
    this?: string
  }
};

export class OwnershipResolution extends PureComponent<
  OwnershipResolutionProps,
  OwnershipResolutionState
> {
  constructor(props: OwnershipResolutionProps) {
    super(props);

    this.state = {
      errors: {},
      values: props.initialValues
    };
  }

  handleFormKeydown = (event: SyntheticKeyboardEvent<>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.validateAndSubmit();
    }
  };

  handleComponentBlur = (event: SyntheticFocusEvent<HTMLInputElement>) => {
    const { name } = event.currentTarget;
    let error;
    try {
      ContactOwnershipSchema.validateSyncAt(name, this.state.values, {
        authorizedSigner: this.props.isAuthorizedSigner
      });
    } catch (exception) {
      if (ValidationError.isError(exception)) {
        error = exception.message;
      } else {
        throw exception;
      }
    }

    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: error
      }
    }));
  };

  handleComponentChange = (event: FormChangeHandlerEventArg) => {
    const { name, value } = event.currentTarget;

    this.setState(prevState => {
      const nextValues = {
        ...prevState.values,
        [name]: value
      };

      if (name === "beneficial_owner" && value === "0") {
        nextValues.ownership_percentage = "";
      }

      return {
        values: nextValues
      };
    });

    if (!this.props.ui.contactEditInProgress) {
      this.props.dispatch({ type: "UI_CONTACT_EDIT_START" });
    }
  };

  handleFormButtonClick = (
    event: SyntheticMouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    event.preventDefault();
    this.validateAndSubmit();
  };

  validateAndSubmit = () => {
    try {
      ContactOwnershipSchema.validateSync(this.state.values, {
        abortEarly: false,
        context: {
          authorizedSigner: this.props.isAuthorizedSigner
        }
      });
    } catch (exception) {
      if (ValidationError.isError(exception)) {
        this.setState({
          errors: exception.inner.reduce((accumulator, error) => {
            accumulator[error.params.path] = error.message;
            return accumulator;
          }, {})
        });
        return;
      }
      throw exception;
    }
    this.props.onSuccess(this.state.values);
  };

  render() {
    const { countryCode } = this.props;
    const { values, errors } = this.state;

    return (
      <React.Fragment>
        <form
          onKeyDown={this.handleFormKeydown}
          data-test-id="OwnershipResolutionForm">
          <Fieldset
            title="Beneficial Ownership"
            description={
              <div>
                <p>
                  In compliance with anti-money laundering laws, on behalf of
                  our partner bank, we need to know if this individual owns,
                  directly or indirectly, 25% or more of the&nbsp;business.
                </p>

                {errors.this !== undefined ? (
                  <ErrorBlock message={errors.this} />
                ) : null}
              </div>
            }>
            <Grid>
              {["USA", ...SUPPORTED_EU_COUNTRY_CODES].includes(countryCode) && (
                <Whole>
                  {" "}
                  <ChoiceGroup
                    errorText={errors.significant_responsibility}
                    label="Does this individual have significant responsibility for managing the business? (For example, CEO, COO, CFO or similar role?)"
                    name="significant_responsibility"
                    onBlur={this.handleComponentBlur}
                    onChange={this.handleComponentChange}
                    value={values.significant_responsibility}>
                    <Choice labelText="Yes" value="1" />
                    <Choice labelText="No" value="0" />
                  </ChoiceGroup>
                </Whole>
              )}
              <Whole>
                <ChoiceGroup
                  errorText={errors.beneficial_owner}
                  label="Does this individual own 25% or more of the business?"
                  name="beneficial_owner"
                  onBlur={this.handleComponentBlur}
                  onChange={this.handleComponentChange}
                  value={values.beneficial_owner}>
                  <Choice labelText="Yes" value="1" />
                  <Choice labelText="No" value="0" />
                </ChoiceGroup>
              </Whole>
              {values.beneficial_owner === "1" ? (
                <Whole>
                  <TextField
                    errorText={errors.ownership_percentage}
                    labelText="Ownership Percentage"
                    name="ownership_percentage"
                    onBlur={this.handleComponentBlur}
                    onChange={this.handleComponentChange}
                    placeholder="%"
                    type="number"
                    value={values.ownership_percentage}
                  />
                </Whole>
              ) : null}
            </Grid>
          </Fieldset>
        </form>
        <Button
          intent="primary"
          submit={true}
          fullWidth={true}
          onClick={this.handleFormButtonClick}>
          Continue
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ ui, countryCode }: RootState) => ({
  ui,
  countryCode
});

// $FlowFixMe
export default connect(mapStateToProps)(OwnershipResolution);
