/** @flow */

import type { UIState, UIAction } from "../types";

export const initialState = {
  asyncActionInFlight: false,
  contactEditInProgress: false
};

const uiReducer = (
  state: UIState = initialState,
  action: UIAction
): UIState => {
  switch (action.type) {
    case "UI_ASYNC_ACTION_START":
      return { ...state, asyncActionInFlight: true };
    case "UI_ASYNC_ACTION_COMPLETE":
      return { ...state, asyncActionInFlight: false };
    case "UI_CONTACT_DOCUMENT_UPLOAD_START":
      return { asyncActionInFlight: true, contactEditInProgress: true };
    case "UI_CONTACT_EDIT_START":
      return { ...state, contactEditInProgress: true };
    case "UI_CONTACT_EDIT_COMPLETE":
      return { ...initialState };
    default:
      return state;
  }
};

export default uiReducer;
