/* @flow */

import { includes } from "lodash";
import type { CountryMetadata } from "./types";

const countryLookup = require("country-data").lookup;

export const SUPPORTED_EU_COUNTRY_CODES: string[] = [
  "AND",
  "AUT",
  "BEL",
  "BGR",
  "GGY",
  "HRV",
  "CYP",
  "CZE",
  "DNK",
  "EST",
  "FIN",
  "FRA",
  "DEU",
  "GRC",
  "HUN",
  "ISL",
  "IMN",
  "IRL",
  "ITA",
  "JEY",
  "LVA",
  "LIE",
  "LTU",
  "LUX",
  "MLT",
  "MCO",
  "NLD",
  "NOR",
  "POL",
  "PRT",
  "ROU",
  "SMR",
  "SVK",
  "SVN",
  "ESP",
  "SWE",
  "CHE",
  "GBR"
];

export const SUPPORTED_APAC_COUNTRY_CODES: string[] = ["MYS", "SGP", "HKG"];

export const SUPPORTED_LOCAL_ACQUIRING_COUNTRY_CODES: string[] = ["BRA"];

export const SUPPORTED_COUNTRY_CODES: string[] = [
  ...SUPPORTED_EU_COUNTRY_CODES,
  ...SUPPORTED_APAC_COUNTRY_CODES,
  "USA",
  "CAN",
  "AUS",
  "NZL"
];

export const SUPPORTED_COUNTRIES_METADATA: CountryMetadata[] = SUPPORTED_COUNTRY_CODES.map(
  (countryCode: string) => {
    const country = countryLookup.countries({ alpha3: countryCode })[0];
    return {
      name: country.name,
      countryCode: country.alpha3,
      countryCodeShort: country.alpha2,
      currencyISOCode: country.currencies[0],
      phoneCode: country.countryCallingCodes[0]
    };
  }
);

export const SUPPORTED_EU_COUNTRIES_METADATA: CountryMetadata[] = SUPPORTED_EU_COUNTRY_CODES.map(
  (countryCode: string) => {
    const country = countryLookup.countries({ alpha3: countryCode })[0];
    return {
      name: country.name,
      countryCode: country.alpha3,
      currencyISOCode: country.currencies[0],
      phoneCode: country.countryCallingCodes[0]
    };
  }
);

export const findRegionByCode = (code: string): string => {
  switch (code) {
    case "AUS":
    case "MYS":
    case "HKG":
    case "SGP":
    case "NZL":
      return "Asia-Pacific";
    case "USA":
    case "CAN":
      return "North America";
    default:
      return "European Union";
  }
};

export const isEurope = (countryCode: string): boolean => {
  return includes(SUPPORTED_EU_COUNTRY_CODES, countryCode);
};
