/* @flow */

import React from "react";
import { getMetaNodeValue } from "../dom";
import { readAsDataUrl } from "../io/file";
import { readAndValidateLogo } from "../../../common/validateVenmoLogo";
import GroupedList from "./GroupedList";
import FileDetailsRow from "./FileDetailsRow";
import styles from "./FilePicker.scss";
import type { RefObject, FileDescriptor, FileUploadStatus } from "../types";
import uuid from "uuid/v4";
import ListCell from "./ListCell";
import Spinner from "./Spinner";
import AddButton from "./AddButton";
import { humanizeBytes } from "../bytes";
import ErrorBlock from "./ErrorBlock";

type DocumentType = {
  data: {
    document: {
      id: string
    }
  }
};

type FilePickerProps = {
  description?: string,
  files: FileDescriptor[],
  onFileRemove: (descriptor: FileDescriptor, selectedIndex: number) => void,
  onFileUpload: (descriptor: FileDescriptor) => void,
  onFileUploadStatus?: (status: FileUploadStatus) => void,
  title?: string,
  displayAsCard?: boolean,
  documentableId?: string,
  documentableType?: string,
  buttonTitle?: string,
  acceptType?: string,
  requiredDimensions?: string,
  maxSize?: string
};

type FilePickerState = {
  status: FileUploadStatus,
  errors: Array<string>
};

const maxHttpBytes = 10000000;

class FilePicker extends React.Component<FilePickerProps, FilePickerState> {
  state = {
    status: "idle",
    errors: []
  };

  input: RefObject<HTMLInputElement> = React.createRef();

  handleChange = async (
    event: SyntheticInputEvent<HTMLInputElement>
  ): Promise<void> => {
    this.setState({ status: "uploading", errors: [] });
    const files = [...event.currentTarget.files];
    const errors = [];
    /* eslint-disable no-await-in-loop */
    for (const file of files) {
      let response;
      try {
        response = await this.upload(file);
      } catch (error) {
        const errorMsg =
          error && error.message ? `${error.message}` : "Failed to upload";
        errors.push(`${file.name} - ${errorMsg}`);
      }
      if (response !== undefined) {
        if (response.ok) {
          const { data }: DocumentType = await response.json();
          this.props.onFileUpload({
            fileName: file.name,
            id: data.document.id
          });
        } else {
          let reason = "Failed to upload";

          if (file.size > maxHttpBytes) {
            reason = "Exceeds max file size of 10 MB";
          }

          errors.push(`${file.name} (${humanizeBytes(file.size)}) - ${reason}`);
          continue;
        }
      }
    }
    /* eslint-enable no-await-in-loop */
    this.setState({ status: "complete", errors });
  };

  async upload(file: File): Promise<Response> {
    const { acceptType } = this.props;
    let fileContents;
    if (acceptType === ".png") {
      // In all other cases, use "readAsDataUrl" method as different file formats are supported.
      fileContents = await readAndValidateLogo(file, this.props);
    } else {
      fileContents = await readAsDataUrl(file);
    }

    const body = {
      documentable_id: this.props.documentableId,
      documentable_type: this.props.documentableType,
      data: {
        file_data: fileContents,
        file_type: file.type,
        name: file.name,
        size: file.size,
        object_key: `${uuid()}_${file.name}`
      }
    };

    return fetch(`${location.origin}/documents/s3_upload`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": String(getMetaNodeValue("csrf-token")),
        Accept: "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  componentDidUpdate(prevProps: FilePickerProps, prevState: FilePickerState) {
    if (prevState.status !== this.state.status) {
      if (this.props.onFileUploadStatus) {
        this.props.onFileUploadStatus(this.state.status);
      }
    }
  }

  render() {
    const {
      description,
      files,
      onFileRemove,
      title,
      displayAsCard,
      buttonTitle,
      acceptType
    } = this.props;
    const isUploading = this.state.status === "uploading";
    const errors = this.state.errors;

    let addButtonTitle = "Add Document";
    let acceptedTypes =
      ".zip, .txt, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .csv, .png, .jpg, .jpeg";
    if (typeof buttonTitle === "string") {
      addButtonTitle = buttonTitle;
    }
    if (typeof acceptType === "string") {
      acceptedTypes = acceptType;
    }
    return (
      <div className={styles.root}>
        <GroupedList
          title={title}
          description={description}
          displayAsCard={displayAsCard}>
          {errors.map((errorMessage, i) => (
            <ErrorBlock key={`error-${i}`} message={errorMessage} />
          ))}

          {files.map((fileDescriptor, i) => (
            <FileDetailsRow
              key={`${fileDescriptor.fileName}-${i}`}
              fileName={fileDescriptor.fileName}
              onDismiss={() => {
                onFileRemove(fileDescriptor, i);
              }}
            />
          ))}
          {isUploading && (
            <ListCell
              leftAccessory={<Spinner size={24} />}
              title="Processing..."
            />
          )}
          <div>
            <AddButton
              title={addButtonTitle}
              disabled={isUploading}
              onClick={() => {
                if (this.input.current !== null) {
                  this.input.current.click();
                }
              }}
            />
          </div>
        </GroupedList>
        <input
          accept={acceptedTypes}
          className={styles.input}
          disabled={isUploading}
          multiple={true}
          onChange={this.handleChange}
          ref={this.input}
          type="file"
          title={title ? `file input for ${title}` : null}
        />
      </div>
    );
  }
}

export default FilePicker;
