/* @flow */

import shortid from "shortid";
import type { Contact } from "../../types";

export const createEmptyContact = ({
  authorizedSigner,
  countryCode
}: {
  authorizedSigner: boolean,
  countryCode: string
}): Contact => {
  return {
    id: shortid.generate(),
    authorizedSigner,
    personalInformation: {
      first_name: "",
      last_name: "",
      job_title: "",
      email: "",
      phone: "",
      date_of_birth: "",
      nationality: countryCode,
      id_type: "",
      address: {
        country: countryCode,
        extended_address: "",
        locality: "",
        postal_code: "",
        region: "",
        street_address: ""
      }
    },
    ownership: {
      beneficial_owner: authorizedSigner ? "0" : "1",
      significant_responsibility: "0",
      ownership_percentage: ""
    },
    documents: []
  };
};
